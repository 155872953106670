import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressDialogComponent } from './progressdialog.component';
import { ConfirmDialogComponent } from './confirmdialog.component';
import { InfoDialogComponent } from './infodialog.component';

import { BlockingDialogComponent } from './blockingdialog.component';
import { InfoOptions, InfoParams } from './info';
import { ErrorDialogComponent } from './errordialog/errordialog.component';
import { MultiChoiceDialogComponent } from './multichoicedialog.component';
import { WorkingDialogComponent } from './workingdialog.component';
import { printClassName } from '@orgbrain/lib-data';

@Injectable({ providedIn: 'root' })
export class OrgBrainDialogService {
  constructor(private dialog: MatDialog, @Inject(LOCALE_ID) private locale: string) {
    printClassName(' ---> ', this);
  }

  openWorkingDialog(title: string, body?: string) {
    const progressDialog = this.dialog.open(WorkingDialogComponent, {
      autoFocus: false,
      backdropClass: this.defaultBackdrop(),
      width: '500px',
      disableClose: true,
      data: { title, body },
    });
    return progressDialog;
  }

  openProgressDialog() {
    ProgressDialogComponent.open(this.dialog);
  }

  closeProgressDialog() {
    ProgressDialogComponent.close();
  }

  openConfirmDialog(
    title: string = this.locale === 'nb' ? 'Er du sikker?' : 'Are you sure?',
    question: string | string[] = this.locale === 'nb'
      ? 'Bekreft for å fortsette.'
      : 'Please confirm to proceed.',
    yesOptionTitle: string = 'OK',
    noOptionTitle: string = this.locale === 'nb' ? 'Avbryt' : 'Cancel',
    alternativeOptionTitle: string = null,
    reverseLogic: boolean = false
  ): MatDialogRef<ConfirmDialogComponent> {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      autoFocus: false,
      backdropClass: this.defaultBackdrop(),
      width: '500px',
      disableClose: true,
      data: { reverseLogic },
    });

    confirmDialog.componentInstance.title = title;
    confirmDialog.componentInstance.question = question;
    confirmDialog.componentInstance.yesOptionTitle = yesOptionTitle;
    confirmDialog.componentInstance.noOptionTitle = noOptionTitle;
    confirmDialog.componentInstance.alternativeOptionTitle = alternativeOptionTitle;
    return confirmDialog;
  }

  openInfoDialog(
    title: string,
    infotext: string,
    overrideparams?: any
  ): MatDialogRef<InfoDialogComponent> {
    const params = {
      data: new InfoParams({ title, message: infotext }),
      autoFocus: false,
      backdropClass: this.defaultBackdrop(),
    };

    Object.assign(params, overrideparams);

    return this.dialog.open(InfoDialogComponent, params);
  }

  openMultiDialog({
    title,
    infotext,
    options,
    imageUrl,
    noCancel,
  }: {
    title: string;
    infotext: string;
    options: InfoOptions[];
    imageUrl?: string;
    noCancel?: boolean;
  }): MatDialogRef<MultiChoiceDialogComponent> {
    return this.dialog.open(MultiChoiceDialogComponent, {
      data: new InfoParams({ title, message: infotext, options, imageUrl, noCancel }),
      autoFocus: false,
      backdropClass: this.defaultBackdrop(),
      disableClose: noCancel,
    });
  }

  openErrorDialog({
    title,
    error,
    message,
  }: {
    title: string;
    error: any;
    message?: string;
  }): MatDialogRef<ErrorDialogComponent> {
    window['hiddonErrorList'] = window['hiddonErrorList'] || [];
    window['hiddonErrorList'].push(error);
    return this.dialog.open(ErrorDialogComponent, {
      data: new InfoParams({ title, error, message }),
      autoFocus: false,
      backdropClass: this.defaultBackdrop(),
    });
  }

  openBlockingDialog({
    title,
    infotext,
    dismiss,
    progress,
  }: {
    title: string;
    infotext: string;
    dismiss?: string;
    progress?: { loaded: number; total: number };
  }) {
    return this.dialog.open(BlockingDialogComponent, {
      data: new InfoParams({ title, message: infotext, progress, dismiss }),
      autoFocus: false,
      backdropClass: this.defaultBackdrop(),
      width: '500px',
      disableClose: true,
    });
  }

  showOnlyTop() {
    const all: MatDialogRef<any>[] = this.dialog.openDialogs;
    const top = all[all.length - 1];

    for (const d of all) {
      const container = d._containerInstance as any;

      const el = container?._elementRef.nativeElement;
      if (el) {
        if (d !== top) {
          el.style.display = 'none';
        } else {
          el.style.display = 'block';
          d.afterClosed().subscribe(() => {
            this.showOnlyTop();
          });
        }
      }
    }
  }

  defaultBackdrop(): string {
    if (this.dialog.openDialogs.length === 0) {
      return 'darkerBackdrop';
    } else {
      return 'clearBackdrop';
    }
  }
}
