import { CredentialEnum } from '../libs/lib-data/src/lib/session';
import { sharedenvironment } from './frontend-defaults';
import { monitorUrls } from './monitorService';
import { frontendNamesStaging, serverConfigsStaging } from './systemNamesAndUrls';

const APP_BASE_URL = 'https://preprod.staging.orgbrain.ai/';

const myenvironment = {
  production: true,
  name: 'PREPROD',
  frontendList: frontendNamesStaging,
  defaultServerConfig: serverConfigsStaging,

  academyBucketName: 'orgbrain-beta-site',
  logServiceUrl: monitorUrls.preprod.url, // DEPreCAte ME
  monitorUrl: monitorUrls.preprod.url,
  debug: true,
  monitorFrontendUrl: null,
  appBase: APP_BASE_URL,

  meetingEmailRecipientDomain: 'staging-meeting.orgbrain.ai',
  // mainServerUrl: 'https://preprod-server.orgbrain.ai',
  logoURL: 'https://orgbrain-preprod-site.s3.eu-central-1.amazonaws.com/',
  vapidPubKey:
    'BFgOaLG4mMaNglU4un2hG43LpekcCp0dBDzi67T86zSEZQFzTTfT7v2M8tp1LUgsl133JvXfD6-0a47lEPfpLRE',
  bankid2FA: true,
  oauth: {
    BANKID: {
      client_id: 'urn:my:application:identifier:2386',
      redirectPath: `/auth`,
      auth: 'nbid',
      authURL: 'https://paul-leonard-organization-test.criipto.id/oauth2/authorize',
    },
    SPORTSID: {
      client_id: 'orgbrain-client',
      redirectPath: `/auth/index.html`,
      auth: 'nbid',
      authURL: 'https://auth.nif.test4.buypass.no/auth/realms/nif/protocol/openid-connect/auth',
    },
  } as { [key in CredentialEnum]: any },

  azets: {
    client_id: 'orgbrain_sso',
    redirect: `${APP_BASE_URL}`,
    authURL: 'https://idp-develop-devdb.staging.cozone.com/oauth/login',
    jwksURL: 'https://idp-develop-devdb.staging.cozone.com/api/v1/oauth2/jwks',
  },

  loguncaughtErrors: false,
  adobe_embedded_key: '5b7c54a30d974e77bbd1b4676906fb03',
};

export const environment = Object.assign(sharedenvironment, myenvironment);
