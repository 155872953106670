import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'orgbrain-setting-title',
  templateUrl: './setting-title.component.html',
  styleUrls: ['./setting-title.component.scss'],
})
export class SettingTitleComponent implements OnInit {
  @Input() label;
  @Input() value;
  @Input() moduleId;

  constructor() {}

  ngOnInit(): void {}
}
