// Use this in errors
// backendErrorCodes.GenericError.code

export type BackendErrorCode = {
  en: string;
  nb: string;
  code: string;
  usecode?: boolean;
  appendmessage?: boolean;
};
export const backendErrorCodes: { [key: string]: BackendErrorCode } = {
  EmailDoesNotMatchAssertion: {
    en: 'Email in identity record deos not match orgbrain.',
    nb: 'E-post i identitetspost samsvarer ikke med orgbrain.',
    code: '',
  },

  BANKID_DOES_NOT_MATCH_USER_BANKID: {
    en: 'The bankid you used does not match the one in your Orgbrain records.',
    nb: 'Bankid du brukte samsvarer ikke med den i dine Orgbrain-poster.',
    code: '',
  },
  BANKID_NAME_DOES_NOT_MATCH_USER_NAME: {
    en: 'The name associated with bankid you used does not match your Orgbrain user name.',
    nb: 'Navnet som er knyttet til bankid du brukte samsvarer ikke med ditt Orgbrain-brukernavn.',
    code: '',
  },
  AzetsUserNotFound: {
    en: 'Cozone user not found',
    nb: 'Cozone user not found',
    code: '',
    usecode: true,
  },

  GenericError: {
    en: 'Generic Error',
    nb: 'Generisk feil',
    code: '',
  },

  SeriousError: {
    en: 'Serious Database Error',
    nb: 'Alvorlig databasefeil',
    code: '',
  },

  LoginBeforeRegister: {
    en: 'You must register before you can logon (see invitation email or click forgot password)',
    nb: 'Du må registrere deg før du kan logge på (se invitasjons-e-post eller klikk på glemt passord)',
    code: '',
  },

  IncorrectUsernameOrPassword: {
    en: 'Incorrect username or password.',
    nb: 'Feil brukernavn eller passord.',
    code: '',
  },

  AuthorizationProblem: {
    en: 'Authorization problem.',
    nb: 'Autorisasjonsproblem.',
    code: '',
  },

  SessionHasExpired: {
    // unused ??
    en: 'Session has expired',
    nb: 'Økten er utløpt',
    code: '',
  },

  SessionDoesNotExist: {
    en: 'Session does not exist.',
    nb: 'Økten eksisterer ikke.',
    code: '',
  },
  NoAuthorizationToken: {
    en: 'You are not authorized.',
    nb: 'Du er ikke autorisert.',
    code: '',
  },
  PasswordIsRequired: {
    en: 'Password is required.',
    nb: 'Passord er påkrevd.',
    code: '',
  },
  ShareClassInUseCanNotDelete: {
    en: 'In use - can not delete.',
    nb: 'Er i bruk - kan ikke slette.',
    code: '',
  },
  InviteAlreadyProcessed: {
    en: 'Invite already processed.',
    nb: 'Invitere allerede behandlet.',
    code: '',
  },
  BankidDidNotMatch: {
    en: 'Bankid did not match.',
    nb: 'Bankid did not match (nb TODO)',
    code: '',
  },
  TransferAcountNoIdMatch: {
    en: 'No identity match found.',
    nb: 'No identity match found. (nb TODO)',
    code: '',
  },

  TransferAcountRecipientNotFound: {
    en: 'Recipient not found.',
    nb: 'Recipient not found. (nb TODO)',
    code: '',
  },

  TransferAcountUserHasIsues: {
    en: 'Issues transfering user account.',
    nb: 'Issues transfering user account. (nb TODO)',
    code: '',
    appendmessage: true,
  },
};

// Well this is naff but it helps checking.
const keys = Object.keys(backendErrorCodes);

for (const key of keys) {
  backendErrorCodes[key].code = key;
}

export const IGNORED_ERROR_MESSAGES = [
  'Connection problem. Check your internet',
  'WebSocket connection to',
  'Heart beat is lost',
  'Heart beat lost',
  'ExpressionChangedAfterItHasBeenCheckedError',
  'Jitsi is already open',
  // 'Cannot read properties of', // this is actually helpful sometimes!
  'null is not an object',
  "reading 'classList'",
  "reading 'className'",
  'attribute d: Expected number',
];
