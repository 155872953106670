export enum CredentialEnum {
  COGNITO = 'COGNITO',
  SMS = 'SMS',
  BANKID = 'BANKID',
  TOTP = 'TOTP',
  TRUSTED = 'TRUSTED',
  COZONE = 'COZONE',
  MANUAL = 'MANUAL',
  SPORTSID = 'SPORTSID',
}

export const oauthCredentialEnums: CredentialEnum[] = [
  CredentialEnum.BANKID,
  CredentialEnum.SPORTSID,
];

export const isOauthCredential: { [key in CredentialEnum]?: boolean } = {
  [CredentialEnum.BANKID]: true,
  [CredentialEnum.SPORTSID]: true,
};

// We have accidcentally created two enums for the same thing see CredentialEnum.
// We should really use CredentialEnum but we have legacy data (user.identities USE this)
// This is a temporary solution to convert between the two.
export enum IdentityType {
  no_bankid = 'no_bankid',
  sms = 'sms',
  cozone = 'cozone',
  cognito = 'cognito',
  totp = 'totp',
  manual = 'manual', // This is a special case because we store this informtion in OrganizationUserRoles  Not in the user data}
  sportsid = 'sportsid',
}

export enum SetContextResultEnum {
  NONEED = 'NONEED', // No need to set the context   all OK
  OK = 'OK', // Set the context and all OK
  ORGANIZATION_NOT_IN_USER = 'ORGANIZATION_NOT_IN_USER',
  NO_ORGANIZATION_IN_URL = 'NO_ORGANIZATION_IN_URL',
}

export const mfaResetIDS = [IdentityType.sms, IdentityType.totp];

const id2Credential: { [key in IdentityType]: CredentialEnum } = {
  [IdentityType.no_bankid]: CredentialEnum.BANKID,
  [IdentityType.sms]: CredentialEnum.SMS,
  [IdentityType.cozone]: CredentialEnum.COZONE,
  [IdentityType.cognito]: CredentialEnum.COGNITO,
  [IdentityType.totp]: CredentialEnum.TOTP,
  [IdentityType.manual]: CredentialEnum.MANUAL,
  [IdentityType.sportsid]: CredentialEnum.SPORTSID,
};

const isCredential: { [key in CredentialEnum]: boolean } = {
  [CredentialEnum.COGNITO]: true,
  [CredentialEnum.SMS]: true,
  [CredentialEnum.BANKID]: true,
  [CredentialEnum.TOTP]: true,
  [CredentialEnum.TRUSTED]: true,
  [CredentialEnum.COZONE]: true,
  [CredentialEnum.MANUAL]: true,
  [CredentialEnum.SPORTSID]: true,
};

export function makeIntoCredential(identity: any): CredentialEnum {
  const cred = id2Credential[identity];
  if (cred) {
    return cred;
  }

  if (isCredential[identity]) {
    return identity as unknown as CredentialEnum;
  }
  return undefined;
}

export function makeIntoIdentity(cred: any): IdentityType {
  if (!isCredential[cred]) {
    return cred as unknown as IdentityType;
  }

  const id = Object.keys(id2Credential).find(
    (item) => id2Credential[item] === cred
  ) as IdentityType;
  if (id) {
    return id;
  }

  return undefined;
}

export function makeIntoCredentialArray(array: any) {
  if (!array) {
    return null;
  }
  return array.map((item) => makeIntoCredential(item));
}

export type CredentialKeys = {
  [key in CredentialEnum]: string; // Note that "key in".
};

// Data required for creating a session

export class SessionDataInit {
  _id?: string;
  userId: string;
  ip: string;
  expires?: string;
  // Device
  deviceId: string;

  //  session
  sessionToken: string; // current session  (same as cookie)
  // sub: string; //  Cognito sub
  email: string; //  user email
  slidePeriod: number; // millis for sliding window
  credentials: CredentialKeys; //
  idToken: string;
  lastActivity?: Date;
  inactivityPeriod?: { [cred in CredentialEnum]: number };
  trusted: boolean;
}

export class SessionData extends SessionDataInit {
  // Device
  override deviceId: string;

  //  session

  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  // temporary stuff
}

export class DeviceData {
  _id?: string;
  userId: string;
  expand?: boolean;
  // Device
  ip: string;
  fingerprint: number;
  trusted: boolean;
  deviceToken: string;
  name: string;

  expires: string;
  //  TODO probably just need expires
  deleted?: boolean;

  // tTO pas the front end someting compact.
  sessions?: SessionData[];
  mydevice?: boolean;
  createdAt?: string;
  updatedAt?: string;
  virgin: boolean;
}

// export function mfa2identity(mfa: CredentialEnum): IdentityType {
//   switch (mfa) {
//     case CredentialEnum.TOTP:
//       return IdentityType.totp;

//     case CredentialEnum.COZONE:
//       return IdentityType.cozone;

//     case CredentialEnum.COGNITO:
//       return IdentityType.cognito;

//     case CredentialEnum.BANKID:
//       return IdentityType.no_bankid;

//     case CredentialEnum.SMS:
//       return IdentityType.sms;

//     default:
//       return null;
//   }
// }

// export function mfaArray2identityArray(mfaArray: CredentialEnum[]): IdentityType[] {
//   const idArray = [];

//   for (const mfa of mfaArray) {
//     const id = mfa2identity(mfa);
//     idArray.push(id);
//   }

//   return idArray;
// }

export const identityTypeLang: { [type in IdentityType]: { en: string; nb: string } } = {
  [IdentityType.no_bankid]: {
    en: 'Norwegian BankID',
    nb: 'Norsk BankID',
  },
  [IdentityType.sms]: {
    en: 'SMS',
    nb: 'SMS',
  },
  [IdentityType.cozone]: {
    en: 'Cozone',
    nb: 'Cozone',
  },
  [IdentityType.cognito]: {
    en: 'AWS Cognito',
    nb: 'AWS Cognito',
  },
  [IdentityType.totp]: {
    en: 'totp',
    nb: 'totp',
  },
  [IdentityType.manual]: {
    en: 'manual',
    nb: 'manual',
  },
  [IdentityType.sportsid]: {
    en: 'idrettens-ID',
    nb: 'idrettens-ID',
  },
};

export const credentialTypeLang: { [type in CredentialEnum]: { en: string; nb: string } } = {
  [CredentialEnum.BANKID]: {
    en: 'Norwegian BankID',
    nb: 'Norsk BankID',
  },
  [CredentialEnum.SMS]: {
    en: 'SMS',
    nb: 'SMS',
  },
  [CredentialEnum.COZONE]: {
    en: 'Cozone',
    nb: 'Cozone',
  },
  [CredentialEnum.COGNITO]: {
    en: 'AWS Cognito',
    nb: 'AWS Cognito',
  },
  [CredentialEnum.TOTP]: {
    en: 'Authenticator App',
    nb: 'Authenticator app',
  },
  [CredentialEnum.MANUAL]: {
    en: 'manual',
    nb: 'manual',
  },
  TRUSTED: {
    // Probably not used
    en: 'Trusted',
    nb: 'Trusted',
  },
  SPORTSID: {
    en: 'Idretts',
    nb: 'Idretts',
  },
};

export const idTypeLang: { [type in IdentityType]?: { en: string; nb: string } } = {
  no_bankid: {
    en: 'Norwegian BankID',
    nb: 'Norsk BankID',
  },
  sms: {
    en: 'SMS',
    nb: 'SMS',
  },
};

export class IdentityRecord {
  identityType: IdentityType;
  identityId: string;
  data: any;
  // history: any[];
  allowLogon: boolean;
  // last verification.
  stamp: string | Date;
  createdAt: string | Date;
}
