import { MongoId } from './chatChannel';

export interface IRequestLog {
  requestId: string;
  type: string;
  source: string;
  serverConfig: string;
  data: any; //  artbirtary data
  cpu: any;
  memory: any;
  fields: any;
  params: any;
  query: any;
  userId: MongoId;
  email: string;
  path: string;
  baseUrl: string;
  ip: string;
  stamp: Date; //  time stamp
  proxyUser: string;
  versionId: string;
  processId: number;
  hostname: string;
  filename: string;
  elasped: number;
  op: string;

  // Added for indexing the logging.
  organizationId: MongoId;
  meetingId: MongoId;
  direntId: MongoId;
  subjectId: MongoId;
  keepMe: boolean;
}
