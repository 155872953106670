export const textMappings = {
  BogdanBegin: {
    en: 'x1',
    nb: 'x2',
    sv: 'x3',
  },
  SUBSCRIPTION_PACKAGE: {
    en: 'Subscription',
    nb: 'Abonnement',
    sv: 'Prenumeration',
  },
  SUBSCRIPTION_PACKAGE_USER_EXTENSION: {
    en: 'Subscription update: extra users',
    nb: 'Abonnementsoppdatering: ekstra brukere',
    sv: 'Prenumerationsuppdatering: extra användare',
  },
  SUBSCRIPTION_PACKAGE_REMAINDER: {
    en: 'Remainder from previous subscription',
    nb: 'Resten fra forrige abonnement',
    sv: 'Resterande från tidigare prenumeration',
  },
  'Subscription has changed': {
    en: 'Subscription has changed',
    nb: 'Abonnementet er endret',
    sv: 'Prenumerationen har ändrats',
  },
  'Users before': {
    en: 'Users before',
    nb: 'Brukere før',
    sv: 'Användare tidigare',
  },
  'Users after': {
    en: 'Users after',
    nb: 'Brukere etter',
    sv: 'Användare efter',
  },
  BOARD_PORTAL: {
    en: 'Board portal',
    nb: 'Styreportal',
    sv: 'Styrelsens portal',
  },
  INVOICE_PERIOD: {
    en: 'Invoice period',
    nb: 'Fakturaperiode',
    sv: 'Fakturaperiod',
  },
  PER_MNTH: {
    en: 'per month',
    nb: 'pr. mnd.',
    sv: 'pr. mnd.',
  },
  USER_PACKAGE: {
    en: 'Users',
    nb: 'Brukere',
    sv: 'Användare',
  },
  YEARLY_AGREEMENT: {
    en: 'Annual agreement',
    nb: 'Årsavtale',
    sv: 'Årsavtal',
  },
  MONTHLY_AGREEMENT: {
    en: 'Monthly agreement',
    nb: 'Månedlig avtale',
    sv: 'Månadsavtal',
  },
  SUBGROUP_USER_PACKAGE: {
    en: 'Subgroup users',
    nb: 'Undergruppebrukere',
    sv: 'Undergruppsanvändare',
  },
  DISCOUNT_CASH_TOTAL: {
    en: 'Money discount',
    nb: 'Penger rabatt',
    sv: 'Pengar rabatt',
  },
  DISCOUNT_PERCENT_TOTAL: {
    en: 'Percentage discount',
    nb: 'Prosentvis rabatt',
    sv: 'Procentuell rabatt',
  },
  DD_PROCESSES_GROUPS: {
    en: 'Due diligence processes',
    nb: 'Due diligence-prosesser',
    sv: 'Due diligence-processer',
  },
  DATAROOM_STORAGE: {
    en: 'Dataroom storage',
    nb: 'Datarom lagring',
    sv: 'Datarumslagring',
  },
  SIGNING_DOCUMENTS: {
    en: 'Signing documents',
    nb: 'Signering av dokumenter',
    sv: 'Underteckna dokument',
  },
  NOK: {
    en: 'NOK',
    nb: 'kr',
    sv: 'kr',
  },
  ddProcesses: {
    en: 'Due diligence processes',
    nb: 'Due diligence-prosesser',
    sv: 'Due diligence-processer',
  },
  NIF_START: {
    en: 'NIF Standard',
    nb: 'NIF Standard',
    sv: 'NIF Standard',
  },
  NIF_PRO: {
    en: 'NIF Pro',
    nb: 'NIF Pro',
    sv: 'NIF Pro',
  },
  DD_SUBSCRIPTION: {
    en: 'Due Diligence',
    nb: 'Due Diligence',
    sv: 'Due Diligence',
  },
  FOUNDER2024: {
    en: 'Founder',
    nb: 'Gründer',
    sv: 'Grundare',
  },
  SMALL_BUSINESS2024: {
    en: 'Small business',
    nb: 'Lite foretak',
    sv: 'Litet företag',
  },
  STANDARD2024: {
    en: 'Standard',
    nb: 'Standard',
    sv: 'Standard',
  },
  DISCOUNT_USER_COUNT_GENERAL: {
    en: 'Number of free users',
    nb: 'Antall gratis brukere',
    sv: 'Antal gratisanvändare',
  },
  DISCOUNT_USER_COUNT_SUBGROUP: {
    en: 'Number of free subgroup users',
    nb: 'Antall gratis undergruppebrukere',
    sv: 'Antal gratis undergruppsanvändare',
  },
  DISCOUNT_USER_COUNT_ROLE_BASED: {
    en: 'Number of free users by role',
    nb: 'Antall gratis brukere etter rolle',
    sv: 'Antal lediga användare per roll',
  },
  DescriptionHasChanged: {
    en: 'Description have changed. If you save now - you will overwrite the changes. To cancel please close the dialog.',
    nb: 'Beskrivelsen er endret. Hvis du lagrer nå - vil du overskrive endringene. Lukk dialogboksen for å avbryte.',
  },
  BogdanEnd: {
    en: 'x4',
    nb: 'x5',
    sv: 'x6',
  },
  PaulBegin: {
    en: 'x1',
    nb: 'x2',
    sv: 'x3',
  },
  PaulEnd: {
    en: 'x4',
    nb: 'x5',
    sv: 'x6',
  },
  EivindBegin: {
    en: 'x1',
    nb: 'x2',
    sv: 'x3',
  },
  EivindEnd: {
    en: 'x4',
    nb: 'x5',
    sv: 'x6',
  },
  PederBegin: {
    en: 'x1',
    nb: 'x2',
    sv: 'x3',
  },
  Search: {
    en: 'Search',
    nb: 'Søk',
  },
  NoCurrentContracts: {
    en: 'No contracts are currently active',
    nb: 'Ingen kontrakter er aktive.',
  },
  Home: {
    en: 'Home',
    nb: 'Hjem',
    sv: 'Hem',
  },
  PederEnd: {
    en: 'x4',
    nb: 'x5',
    sv: 'x6',
  },
  ViktorBegin: {
    en: 'x1',
    nb: 'x2',
    sv: 'x3',
  },
  ViktorEnd: {
    en: 'x4',
    nb: 'x5',
    sv: 'x6',
  },
  SondreBegin: {
    en: 'x1',
    nb: 'x2',
    sv: 'x3',
  },
  SondreEnd: {
    en: 'x4',
    nb: 'x5',
    sv: 'x6',
  },
  Reminder: {
    en: 'Reminder',
    nb: 'Påminnelse',
    sv: 'Påminnelse',
  },
  and: {
    en: 'and',
    nb: 'og',
    sv: 'och',
  },
  of: {
    en: 'of',
    nb: 'av',
    sv: 'av',
  },
  Page: {
    en: 'Page',
    nb: 'Side',
    sv: 'Sida',
  },
  Pages: {
    en: 'Pages',
    nb: 'Sider',
    sv: 'Sidor',
  },
  InviteInvestors: {
    en: 'Invite investors',
    nb: 'Inviter investorer',
    sv: 'Bjud in investerare',
  },
  SendReminder: {
    en: 'Send reminder',
    nb: 'Send påminnelse',
    sv: 'Skicka påminnelse',
  },
  'This document package contains': {
    en: 'This document package contains',
    nb: 'Denne dokumentpakken inneholder',
  },
  'Signing of encrypted files is not supported': {
    en: 'Signing of encrypted files is not supported',
    nb: 'Signering av kryptert fil støttes ikke',
  },
  'The original document': {
    en: 'The original document',
    nb: 'Det originale dokumentet',
  },
  'This signature page at the end': {
    en: 'This signature page at the end',
    nb: 'Denne signatursiden på slutten',
  },
  'Signature pages at the end': {
    en: 'Signature pages at the end',
    nb: 'Signatur sidene på slutten',
  },
  'COUNTRY:NO': {
    en: 'Norway',
    nb: 'Norge',
    sv: 'Norge',
  },
  'COUNTRY:SE': {
    en: 'Sweden',
    nb: 'Sverige',
    sv: 'Sverige',
  },
  'COUNTRY:DK': {
    en: 'Denmark',
    nb: 'Danmark',
    sv: 'Danmark',
  },
  EMAIL_ATTACHMENTS: {
    en: 'Email attachments',
    nb: 'E-postvedlegg',
    sv: 'E-postbilagor',
  },
  PROXY_FOLDER: {
    en: 'Proxy',
    nb: 'Fullmektig',
    sv: 'Ombud',
  },
  MINUTES: {
    en: 'Minutes',
    nb: 'Protokoll',
    sv: 'Protokoll',
  },
  ATTACHMENTS: {
    en: 'Attachments',
    nb: 'Vedlegg',
    sv: 'Bilagor',
  },
  MINUTES_ATTACHMENTS: {
    en: 'Minutes attachments',
    nb: 'Vedlegg til protokoll',
    sv: 'Bilagor till protokoll',
  },
  DRAFT: {
    en: 'Draft',
    nb: 'Kladd',
    sv: 'Förslag',
  },
  PLANNED: {
    en: 'Planned',
    nb: 'Planlagt',
    sv: 'Planerad',
  },
  CALLED: {
    en: 'Called',
    nb: 'Innkalt',
    sv: 'Kallad',
  },
  ACTIVE: {
    en: 'Active',
    nb: 'Aktivt',
    sv: 'Aktiva',
  },
  ANNUAL_PLAN: {
    en: 'Annual Plan',
    nb: 'Årsplan',
    sv: 'Årlig plan',
  },
  REQUIRES_MINUTES: {
    en: 'Requires Minutes',
    nb: 'Mangler protokoll',
    sv: 'Kräver protokoll',
  },
  REQUIRES_DISCUSSION: {
    en: 'Requires Comments',
    nb: 'Protokoll på høring',
    sv: 'Protokoll från förhandlingen',
  },
  REQUIRES_SIGNATURES: {
    en: 'Signing',
    nb: 'Signering',
    sv: 'Signering',
  },
  COMPLETED: {
    en: 'Completed',
    nb: 'Fullført',
    sv: 'Färdigt',
  },
  CANCELLED: {
    en: 'Cancelled',
    nb: 'Avlyst',
    sv: 'Inställt',
  },
  TEST: {
    en: 'Trial (Expired)',
    nb: 'Prøve (Utløpende)',
    sv: 'Rättegång (Utlöper)',
  },
  TEST2024: {
    en: 'Trial',
    nb: 'Prøve',
    sv: 'Rättegång',
  },
  ALLSTATUS: {
    en: 'Alle statuses',
    nb: 'Alle statuser',
  },
  RELEVANTADS: {
    en: 'Relevant ads',
    nb: 'Aktuelle annonser',
  },
  RELEVANTCANDIDATES: {
    en: 'Relevant candidates',
    nb: 'Aktuelle kandidater',
  },
  POSITIVE: {
    en: 'Interesting',
    nb: 'Interessant',
  },
  NEGATIVE: {
    en: 'Not interesting',
    nb: 'Ikke interessant',
  },
  NEUTRAL: {
    en: 'Maybe interesting',
    nb: 'Kanskje interessant',
    sv: 'Kanskje interessant',
  },

  BOARDSCHOOL: {
    en: 'Board School',
    nb: 'Styreskolen',
    sv: 'Styrelseskolan',
  },
  SUSPENDED: {
    en: 'Expired',
    nb: 'Utgått',
    sv: 'Utgånget',
  },
  GRUNDER: {
    en: 'Founder (Expired)',
    nb: 'Gründer (Utløpende)',
    sv: 'Grundare (Utlöper)',
  },
  SMALL_BUSINESS: {
    en: 'Small business (Expired)',
    nb: 'Lite foretak (Utløpende)',
    sv: 'Litet företag (Utlöper)',
  },
  FOUNDER: {
    en: 'Founder package (Expired)',
    nb: 'Gründerpakke (Utløpende)',
    sv: 'Grundarpaket (Utlöper)',
  },
  SMALL_BUSINESS2: {
    en: 'Small business (Expired)',
    nb: 'Lite foretak (Utløpende)',
    sv: 'Småföretagspaket (Utlöper)',
  },
  BASIS: {
    en: 'Standard (Expired)',
    nb: 'Standard (Utløpende)',
    sv: 'Standard (Utlöper)',
  },
  ENTERPRISE: {
    en: 'Enterprise',
    nb: 'Enterprise',
    sv: 'Företag',
  },
  PersonalFiles: {
    en: 'Personal Files',
    nb: 'Personlige filer',
    sv: 'Personliga filer',
  },
  MeetingFiles: {
    en: 'Meeting Files',
    nb: 'Møtefiler',
    sv: 'Mötesfiler',
  },
  all_meeting: {
    en: 'Board',
    nb: 'Styre',
    sv: 'Styrelse',
  },
  ga_meetings: {
    en: 'Owner/Member',
    nb: 'Eier/Medlem',
    sv: 'Ägare / medlem',
  },
  admin: {
    en: 'Admin',
    nb: 'Admin',
    sv: 'Administration',
  },
  buyer: {
    en: 'Data Room',
    nb: 'Datarom',
    sv: 'Datarum',
  },
  DataRoomRoot: {
    en: 'Data Room',
    nb: 'Datarom',
    sv: 'Datarum',
  },

  Enable: {
    en: 'Enable',
    nb: 'Aktiver',
    sv: 'Gör det möjligt',
  },
  Disable: {
    en: 'Disable',
    nb: 'Deaktiver',
    sv: 'Inaktivera',
  },
  Enabled: {
    en: 'Enabled',
    nb: 'Aktivert',
    sv: 'Aktiverad',
  },
  ChooseTimePoint: {
    en: 'Choose date and time',
    nb: 'Velg dato og tidspunkt',
    sv: 'Aktiverad',
  },
  Users: {
    en: 'Users',
    nb: 'Brukere',
    sv: 'Brukere',
  },

  MeetingUserListInfoText: {
    en: ' Here you can enter a historical user status. This can be used, for example, if there have been changes to the board, and there is a need to create a record of the status before the changes occurred. ',
    nb: ' Her kan du legge inn en brukerstatus tilbake i tid. Dette kan for eksempel benyttes dersom det har skjedd endringer i styret, og det er behov for å lage en protokoll med status før endringene skjedde. ',
    sv: 'Aktiverad',
  },
  Disabled: {
    en: 'Disabled',
    nb: 'Deaktivert',
    sv: 'Inaktiverad',
  },
  Yes: {
    en: 'Yes',
    nb: 'Ja',
    sv: 'Ja',
  },
  No: {
    en: 'No',
    nb: 'Nei',
    sv: 'Nej',
  },
  'Meeting type': {
    en: 'Meeting type',
    nb: 'Møtetype',
    sv: 'Möte typ',
  },
  PHYSICAL_MEETING: {
    en: 'In-person Meeting',
    nb: 'Fysisk møte',
    sv: 'Fysiskt möte',
  },
  PHONE_MEETING: {
    en: 'Phone Meeting',
    nb: 'Telefonmøte',
    sv: 'Telefonkonferens',
  },
  HYBRID_MEETING: {
    en: 'Hybrid Meeting',
    nb: 'Hybridmøte',
    sv: 'Hybrid möte',
  },
  DIGITAL_MEETING: {
    en: 'Digital Meeting',
    nb: 'Digitalt møte',
    sv: 'Digital möte',
  },
  VIDEO_CONFERENCE: {
    en: 'Video Conference',
    nb: 'Videokonferanse',
    sv: 'Videokonferens',
  },
  WRITTEN_PROCEDURE: {
    en: 'Written Procedure',
    nb: 'Skriftlig behandling',
    sv: 'Skriftligt förfarande',
  },
  OTHER_PROCEDURE: {
    en: 'Other Procedure',
    nb: 'Annen møteform',
    sv: 'Annat förfarande',
  },
  Date: {
    en: 'Date',
    nb: 'Dato',
    sv: 'Datum',
  },
  Location: {
    en: 'Location',
    nb: 'Sted',
    sv: 'Plats',
  },
  'Proposed resolution': {
    en: 'Proposed resolution',
    nb: 'Forslag til vedtak',
    sv: 'Föreslagen resolution',
  },
  For: {
    en: 'For',
    nb: 'For',
    sv: 'För',
  },
  Against: {
    en: 'Against',
    nb: 'Imot',
    sv: 'Mot',
  },
  'General comments': {
    en: 'General comments',
    nb: 'Generelle kommentarer',
    sv: 'Generella kommentarer',
  },
  Participants: {
    en: 'Participants',
    nb: 'Deltakere',
    sv: 'Deltagarna',
  },
  'Did not participate': {
    en: 'Did not participate',
    nb: 'Deltok ikke',
    sv: 'Deltog inte',
  },
  'Chair of the meeting': {
    en: 'Chair of the meeting',
    nb: 'Møteleder',
    sv: 'Ordförande för mötet',
  },
  'In the event of a tie, the decision is made by a built-in lottery': {
    en: 'In the event of a tie, the decision is made by a built-in lottery',
    nb: 'Ved stemmelikhet, treffes avgjørelsen ved en innebygget loddtrekning',
    sv: 'I händelse av oavgjort fattas beslutet av ett inbyggt lotteri',
  },
  minutesInfoForProcedureTypePhysicalMeeting: {
    en: 'The proceedings were carried out as a meeting.',
    nb: 'Styrebehandlingen ble gjennomført som et møte.',
    sv: 'Förfarandet genomfördes som ett möte.',
  },
  minutesInfoForProcedureTypeHybridMeeting: {
    en: 'The proceedings were carried out as a hybrid meeting.',
    nb: 'Styrebehandlingen ble gjennomført som et hybridmøte.',
    sv: 'Förfarandet genomfördes som ett hybridmöte.',
  },
  minutesInfoForProcedureTypeDigitalMeeting: {
    en: 'The proceedings were carried out as a digital meeting.',
    nb: 'Styrebehandlingen ble gjennomført som et digitalt møte.',
    sv: 'Förfarandet genomfördes som ett digital möte.',
  },

  minutesInfoForProcedureTypePhoneMeeting: {
    en: 'The proceedings were carried out as a phone meeting.',
    nb: 'Styrebehandlingen ble gjennomført per telefon.',
    sv: 'Förfarandet genomfördes som ett telefonmöte.',
  },
  minutesInfoForProcedureTypeVideoConference: {
    en: 'The proceedings were carried out as a video conference.',
    nb: 'Styrebehandlingen ble gjennomført som en videokonferanse.',
    sv: 'Förfarandet genomfördes som en videokonferens.',
  },
  minutesInfoForProcedureTypeWrittenProcedure: {
    en: 'The proceedings were carried out in writing without a meeting.',
    nb: 'Styrebehandlingen ble gjennomført skriftlig og uten møte.',
    sv: 'Förfarandet genomfördes skriftligen utan möte.',
  },
  MeetingVotedOnAndApproved: {
    en: 'The following decision was voted on and approved:',
    nb: 'Følgende forslag ble stemt over og vedtatt:',
    sv: 'Följande beslut röstades om och godkändes:',
  },
  MeetingVotedOnAndRejected: {
    en: 'The following decision was voted on but rejected:',
    nb: 'Følgende forslag ble stemt over, men fikk ikke flertall:',
    sv: 'Följande beslut röstades om men avvisades:',
  },
  MeetingVotePassedAllFor: {
    en: 'There was a unanimous vote in favour of the following decision:',
    nb: 'Følgende forslag ble enstemmig vedtatt:',
    sv: 'Följande beslut röstades enhälligt för:',
  },
  MeetingVotePassedWithAllDecisionsUnanimous: {
    en: 'The following decision was made:',
    nb: 'Det ble fattet følgende vedtak:',
    sv: 'Följande beslut fattades:',
  },
  MeetingVoteRjectedAllAgainst: {
    en: 'There was a unanimous vote against the following decision:',
    nb: 'Følgende forslag ble enstemmig nedstemt:',
    sv: 'Följande beslut röstades enhälligt emot:',
  },
  MeetingThoseForDecision: {
    en: 'The following voted in favour of the decision:',
    nb: 'Følgende stemte for forslaget:',
    sv: 'Följande röstade för beslutet:',
  },
  MeetingThoseAgainstDecision: {
    en: 'The following voted against the decision:',
    nb: 'Følgende stemte imot forslaget:',
    sv: 'Följande röstade emot beslutet:',
  },
  MeetingThoseNeutralDecision: {
    en: 'The following voted neutral:',
    nb: 'Følgende avga ikke stemme:',
    sv: 'Följande röstade neutralt:',
  },
  MeetingAllUnanimousStatement: {
    en: 'All decisions were passed unanimously.',
    nb: 'Det var enstemmighet i alle avstemningene.',
    sv: 'Alla beslut fattades enhälligt.',
  },
  TheFollowingMustSignRead: {
    en: 'The following must sign that the minutes have been read:',
    nb: 'Følgende skal signere at protokollen er lest:',
    sv: 'Följande måste underteckna att protokollet har lästs:',
  },
  theFollowingWasApproved: {
    en: 'The following was approved',
    nb: 'Følgende ble vedtatt',
    sv: 'Följande godkändes',
  },
  theFollowingWasRejected: {
    en: 'The following was rejected',
    nb: 'Følgende ble nedstemt',
    sv: 'Följande avvisades',
  },
  overviewOfVoting: {
    en: 'Overview of voting',
    nb: 'Oversikt over stemmegiving',
    sv: 'Översikt över omröstning',
  },
  Item: {
    en: 'Item',
    nb: 'Sak',
    sv: 'Fall',
  },
  Shares: {
    en: 'Shares',
    nb: 'Aksjer',
    sv: 'Aktier',
  },
  'In person': {
    en: 'In person',
    nb: 'Personlig',
    sv: 'I person',
  },
  Proxy: {
    en: 'Proxy',
    nb: 'Fullmektig',
    sv: 'Ombud',
  },
  'Represented by': {
    en: 'Represented by',
    nb: 'Representert ved',
    sv: 'Representerad av',
  },
  'Follow-up case': {
    en: 'Follow-up case',
    nb: 'Oppfølgingssak',
    sv: 'Uppföljningsfall',
  },
  Description: {
    en: 'Description',
    nb: 'Beskrivelse',
    sv: 'Beskrivning',
  },
  'Responsible for follow-up': {
    en: 'Responsible for follow-up',
    nb: 'Ansvarlig for oppfølging',
    sv: 'Ansvarig för uppföljning',
  },
  'Follow-up date': {
    en: 'Follow-up date',
    nb: 'Oppfølgingsdato',
    sv: 'Uppföljningsdatum',
  },
  'By drawing lots': {
    en: 'By drawing lots',
    nb: 'Ved loddtrekning',
    sv: 'Genom att dra lod',
  },
  'Repeat annually': {
    en: 'Repeat annually',
    nb: 'Gjenta årlig',
  },
  'Repeats annually': {
    en: 'Repeats annually',
    nb: 'Gjentas årlig',
  },
  Elected: {
    en: 'Elected',
    nb: 'Valgt',
    sv: 'Vald',
  },
  'Elected candidates': {
    en: 'Elected candidates',
    nb: 'Valgte kandidater',
    sv: 'Valda kandidater',
  },
  'Elected candidate': {
    en: 'Elected candidate',
    nb: 'Valgt kandidat',
    sv: 'Vald kandidat',
  },
  orderOfCandidatesWSithSameNumberOfVotesDecidedRandomly: {
    en: 'The order of candidates with the same number of votes was decided by drawing lots.',
    nb: 'Rekkefølgen på kandidatene med likt antall stemmer er avgjort av systemet ved en tilfeldig loddtrekning.',
    sv: 'Ordningen på kandidater med samma antal röster bestämdes genom att dra lod.',
  },
  'On election': {
    en: 'On election',
    nb: 'På valg',
    sv: 'Vid valet',
  },
  position_electionResults: {
    en: 'Place',
    nb: 'Plass',
    sv: 'Placera',
  },
  Votes: {
    en: 'Votes',
    nb: 'Stemmer',
    sv: 'Röster',
  },
  'of votes represented': {
    en: 'of votes represented',
    nb: 'av stemmer representert',
    sv: 'av de representerade rösterna',
  },
  Candidate: {
    en: 'Candidate',
    nb: 'Kandidat',
    sv: 'Kandidat',
  },
  'Votes cast': {
    en: 'Votes cast',
    nb: 'Stemmer avgitt',
    sv: 'Avgivna röster',
  },
  'Votes for': {
    en: 'Votes for',
    nb: 'Stemmer for',
    sv: 'Röstar på',
  },
  'Votes against': {
    en: 'Votes against',
    nb: 'Stemmer imot',
    sv: 'Röstar emot',
  },
  'of votes cast': {
    en: 'of votes cast',
    nb: 'av stemmer avgitt',
    sv: 'av avgivna röster',
  },
  'of cast': {
    en: 'of cast',
    nb: 'av avgitt',
    sv: 'av avgivna',
  },
  'Total represented': {
    en: 'Total represented',
    nb: 'Totalt representert',
    sv: 'Totalt representerat',
  },
  Abstained: {
    en: 'Abstained',
    nb: 'Avstått',
    sv: 'Avstod',
  },
  Result: {
    en: 'Result',
    nb: 'Resultat',
    sv: 'Resultat',
  },
  Approved: {
    en: 'Approved',
    nb: 'Vedtatt',
    sv: 'Godkänd',
  },
  Rejected: {
    en: 'Rejected',
    nb: 'Nedstemt',
    sv: 'avvisade',
  },
  voteRuleExplanationStatutoryChange: {
    en: 'in accordance with majority requirement rules',
    nb: 'i overenstemmelse med flertallskrav',
    sv: 'i enlighet med regler för majoritetskrav',
  },
  voteRuleExplanationQualifiedMajority: {
    en: 'in accordance with qualified majority requirement',
    nb: 'i overenstemmelse med krav til kvalifisert flertall',
    sv: 'i enlighet med kvalificerad majoritet',
  },
  voteRuleExplanationUnanimity: {
    en: 'in accordance with unanimity requirement',
    nb: 'i overenstemmelse med krav til enstemmighet',
    sv: 'i enlighet med enhällighetskrav',
  },
  voteRuleExplanationInvestigation: {
    en: 'in accordance with vote approval requirement for proposition for investigation cases',
    nb: 'i overenstemmelse med stemmekrav for forslag om gransking',
    sv: 'i enlighet med kravet på röstgodkännande för förslag till utredningsärenden',
  },
  'Represented directly': {
    en: 'Represented directly',
    nb: 'Representert direkte',
    sv: 'Representeras direkt',
  },
  'Represented by pre-voting': {
    en: 'Represented by pre-voting',
    nb: 'Representert ved forhåndsstemme',
    sv: 'Representeras av förhandsröstning',
  },
  'Represented by joining': {
    en: 'Represented by joining',
    nb: 'Representert ved oppmøte',
    sv: 'Representeras genom att gå med',
  },
  'Voting shares': {
    en: 'Voting shares',
    nb: 'Stemmeberettigede aksjer',
    sv: 'Röstningsandelar',
  },
  'of voting-eligible shares': {
    en: 'of voting-eligible shares',
    nb: 'av stemme&shy;berettigede aksjer',
    sv: 'av röstberättigade aktier',
  },
  'own shares': {
    en: 'own shares',
    nb: 'egne aksjer',
    sv: 'egna aktier',
  },
  'Represented by proxy': {
    en: 'Represented by proxy',
    nb: 'Representert ved fullmakt',
    sv: 'Representeras av ombud',
  },
  'Reminder of meeting for': {
    en: 'Reminder of meeting for',
    nb: 'Påminnelse om møte for',
    sv: 'Påminnelse om möte för',
  },
  'New subgroup': {
    en: 'New subgroup',
    nb: 'Ny undergruppe',
    sv: 'Ny undergrupp',
  },
  MinutesFrom: {
    en: 'Minutes from',
    nb: 'Protokoll fra',
    sv: 'Protokoll från',
  },
  Recipients: {
    en: 'Recipients',
    nb: 'Mottakere',
    sv: 'Mottagare',
  },
  'AllRequirementsFullfilled?companyType=FLI': {
    en: 'All the formal requirements for notice and number of attendance are fulfilled.',
    nb: 'Alle de formelle krav til innkalling og antall deltagere er oppfylt.',
    sv: 'Alla formella krav för uppsägning och antal närvaro är uppfyllda.',
  },
  'AllRequirementsFullfilled?jurisdiction=NO': {
    en: 'The proceeding fulfills the requirements presented in Norwegian Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller kravene i aksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen § 6-24.',
  },
  'AllRequirementsFullfilled?companyType=ASA': {
    en: 'The proceeding fulfills the requirements presented in the Public Limited Liability Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller kravene i allmennaksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen § 6-24.',
  },
  'AllRequirementsFullfilled?jurisdiction=EN': {
    en: 'The proceeding fulfills the requirements presented in the Companies Act.',
    nb: 'Saksbehandlingen oppfyller kravene i aksjeloven.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen.',
  },
  'AllRequirementsFullfilled?jurisdiction=SE': {
    en: 'The proceeding fulfills the requirements presented in the Companies Act ch.8 § 21.',
    nb: 'Saksbehandlingen oppfyller kravene i aktiebolagslagen 8 kap. 21 §.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen kap. 8 § 21.',
  },
  'AllRequirementsFullfilled?jurisdiction=DK': {
    en: 'The proceeding fulfills the requirements presented in the Companies Act § 124.',
    nb: 'Saksbehandlingen oppfyller kravene i selskabsloven § 124.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen § 124.',
  },
  'AllRequirementsNotFullfilled?companyType=FLI': {
    en: 'The formal requirements for notice and/or number of attendance are not fulfilled.',
    nb: 'De formelle krav til innkalling og/eller antall deltagere er ikke oppfylt.',
    sv: 'De formella kraven för anmälan och / eller antal närvaro uppfylls inte.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=NO': {
    en: 'The proceeding does not meet the requirements presented in Norwegian Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i aksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller inte kraven i norska aktiebolagslagen § 6-24.',
  },
  'AllRequirementsNotFullfilled?companyType=ASA': {
    en: 'The proceeding does not meet the requirements presented in the Public Limited Liability Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i allmennaksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen § 6-24.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=EN': {
    en: 'The proceeding does not meet the requirements presented in the Companies Act.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i aksjeloven.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=SE': {
    en: 'The proceeding does not meet the requirements presented in the Companies Act ch.8 § 21.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i aktiebolagslagen 8 kap. 21 §.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen kap. 8 § 21.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=DK': {
    en: 'The proceeding does not meet the requirements presented in the Companies Act § 124.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i selskabsloven § 124.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen § 124.',
  },
  'There is quorum, decisions can be taken': {
    en: 'There is quorum, decisions can be taken',
    nb: 'Styret er beslutningsdyktig',
    sv: 'Det finns beslutsförmåga, beslut kan fattas',
  },
  'There is not a quorum, decisions cannot be taken': {
    en: 'There is not a quorum, decisions cannot be taken',
    nb: 'Styret er ikke beslutningsdyktig',
    sv: 'Det finns inte beslutsfattande, beslut kan inte fattas',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=NO': {
    en: 'There was a quorum, and the requirements listed in Norwegian Companies Act § 6-24 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til aksjeloven § 6-24.',
    sv: 'Det var beslutsfattande och kraven i norska aktiebolagslagen § 6-24 för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?companyType=ASA': {
    en: 'There was a quorum, and the requirements listed in the Public Limited Liability Companies Act § 6-24 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til allmennaksjeloven § 6-24.',
    sv: 'Det var beslutsfattande och kraven i aktiebolagslagen § 6-24 för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=EN': {
    en: 'There was a quorum, and the requirements listed in the Companies Act for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til aksjeloven.',
    sv: 'Det var beslutsfattande och kraven i företagslagen för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=SE': {
    en: 'There was a quorum, and the requirements listed in the Companies Act ch.8 § 21 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til aktiebolagslagen 8 kap. 21 §.',
    sv: 'Det var beslutsfattande och kraven i aktiebolagslagen kap. 8 § 21 för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=DK': {
    en: 'There was a quorum, and the requirements listed in the Companies Act § 124 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til selskabsloven § 124.',
    sv: 'Det var beslutsfattande och kraven i aktiebolagslagen § 124 för att fatta beslut uppfylldes.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=NO': {
    en: 'There was no quorum, and the requirements listed in Norwegian Companies Act § 6-24 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til aksjeloven § 6-24.',
    sv: 'Det fanns inget beslutsfattande och kraven i norska aktiebolagslagen § 6-24 för att fatta beslut uppfylldes inte som sådana.',
  },
  'ThereWasNoQuorumMinutesPdf?companyType=ASA': {
    en: 'There was no quorum, and the requirements listed in the Public Limited Liability Companies Act § 6-24 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til allmennaksjeloven § 6-24.',
    sv: 'Det fanns inget beslutsfattande och kraven i aktiebolagslagen § 6-24 för att fatta beslut var inte uppfyllda.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=EN': {
    en: 'There was no quorum, and the requirements listed in the Companies Act for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til aksjeloven.',
    sv: 'Det fanns inget beslutsfattande, och kraven i företagslagen för att fatta beslut uppfylldes inte som sådana.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=SE': {
    en: 'There was no quorum, and the requirements listed in the Companies Act ch.8 § 21 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til aktiebolagslagen 8 kap. 21 §.',
    sv: 'Det fanns inget beslutsfattande och de krav som anges i aktiebolagslagen kap. 8 § 21 för att fatta beslut var inte uppfyllda.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=DK': {
    en: 'There was no quorum, and the requirements listed in the Companies Act § 124 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til selskabsloven § 124.',
    sv: 'Det fanns inget beslutsfattande och kraven i aktiebolagslagen § 124 för att fatta beslut var som sådan inte uppfyllda.',
  },
  isSubsidiaryTextFli: {
    en: 'Shall not exercise voting rights',
    nb: 'Skal ikke utøve stemmerett',
    sv: 'Ska inte utöva rösträtt',
  },
  isSubsidiaryText: {
    en: 'Is a subsidiary of {{orgName}} and shall not exercise voting rights',
    nb: 'Er datterselskap av {{orgName}} og skal ikke utøve stemmerett',
    sv: 'Är ett dotterbolag till {{orgName}} och ska inte utöva rösträtt',
  },
  'isSubsidiaryText?jurisdiction=NO': {
    en: 'Is a subsidiary of {{orgName}} and shall not exercise voting rights, The Norwegian law of stocks (Aksjeloven) § 5-3 (3)',
    nb: 'Er datterselskap av {{orgName}} og skal ikke utøve stemmerett, jf. aksjeloven § 5-3 (3)',
    sv: 'Är ett dotterbolag till {{orgName}} och ska inte utöva rösträtt, jfr. den norska aktiebolagslagen § 5-3 (3)',
  },
  cantUpdateMeetingStatusTooltip: {
    en: 'Only administrators can change attendance status after the meeting has started.',
    nb: 'Kun møteadministrator kan endre din deltakerstatus etter at møtet har startet.',
    sv: 'Endast mötesadministratören kan ändra din deltagarestatus efter att mötet har startat.',
  },
  VotingTieTextHelper2: {
    en: "tie; final result is based on the meeting chair's deciding vote",
    nb: 'likt stemmetall; endelig resultat er basert på møteleders avgjørende stemme',
    sv: 'slips; slutresultatet baseras på mötesordförandens avgörande röst',
  },
  'No info': {
    en: 'No info',
    nb: 'Ingen info',
    sv: 'Ingen info',
  },
  'Rendering Failure': {
    en: 'Rendering Failure',
    nb: 'Gjengivelsesfeil',
    sv: 'Rendering misslyckande',
  },
  Send: {
    en: 'Send',
    nb: 'Sende',
    sv: 'Skicka',
  },
  Reject: {
    en: 'Reject',
    nb: 'Avvis',
    sv: 'Avvisa',
  },
  Complaint: {
    en: 'Complaint',
    nb: 'Klage',
    sv: 'Klagomål',
  },
  Bounce: {
    en: 'Bounce',
    nb: 'Sprette',
    sv: 'Studsa',
  },
  InvalidUse: {
    en: 'Invalid Use',
    nb: 'Ugyldig bruksstørrelse',
  },
  InvalidUseContract: {
    en: 'Invalid execution',
    nb: 'Ugyldig utøvelse',
  },
  UseAuthorization: {
    en: 'Use authorization',
    nb: 'Bruk fullmakt',
  },
  UseContract: {
    en: 'Register execution of agreement',
    nb: 'Registrer utøvelse av avtale',
  },
  NoAuth: {
    en: 'No authorizations have been made yet. Press the button "New authorization" to create your first authorization. Or you can import your companies earlier authorizations by clicking on the import auths button.',
    nb: 'Ingen fullmakter er foretatt ennå. Trykk på knappen "Ny fullmakt" for å opprette din første fullmakt. Ellers kan importere bedriftens tidligere autorisasjoner ved å klikke på "Importer fra Brønnøysundregistrene".',
  },
  NoAuth2: {
    en: 'No active authorizations registered',
    nb: 'Ingen aktive fullmakter registrert',
  },

  IssueSharesAuth: {
    en: 'Increase share capital',
    nb: 'Forhøye aksjekapital',
    sv: 'T',
  },
  ConvertibleAuth: {
    en: 'Issue convertible loans',
    nb: 'Ta opp konvertible lån',
    sv: 'Issue Convertible debt',
  },
  BuySharesAuth: {
    en: 'Acquire own shares',
    nb: 'Erverve egne aksjer',
    sv: 'Issue Convertible debt',
  },
  ClaimSharesAuth: {
    en: 'Acquire contractual lien in own shares',
    nb: 'Erverve avtalepant i egne aksjer',
    sv: 'Issue Convertible debt',
  },
  OptionsAuth: {
    en: 'Grant options',
    nb: 'Dele ut opsjoner',
    sv: 'Issue Convertible debt',
  },
  DividendsAuth: {
    en: 'Distribution of dividends',
    nb: 'Utdeling av utbytte',
    sv: 'Issue Convertible debt',
  },
  IssueSharesType: {
    en: 'Issue Shares',
    nb: 'Forhøye aksjekapital',
    sv: 'T',
  },
  ConvertibleType: {
    en: 'Convertible loan',
    nb: 'Ta opp konvertible lån',
    sv: 'Issue Convertible debt',
  },
  BuySharesType: {
    en: 'Aquire own shares',
    nb: 'Erverve egne aksjer',
    sv: 'Issue Convertible debt',
  },
  ClaimSharesType: {
    en: 'Claim shares',
    nb: 'Erverve avtalepant i egne aksjer',
    sv: 'Issue Convertible debt',
  },
  OptionsType: {
    en: 'Grant options',
    nb: 'Dele ut opsjoner',
    sv: 'Issue Convertible debt',
  },
  DividendsType: {
    en: 'Grant dividends',
    nb: 'Utdeling av utbytte',
    sv: 'Issue Convertible debt',
  },
  Size: {
    en: 'Size',
    nb: 'Størrelse',
    sv: 'Størrelse sv',
  },
  Remaining: {
    en: 'Remaining',
    nb: 'Rest',
  },
  SizeRemaining: {
    en: 'Size remaining',
    nb: 'Gjenværende størrelse',
    sv: 'Størrelse sv',
  },
  SizeRemainingBeforeUse: {
    en: 'Size before use',
    nb: 'Størrelse før bruk',
    sv: 'Størrelse sv',
  },
  SizeRemainingBeforeUseContract: {
    en: 'Quantity before',
    nb: 'Antall før',
    sv: 'Antall før sv',
  },
  SizeRemainingAfterUse: {
    en: 'Remaining after use',
    nb: 'Gjenværende etter bruk',
    sv: 'Størrelse sv',
  },
  SizeRemainingAfterUseContract: {
    en: 'Quantity after',
    nb: 'Antall etter',
    sv: 'Størrelse sv',
  },
  Used: {
    en: 'Used',
    nb: 'Benyttet',
  },
  UseAmountPlaceholder: {
    en: 'Use size',
    nb: 'Bruksstørrelse',
    sv: 'Størrelse sv',
  },
  UseAmountPlaceholderContract: {
    en: 'Quantity',
    nb: 'Antall',
    sv: 'Størrelse sv',
  },
  UseAmountTooltip: {
    en: 'The size of the usage',
    nb: 'Størrelsen på bruken',
    sv: 'Størrelse sv',
  },
  UseAmountTooltipContract: {
    en: 'The size of the execution',
    nb: 'Størrelsen på utøvelsen',
    sv: 'Størrelse sv',
  },

  UseDate: {
    en: 'Date Used',
    nb: 'Bruksdato',
  },
  UseDatePlaceholder: {
    en: 'Use date',
    nb: 'Bruksdato',
    sv: 'Størrelse sv',
  },
  UseDatePlaceholderContract: {
    en: 'Date',
    nb: 'Dato',
    sv: 'Størrelse sv',
  },
  UseDateTooltipContract: {
    en: 'The date of the execution',
    nb: 'Datoen til utøvelsen',
    sv: 'Størrelse sv',
  },
  UseDateTooltip: {
    en: 'The date of the usage',
    nb: 'Dato til bruken',
    sv: 'Størrelse sv',
  },
  UpdateUse: {
    en: 'Update use',
    nb: 'Oppdater bruk',
    sv: 'Størrelse sv',
  },
  NewUse: {
    en: 'Register use',
    nb: 'Registrer bruk',
    sv: 'UpdateUse sv',
  },
  NewUseContract: {
    en: 'Register execution',
    nb: 'Registrer utøvelse',
    sv: 'UpdateUse sv',
  },
  RegisterContract: {
    en: 'Register',
    nb: 'Registrer',
    sv: 'UpdateUse sv',
  },
  NewUseTitle: {
    en: 'Register use of authorization',
    nb: 'Registrer bruk av fullmakt',
    sv: 'NewUseTitle sv',
  },
  NewUseTitleContract: {
    en: 'Register execution of agreement',
    nb: 'Registrer utøvelse av avtale',
    sv: 'NewUseTitle sv',
  },
  DeleteUseTitle: {
    en: 'Delete use',
    nb: 'Slett bruk',
  },
  DeleteUseText: {
    en: 'Deleting a use cannot be undone. Do you want to delete?',
    nb: 'Sletting av bruk kan ikke angres. Ønsker du å slette?',
  },
  Created: {
    en: 'Created',
    nb: 'Vedtatt',
    sv: 'Størrelse sv',
  },
  Expires: {
    en: 'Expires',
    nb: 'Utløper',
    sv: 'Størrelse sv',
  },
  Registered: {
    en: 'Registered',
    nb: 'Registrert',
    sv: 'Størrelse sv',
  },
  IssuingSharesTitle: {
    en: 'Authorization For Issuing New Shares',
    nb: 'Fullmakt til å forhøye aksjekapital',
    sv: 'Størrelse sv',
  },
  ClaimTitle: {
    en: 'Authorization For Stock Claim',
    nb: 'Fullmakt til å erverve avtalepant i egne aksjer',
    sv: 'Størrelse sv',
  },
  ConvertibleTitle: {
    en: 'Authorization For Convertible Loan',
    nb: 'Fullmakt til å ta opp konvertibelt lån',
    sv: 'Størrelse sv',
  },
  BuySharesTitle: {
    en: 'Authorization For Buying Company Shares',
    nb: 'Fullmakt til å erverve egne aksjer',
    sv: 'Størrelse sv',
  },
  DividendsTitle: {
    en: 'Authorization For Granting Dividends',
    nb: 'Fullmakt til utdeling av utbytte',
    sv: 'Størrelse sv',
  },
  OptionsTitle: {
    en: 'Authorization For Granting Options',
    nb: 'Fullmakt opsjonsprogram',
    sv: 'Størrelse sv',
  },
  AuthBuySharesInfo: {
    en: "The general assembly's decision must contain information on how long the authorization will be valid, the highest nominal value of the shares the company can acquire in total, that is, the sum of the number of shares x nominal value stated in the articles of association, the minimum and maximum amount that can be paid for the shares, and in which ways the acquisition and disposal of own shares can take place. The general assembly's decision must be reported to the Norwegian companies register and must be registered before shares can be acquired in accordance with the authorization.",
    nb: 'Generalforsamlingens beslutning skal inneholde opplysninger om hvor lenge fullmakten skal gjelde, den høyeste pålydende verdien av aksjene selskapet i alt kan erverve, det vil si summen av antall aksjer x pålydende verdi som er oppgitt i vedtektene, det minste og høyeste beløpet som kan betales for aksjene, og om hvilke måter erverv og avhendelse av egne aksjer kan skje på. Generalforsamlingens beslutning skal meldes til Foretaksregisteret og må være registrert før aksjer kan erverves i henhold til fullmakten.',
  },
  AuthClaimInfo: {
    en: "The general assembly's decision must contain information on how long the authorization is to be valid, the highest nominal value of the shares the company can acquire in total, that is, the sum of the number of shares x nominal value stated in the articles of association, the minimum and maximum amount that can be paid for the shares, and in which ways the acquisition and disposal of own shares can take. The general assembly's decision must be reported to the Norwegian companies register and must be registered before shares can be acquired in accordance with the authorization.",
    nb: 'Generalforsamlingens beslutning skal inneholde opplysninger om hvor lenge fullmakten skal gjelde, den høyeste pålydende verdien av aksjene selskapet i alt kan erverve, det vil si summen av antall aksjer x pålydende verdi som er oppgitt i vedtektene, det minste og høyeste beløp som kan betales for aksjene, og på hvilke måter erverv og avhendelse av egne aksjer kan skje. Generalforsamlingens beslutning skal meldes til Foretaksregisteret og må være registrert før aksjer kan erverves i henhold til fullmakten.',
  },
  AuthConvertibleInfo: {
    en: "The General Assembly's decision must contain information on the total loan amount that can be taken out, the amount by which the share capital can be increased, and how long the authorization is to be valid.",
    nb: 'Generalforsamlingens beslutning skal inneholde opplysninger om det samlede lånebeløp som kan tas opp, beløpet aksjekapitalen skal kunne forhøyes med, og hvor lenge fullmakten skal gjelde.',
  },
  AuthDividendsInfo: {
    en: 'When the annual accounts for the last year have been approved, the general meeting can authorize the board to decide on the distribution of dividends. The authorization is valid until the next ordinary general meeting.',
    nb: 'Når årsregnskapet for siste år er godkjent, kan generalforsamlingen gi styret fullmakt til å beslutte utdeling av utbytte. Fullmakten gjelder frem til neste ordinære generalforsamling.',
  },
  AuthIssueSharesInfo: {
    en: "The general assembly can authorize the board to increase the share capital by issuing new shares. The general assembly's decision must state the amount by which the share capital is to be increased, and how long the authorization is to be valid. The general assembly's decision on board authorization must be reported to the Companies Register without delay. The board cannot make use of the authorization before it has been registered.",
    nb: 'Generalforsamlingen kan gi styret fullmakt til å forhøye aksjekapitalen ved nytegning av aksjer. I generalforsamlingens beslutning må det stå oppgitt beløpet aksjekapitalen skal forhøyes med, og hvor lenge fullmakten skal gjelde. Generalforsamlingens beslutning om styrefullmakt skal uten opphold meldes til Foretaksregisteret. Styret kan ikke gjøre bruk av fullmakten før den er registrert.',
  },
  AuthOptionsInfo: {
    en: 'The general assembly can authorize the board to increase the share capital by issuing new shares. In connection with such an authorization, the board at the general assembly can obtain an authorization on how much of the authorization can be used in connection with an option program.',
    nb: 'Generalforsamlingen kan gi styret fullmakt til å forhøye aksjekapitalen ved nytegning av aksjer. I forbindelse med en slik fullmakt kan styret på generalforsamling få en fullmakt på hvor mye av fullmakten som kan benyttes i forbindelse med et opsjonsprogram.',
  },
  AuthBuySharesReadMore: {
    en: 'Read more in sections 9-2 and 9-4 of the Norwegian Companies Act.',
    nb: 'Les mer i aksjeloven §§ 9-2 og 9-4.',
  },
  AuthClaimReadMore: {
    en: 'Read more in section 9-5 of the Norwegian Companies Act.',
    nb: 'Les mer i aksjeloven § 9-5.',
  },
  AuthConvertibleReadMore: {
    en: 'Read more in section 11-8 of the Norwegian Companies Act.',
    nb: 'Les mer i aksjeloven § 11-8.',
  },
  AuthDividendsReadMore: {
    en: 'Read more in section 8-2 of the Norwegian Companies Act.',
    nb: 'Les mer i aksjeloven § 8-2.',
  },
  AuthIssueSharesReadMore: {
    en: 'Read more in chapter 10 of the Norwegian Company Act.',
    nb: 'Les mer i aksjeloven kapittel 10.',
  },
  AuthBuySharesReadMoreASA: {
    en: 'Read more in section 9-2 and 9-4 of the Norwegian public Limited Liability Companies Act.',
    nb: 'Les mer i allmennaksjeloven §§ 9-2 og 9-4.',
  },
  AuthClaimReadMoreASA: {
    en: 'Read more in section 9-5 of the Norwegian public Limited Liability Companies Act.',
    nb: 'Les mer i allmennaksjeloven § 9-5.',
  },
  AuthConvertibleReadMoreASA: {
    en: 'Read more in section 11-8 of the Norwegian public Limited Liability Companies Act.',
    nb: 'Les mer i allmennaksjeloven § 11-8.',
  },
  AuthDividendsReadMoreASA: {
    en: 'Read more in section 8-2 of the Norwegian public Limited Liability Companies Act.',
    nb: 'Les mer i allmennaksjeloven § 8-2.',
  },
  AuthIssueSharesReadMoreASA: {
    en: 'Read more in the Norwegian public Limited Liability Companies Act, chapter 10.',
    nb: 'Les mer i allmennaksjeloven kapittel 10.',
  },

  ImportAuthorizations: {
    en: 'Import from Brønnøysund Registers',
    nb: 'Importer fra Brønnøysundregistrene',
    sv: 'Størrelse sv',
  },
  IssueSharesAuthCreated: {
    en: 'Issue shares authorization created',
    nb: 'Fullmakt til å øke aksjekapitalen ble registrert',
    sv: 'Størrelse sv',
  },
  ConvertibleLoanAuthCreated: {
    en: 'Convertible loan authorization created',
    nb: 'Fullmakt til å ta opp konvertibelt lån ble registrert',
    sv: 'Størrelse sv',
  },
  BuySharesAuthCreated: {
    en: 'Buy shares authorization created',
    nb: 'Fullmakt til å erverve egne aksjer ble registrert',
    sv: 'Størrelse sv',
  },
  ClaimAuthCreated: {
    en: 'Claim authorization created',
    nb: 'Fullmakt til å erverve avtalepant på egne aksjer ble registrert',
    sv: 'Størrelse sv',
  },
  DividendsAuthCreated: {
    en: 'Dividends authorization created',
    nb: 'Utbytte fullmakt ble registrert',
    sv: 'Størrelse sv',
  },
  OptionsAuthCreated: {
    en: 'Options authorization created',
    nb: 'Opsjons fullmakt ble registrert',
    sv: 'Størrelse sv',
  },
  AuthDeleted: {
    en: 'Authorization deleted',
    nb: 'Fullmakt slettet',
    sv: 'Størrelse sv',
  },
  AuthSizePlaceholder: {
    en: 'Authorization Size (NOK)',
    nb: 'Fullmaktens størrelse (NOK)',
    sv: 'Størrelse sv',
  },
  AuthSizeTooltip: {
    en: 'The size of the authorization (NOK)',
    nb: 'Størrelsen på fullmakten (NOK)',
    sv: 'Størrelse sv',
  },
  LoanSizePlaceholder: {
    en: 'Loan size (NOK)',
    nb: 'Lånets størrelse (NOK)',
    sv: 'Størrelse sv',
  },
  LoanSizeTooltip: {
    en: 'The size of the loan (NOK)',
    nb: 'Størrelsen på lånet (NOK)',
    sv: 'Størrelse sv',
  },
  AuthStartDate: {
    en: 'Date given',
    nb: 'Dato gitt',
  },
  AuthStartDateTooltip: {
    en: 'The starting date of the authorization',
    nb: 'Datoen fullmakten er gitt',
  },
  AuthExpirationDate: {
    en: 'Date expires',
    nb: 'Utløpsdato',
  },
  AuthExpirationDateTooltip: {
    en: 'The expiration date of the authorization',
    nb: 'Utløpsdatoen til fullmakten',
  },
  RegistrationDate: {
    en: 'Registered in Brreg',
    nb: 'Registrert i Brreg',
    sv: 'Størrelse sv',
  },
  RegistrationDateTooltip: {
    en: 'The Registration date in the Foretaksregister',
    nb: 'Datoen fullmakten ble registrert i Foretaksregistert',
    sv: 'Størrelse sv',
  },
  Notes: {
    en: 'Notes',
    nb: 'Notater',
    sv: 'Størrelse sv',
  },
  NotesTooltip: {
    en: 'Here you can enter free text, for example whether there are criteria that must be met for the agreement to be exercised.',
    nb: 'Here you can enter free text, for example whether there are criteria that must be met for the agreement to be exercised.',
    sv: 'Størrelse sv',
  },
  NotesPlaceholder: {
    en: 'Here you can enter notes for the authorization',
    nb: 'Her kan du skrive inn notater for fullmakten',
    sv: 'Størrelse sv',
  },
  EmailNoInfo: {
    en: 'No information about the invitation is available. Has an invitation been sent via email to this user?',
    nb: 'Ingen informasjon om invitasjonen er tilgjengelig. Er det sendt invitasjon på e-post til denne brukeren?',
  },
  Delivery: {
    en: 'Invitation has been sent via email. Click on the icon for more information.',
    nb: 'Invitasjon er sendt på e-post. Klikk på ikonet for ytterligere informasjon.',
  },
  NewUserDelivery: {
    en: 'Invitation has been sent via email.',
    nb: 'Invitasjon er sendt på e-post.',
  },
  NewUserRegistered: {
    en: 'This user is registered.',
    nb: 'Denne brukeren er registrert.',
  },

  EmailRecipients: {
    en: 'Email recipients',
    nb: 'E-postmottakere',
  },
  EmailRecipientsTooltip: {
    en: 'Here you can add recipients of the email by clicking the "Add people" button and entering their information ',
    nb: 'Her kan du legge til mottakere av e-posten ved å klikke på knappen "Legg til personer" og legge inn deres informasjon',
  },
  Open: {
    en: "The invitation has been opened in the user's email program. Click on the icon for more information.",
    nb: 'Invitasjonen er åpnet i brukerens e-postprogram. Klikk på ikonet for ytterligere informasjon.',
    sv: 'Invitasjonen er åpnet i brukerens e-postprogram. Klikk på ikonet for ytterligere informasjon.',
  },
  Click: {
    en: 'The user has clicked the button in the invitation to join the meeting. Click on the icon for more information.',
    nb: 'Brukeren har trykket på knappen i invitasjonen for å gå til møtet. Klikk på ikonet for ytterligere informasjon.',
    sv: 'Brukeren har trykket på knappen i invitasjonen for å gå til møtet. Klikk på ikonet for ytterligere informasjon.',
  },
  Library: {
    en: 'Library',
    nb: 'Bibliotek',
    sv: 'Bibliotek',
  },
  'New item': {
    en: 'New item',
    nb: 'Ny sak',
    sv: 'Nytt ämne',
  },
  'Add new agenda item': {
    en: 'Add new agenda item',
    nb: 'Legg til nytt agenda punkt',
    sv: 'Lägg till nytt ämne',
  },
  AgendaSuggestions: {
    en: 'About agenda suggestions',
    nb: 'Om agendaforslag',
    sv: 'Om agendaforslag',
  },
  ManageAgendaSuggestions: {
    en: 'Manage default agenda suggestions',
    nb: 'Administrer agendaforslag',
    sv: 'Administrer agendaforslag',
  },
  ManageAgendaSuggestionsInfo: {
    en: 'Agenda suggestions are templates for new agenda items in a meeting. Orgbrain has several agenda suggestions ready to use. In addition, you can create custom agenda suggestions for your organization. The agenda suggestions will appear when you edit the agenda for a meeting.',
    nb: 'Agendaforslag er maler for nye agendapunkter i et møte. Orgbrain har mange agendaforslag klare til bruk. I tillegg kan du lage egendefinerte agendaforslag for din organisasjon. Agendaforslagene dukker opp når du redigerer agendaen for et møte.',
    sv: '',
  },
  DeleteEmissionPlanQuestion2: {
    en: 'You are about to delete a capital increase. This cannot be undone. Do you want to delete?',
    nb: 'Du er i ferd med å slette en kapitalforhøyelse. Dette kan ikke angres. Ønsker du å slette? ',
    sv: 'Du håller på att ta bort en kapitalökning. Detta kan inte ångras. Vill du ta bort?',
  },
  FilterByTopic: {
    en: 'Filter',
    nb: 'Filtrer etter tema',
    sv: 'Filtrer etter tema',
  },
  MeetingType: {
    en: 'Meeting type',
    nb: 'Møtetype',
    sv: 'Møtetype',
  },
  MeetingTypeInfoBox: {
    en: 'Select the type of meetings the agenda item applies to. The agenda item will only appear in these meetings.',
    nb: 'Velg hvilken type møter agendapunktet gjelder for. Agendapunktet vil kun vises på disse møtene.',
    sv: 'sv',
  },
  MaximumAlternatives: {
    en: 'Maximum alternatives',
    nb: 'Antall alternativer',
    sv: 'Antall alternativer',
  },
  MaximumElected: {
    en: 'Election',
    nb: 'Personvalg',
    sv: 'Antall posisjoner',
  },
  ProposedSolution: {
    en: 'Proposed solution',
    nb: 'Forslag til vedtak',
    sv: 'Løsning',
  },
  MinuteNotes: {
    en: 'Minute notes',
    nb: 'Protokoll-notat',
    sv: 'protokoll-notat sv',
  },
  HelpText: {
    en: 'Helptext',
    nb: 'Hjelpetekst',
    sv: 'Hjelpetekst',
  },
  HelptextInfoBox: {
    en: 'Here you can optionally include help text that assists users in better understanding the matter. This could, for example, be references to a law paragraph or best practices.',
    nb: 'Her kan du eventuelt legge inn hjelpetekst som hjelper brukerne å forstå saken bedre. Dette kan for eksempel være henvisninger til en lovparagraf eller beste praksis.',
    sv: 'sv',
  },
  MinuteNotesInfoBox: {
    en: 'Here you insert the text from the agenda item that should be included in the meeting minutes.',
    nb: 'Her legger du inn teksten fra agendapunktet som skal inn i møteprotokollen.',
    sv: 'sv',
  },
  DescInfoBox: {
    en: 'Here you write the text for the agenda item that should be included in the meeting notice.',
    nb: 'Her skriver du inn tekst til agendapunktet som skal med i innkallingen til møtet.',
    sv: ' sv',
  },
  DecisionInfoBox: {
    en: 'Here you write the proposed resolution to be included in the meeting notice. This text will also appear in the draft minutes.',
    nb: 'Her skriver du inn forslag til vedtak som kommer med i innkallingen til møtet. Denne teksten vil også vises i utkast til protokoll.',
    sv: 'sv',
  },
  CategoryInfoBox: {
    en: 'Assign the agenda item to the appropriate category from the menu. This category determines the processing procedure.',
    nb: 'Gi agendapunktet riktig kategori fra menyen. Denne kategorien bestemmer saksbehandlingsprosessen.',
    sv: 'sv',
  },
  TitleInfoBox: {
    en: 'Give the agenda item a title that briefly describes the matter.',
    nb: 'Gi agendapunktet en tittel som kort beskriver saken.',
    sv: 'sv',
  },

  Category: {
    en: 'Category',
    nb: 'Kategori',
    sv: 'Kategori',
  },
  FilterAgenda: {
    en: 'Find agenda suggestions',
    nb: 'Finn agendaforslag',
    sv: 'Kategori',
  },
  NewAgenda: {
    en: 'Make new agenda suggestion',
    nb: 'Lag nytt agendaforslag',
    sv: 'Kategori',
  },
  DeleteEmissionPlanQuestion: {
    en: 'You are about to delete a capital increase. This cannot be undone. Do you want to delete?',
    nb: 'Du er i ferd med å slette en kapitalforhøyelse. Dette kan ikke angres. Ønsker du å slette? ',
    sv: 'Du håller på att ta bort en kapitalökning. Detta kan inte ångras. Vill du ta bort?',
  },
  OrgbrainSignFooter: {
    en: 'This document is signed with Orgbrain eSign\n- a standard electronic signature compliant\nwith EU regulation 910/2014 (eIDAS).',
    nb: 'Dokumentet er signert med Orgbrain eSign\n- en standard elektronisk signatur i samsvar\nmed EU-forordning 910/2014 (eIDAS).',
  },

  OrgbrainSignQRFooter: {
    en: 'For more information see\n \nOr scan the QR code',
    nb: 'For mer informasjon se\n \nEller skann QR koden',
  },

  OrgbrainSignHeader: {
    en: 'This document is signed with Orgbrain eSign - a standard electronic\nsignature compliant with EU regulation 910/2014 (eIDAS).',
    nb: 'Dokumentet er signert med Orgbrain eSign - en standard elektronisk\nsignatur i samsvar med EU-forordning 910/2014 (eIDAS).',
  },

  SignicatRequestHeader: {
    en: 'Orgbrain Signing Request',
    nb: 'Orgbrain signering forespørsel.',
  },

  SignicatTaskTitle: {
    en: 'Orgbrain signing request',
    nb: 'Orgbrain signering forespørsel.',
  },

  SignicatTaskMessage: {
    en: 'Please sign this document',
    nb: 'Vennligst signer dette dokumentet',
  },
  NOLang: {
    en: 'Norwegian',
    nb: 'Norsk',
  },
  ENLang: {
    en: 'English',
    nb: 'Engelsk',
  },
  SELang: {
    en: 'Swedish',
    nb: 'Svensk',
  },
  INFORMATION: {
    en: 'Information',
    nb: 'Informasjon',
  },
  DISCUSSION: {
    en: 'Discussion',
    nb: 'Diskusjon',
  },
  DECISION: {
    en: 'Decision',
    nb: 'Beslutning',
  },
  ELECTION: {
    en: 'Election',
    nb: 'Valg',
  },
  PAUSE: {
    en: 'Pause',
    nb: 'Pause',
  },
  NO_CATEGORY: {
    en: 'No category',
    nb: 'Ingen kategori',
  },
  ALTERNATIVE_OPTIONS: {
    en: 'Alternative options',
    nb: 'Alternative valg',
  },
  NoMeetingType: {
    en: 'Unmarked meetings',
    nb: 'Umarkerte møter',
  },
  FormalitiesTopic: {
    en: 'Formalities',
    nb: 'Formalia',
  },
  ReportingTopic: {
    en: 'Reporting',
    nb: 'Rapportering',
  },
  EconomyTopic: {
    en: 'Economy',
    nb: 'Økonomi',
  },
  PersonnelTopic: {
    en: 'Personnel',
    nb: 'Personale',
  },
  AppointmentsTopic: {
    en: 'Appointments',
    nb: 'Avtaler',
  },
  SupervisionTopic: {
    en: 'Supervision',
    nb: 'Tilsyn',
  },
  ShareholdersTopic: {
    en: 'Owners/Members',
    nb: 'Eiere/Medlemmer',
  },
  CEOTopic: {
    en: 'CEO',
    nb: 'Daglig leder',
  },
  InviteLink: {
    en: 'Invitation link',
    nb: 'Invitasjonskoblingen',
  },
  LinkDuration: {
    en: 'Duration for the invitation link',
    nb: 'Varighet for invitasjonskoblingen:',
  },
  EmailMessage: {
    en: 'Email message',
    nb: 'E-postmelding',
  },

  StrategyTopic: {
    en: 'Strategy',
    nb: 'Strategi',
  },
  ProxyTopic: {
    en: 'Power of attorney',
    nb: 'Fullmakter',
  },
  StatutesTopic: {
    en: 'Statutes',
    nb: 'Vedtekter',
  },
  NewMessageFrom: {
    en: 'New message from',
    nb: 'Ny melding fra',
  },
  ClickHereToViewTheMessage: {
    en: 'Click here to view the message',
    nb: 'Klikk her for å se meldingen',
  },
  HereLink: {
    en: 'here',
    nb: 'her',
  },
  'Email address update': {
    en: 'Email address update',
    nb: 'Oppdatering av e-postadresse',
  },
  'Please use the code below to confirm your new email address': {
    en: 'Please use the code below to confirm your new email address',
    nb: 'Vennligst bekreft din nye e-postadresse ved å benytte koden under',
  },
  resetPassword: {
    en: 'Reset password',
    nb: 'Tilbakestille passord',
  },
  addInvestorsFromSpreadsheet: {
    en: 'Add investors from spreadsheet',
    nb: 'Legg til investorer fra regneark',
  },
  weReceivedRequest: {
    en: 'We have received a request to reset the password for',
    nb: 'Vi har mottatt en forespørsel om å tilbakestille passordet for',
  },
  pleaseUseLink: {
    en: 'Please use the following link',
    nb: 'Bruk følgende lenke',
  },
  HelloEmailText: {
    en: 'Hi',
    nb: 'Hei',
  },
  InvitedToSurveyText: {
    en: 'You have been invited to respond to a survey for {{ORG}}.',
    nb: 'Du er blitt invitert til å svare på en undersøkelse for {{ORG}}.',
  },
  ReminderForSigningRequest: {
    en: 'Reminder for signing request',
    nb: 'Påminnelse om signeringsforespørsel',
  },

  GaveActionPointResponsibility: {
    en: 'gave you a new task or question titled',
    nb: 'har tildelt deg, eller lagt til informasjon, i oppgaven',
  },
  Task: {
    en: 'Task',
    nb: 'Oppgave',
  },
  WithDeadline: {
    en: 'With deadline:',
    nb: 'Frist:',
  },
  ClickBelowTask: {
    en: 'Click the button below to view the task.',
    nb: 'Klikk på knappen under for å se oppgaven.',
  },
  HasSavedInformationTask: {
    en: 'has entered new information in the task',
    nb: 'har lagt inn ny informasjon i oppgaven',
  },
  GoToTask: {
    en: 'Go to Task',
    nb: 'Gå til oppgave',
  },

  SharesHaveBeenTransferredToYou: {
    en: 'Shares have been transferred to you',
    nb: 'Aksjer har blitt overført til deg',
  },
  MessageAboutTransferOfSharesIn: {
    nb: 'Melding om overføring av aksjer i',
    en: 'Message about transfer of shares in',
  },

  TheFollowingHasBeenRegisteredOn: {
    en: 'The following has been registered on ',
    nb: 'Følgende er blitt registrert på ',
  },
  InTheShareholderRegisterOf: {
    en: ' in the shareholder register of ',
    nb: ' i aksjeeierboken til ',
  },
  Recipient: {
    en: 'Recipient',
    nb: 'Mottaker',
  },
  BestRegards: {
    en: 'Best regards',
    nb: 'Vennlig hilsen',
  },
  Sender: {
    en: 'Sender',
    nb: 'Avsender',
  },
  Hi: {
    en: 'Hi',
    nb: 'Hei',
  },
  Time: {
    en: 'Time',
    nb: 'Tidspunkt',
  },
  NewInventory: {
    en: 'New inventory',
    nb: 'Ny beholdning',
  },
  ShareClass: {
    en: 'Share class',
    nb: 'Aksjeklasse',
  },
  Amount: {
    en: 'Amount',
    nb: 'Antall',
  },
  SharesWithClass: {
    en: 'shares, with class',
    nb: 'aksjer, med aksjeklasse',
  },
  HasBeenRegisteredToYou: {
    en: 'has been registered on you in an share issue',
    nb: 'har blitt registrert på deg i en kapitalforhøyelse',
  },
  ShareWithClass: {
    en: 'share, with class',
    nb: 'aksje, med aksjeklasse',
  },
  OfYourSharesWithClass: {
    en: 'of your shares, with class',
    nb: 'av dine aksjer, med aksjeklasse',
  },
  in: {
    en: 'in',
    nb: 'i',
  },
  HasBeenTransferredToYouFrom: {
    en: 'has been transferred to you from',
    nb: 'har blitt overført til deg fra',
  },
  HasBeenTransferredTo: {
    en: 'has been transferred to',
    nb: 'har blitt overført til',
  },
  WeWantToInformYouThat: {
    en: 'We would like to inform you that',
    nb: 'Vi vil med dette opplyse deg at',
  },
  HasBeenRegisteredAsBeneficialOwner: {
    en: 'has been registered as a beneficial owner with',
    nb: 'er registrert som reell rettighetshaver hos',
  },
  HasDecided: {
    nb: 'har vedtatt å tildele',
    en: 'has decided to award',
  },
  AsFollows: {
    nb: 'som følger',
    en: 'as follows',
  },
  PayoutDate: {
    en: 'Payout date',
    nb: 'Utbetalingsdato',
  },
  PayoutPerShare: {
    en: 'Payout per share',
    nb: 'Utbetaling per aksje',
  },
  TotalPayout: {
    en: 'Total payout',
    nb: 'Total utbetaling',
  },
  TotalPaidOut: {
    en: 'Paid out total',
    nb: 'Utbetaling totalt',
  },
  PayoutAccount: {
    en: 'Payout account',
    nb: 'Utbetalingskonto',
  },
  HeadlineTransaction1: {
    en: 'Decision on award of',
    nb: 'Vedtak om tildeling av',
  },
  HeadlineTransaction2: {
    en: 'from',
    nb: 'fra',
  },
  Shareholderreciver: {
    en: 'Shareholder',
    nb: 'Aksjonær',
  },
  Shareownerreciver: {
    en: 'Shareowner',
    nb: 'Aksjeeier',
  },
  NumberOfNewShares: {
    en: 'New shares',
    nb: 'Nye aksjer',
  },
  NewShareholding: {
    en: 'New shareholding',
    nb: 'Ny beholdning',
  },
  AwardTime: {
    en: 'Award time',
    nb: 'Tidspunkt',
  },
  Regards: {
    en: 'Regards',
    nb: 'Hilsen',
  },

  NumberOfShares: {
    en: 'Your current shareholding',
    nb: 'Aksjebeholdning',
  },
  BankNrNotSet: {
    nb: 'OBS! Det ser ut som vi ikke har registrert hvilken bankkonto utbetalingen skal skje til. Vennligst ta kontakt med oss slik at vi kan få registrert dette.',
    en: 'OBS! It seems like we have not registered which bank account the payout should be made to. Please contact us so that we can register this.',
  },
  ByBeneficialOwnerMeaning: {
    en: 'The law defines a beneficial owner as a natural person or persons who ultimately owns or controls a legal person, event, entity or other association.',
    nb: 'Med reell rettighetshaver menes i loven den eller de fysiske personene som i siste instans eier eller kontrollerer en juridisk person, arrangement, enhet eller annen sammenslutning.',
  },
  YouHaveBeenRegisteredAsABeneficialOwner: {
    en: 'You have been registered as a beneficial owner',
    nb: 'Du har blitt registrert som reell rettighetshaver',
  },
  ReadMoreHere: {
    en: 'Read more',
    nb: 'Les mer',
  },
  InvitationToVoteForMeetingTime: {
    en: 'Invitation to find a meeting time',
    nb: 'Invitasjon til å finne møtetidspunkt',
  },
  Svarfrist: {
    en: 'Reply before',
    nb: 'Svarfrist',
  },
  VoteTimeButtonText: {
    en: 'Vote on time',
    nb: 'Stem på tidspunkt',
  },
  BodyTextEmailForMeetingTime: {
    en: 'You have been invited to provide input on a meeting time for a scheduled meeting {{meetingName}} in {{orgName}}. Press the button below to give your feedback.',
    nb: 'Du har blitt invitert til å gi innspill til møtetidspunkt for et planlagt møte {{meetingName}} i {{orgName}}. Trykk på knappen under for å gi din tilbakemelding.',
  },
  TransactionType: {
    en: 'Transaction',
    nb: 'Transaksjon',
  },
  TransferenceTo: {
    en: 'Transference to',
    nb: 'Overføring til',
  },
  TransferenceFrom: {
    en: 'Transference from',
    nb: 'Overføring fra',
  },
  TODO: {
    en: 'To-do',
    nb: 'Skal gjøres',
  },
  DOING: {
    en: 'Ongoing',
    nb: 'Pågår',
  },
  DONE: {
    en: 'Done',
    nb: 'Ferdig',
  },
  CRITICAL: {
    en: 'Critical',
    nb: 'Kritisk',
  },
  HIGH: {
    en: 'High',
    nb: 'Høy',
  },
  MEDIUM: {
    en: 'Medium',
    nb: 'Middels',
  },
  LOW: {
    en: 'Low',
    nb: 'Lav',
  },
  MAYBE: {
    en: 'No priority',
    nb: 'Ingen',
  },
  parValue: {
    en: 'Par value',
    nb: 'Pålydende',
  },
  Options: {
    en: 'Options',
    nb: 'Alternativer',
  },
  Instruction: {
    en: 'Instruction',
    nb: 'Instruksjon',
  },
  AttachmentTo: {
    en: 'Attachment to:',
    nb: 'Vedlegg til:',
  },
  MeetingBook: {
    en: 'Meeting book',
    nb: 'Møtebok',
  },
  sharedFolderTooltip: {
    en: 'A shared folder is a feature where you, as an administrator, can create a folder which can be shared. The folder can be shared with other organizations in the same corporate structure or with other organizations where you are the administrator. The organization that creates the folder is the owner and covers any storage and signing costs.',
    nb: 'Delt mappe er en funksjon der du som administrator kan opprette en mappe som kan deles med andre organisasjoner som er i samme konsernstruktur eller øvrige organisasjoner der du er administrator. Det er organisasjonen som oppretter mappen som er eier av mappen, og som dekker eventuelle kostnader ved lagring og signering.',
  },
  Tasks: {
    nb: 'Oppgaver',
    en: 'Tasks',
  },
  tasks: {
    nb: 'oppgaver',
    en: 'tasks',
  },
  TasksSelectDocumentType: {
    en: 'Select type of the document',
    nb: 'Velg type dokument',
  },
  AnErrorOccured: {
    en: 'An error occured',
    nb: 'Det oppstod en feil',
  },
  NewTask: {
    en: 'New task',
    nb: 'Ny oppgave',
  },
  DownloadTasks: {
    en: 'Download tasks',
    nb: 'Last ned oppgaver',
  },
  TasksModuleHelper: {
    nb: 'Her kan du lage oppgaver med tidsfrist og prioritet som du kan delegere til brukere i portalen. Du kan også opprette private oppgaver («huskeliste») som kun du har tilgang til. Det er også mulig å opprette en dialog med de som har tilgang til en oppgave.',
    en: 'Create tasks with deadlines and priorities that you can delegate to users in the portal. You can also create private tasks ("to-do list") that you alone have access to. It is also possible to create a dialogue with those who have access to a task.',
  },
  MeetingsAndTasks: {
    en: 'Meetings and tasks',
    nb: 'Møter og oppgaver',
  },
  Meetings: {
    en: 'Meetings',
    nb: 'Møter',
  },

  IncompleteTasks: {
    en: 'Incomplete tasks',
    nb: 'Uferdige oppgaver',
  },
  CompletedTasks: {
    en: 'Completed tasks',
    nb: 'Fullførte oppgaver',
  },
  MyIncompleteTasks: {
    en: 'My incomplete tasks',
    nb: 'Mine uferdige oppgaver',
  },
  MyCompletedTasks: {
    en: 'My completed tasks',
    nb: 'Mine fullførte oppgaver',
  },
  AllTasks: {
    en: 'All',
    nb: 'Alle',
  },
  SearchTasks: {
    en: 'Search',
    nb: 'Søk',
  },
  ListViewTasks: {
    en: 'List view',
    nb: 'Listevisning',
  },
  DiagramViewTasks: {
    en: 'Chart view',
    nb: 'Diagramvisning',
  },
  StatusTasks: {
    en: 'Status',
    nb: 'Status',
  },
  PriorityTasks: {
    en: 'Priority',
    nb: 'Prioritet',
  },
  TitleTasks: {
    en: 'Title',
    nb: 'Tittel',
  },
  ResponsibleTasks: {
    en: 'Responsible',
    nb: 'Ansvarlig',
  },
  ExtraResponsibleTasks: {
    en: 'Extra responsible',
    nb: 'Andre ansvarlige',
  },
  DeadlineTasks: {
    en: 'Deadline',
    nb: 'Frist',
  },
  LinkCopiedText: {
    en: 'The link is now ready to be pasted and shared with others who have portal access.',
    nb: 'Lenken er nå klar for å limes inn og deles med andre med portaltilgang.',
  },
  TaskLinkTooltip: {
    en: 'Copy the task link to the clipboard.',
    nb: 'Kopier oppgavelenken til utklippstavlen.',
  },
  EditTask: {
    en: 'Edit',
    nb: 'Rediger',
  },
  Delete: {
    en: 'Delete',
    nb: 'Slett',
  },
  'Org.nr': {
    en: 'Org.nr',
    nb: 'Org.nr',
  },
  EditUser: {
    en: 'Edit user',
    nb: 'Rediger bruker',
  },
  YouHaveNotBeenInvitedToAnySurvey: {
    en: 'You have not been invited to any surveys',
    nb: 'Du er ikke invitert til noen spørreundersøkelse',
  },
  TransfereUser: {
    en: 'Transfere user',
    nb: 'Overfør bruker',
  },
  DeleteContent: {
    en: 'Delete content',
    nb: 'Slett innhold',
  },
  PleaseConfirmPermanentDelete: {
    en: 'You are about to delete chosen content permanently. this action cannot be undone. Do you wish to delete permanently?',
    nb: 'Du er i ferd med å slette valgte innhold permanent. Dette kan ikke angres. Ønsker du å slette permanent?',
  },

  Menu: {
    en: 'Menu',
    nb: 'Meny',
  },
  rolesText: {
    en: 'Roles',
    nb: 'Roller',
  },
  OrgOrgbrainWatermarkinginfo: {
    en: 'Administrators can set settings to include a watermark on documents downloaded from the portal. This makes it easier to trace documents that have been downloaded to the person who performed the download.',
    nb: 'Det er mulig for administrator å sette innstillingene slik at det blir lagt inn et «vannmerke» («watermark») på dokumenter som lastes ned fra portalen. Dette gjør at dokumenter på avveie lettere kan spores til den som har utført nedlastingen.',
  },
  OrgEditUserInfoBox: {
    en: 'The administrator can assign and revoke access to the portal. The role(s) the users are assigned determines what they have access to. When inviting new users, make sure that the "Send invitation by email" box is checked, ensuring the new user receives the message. If you forget to do this, simply check the "Send invitation by email" box and save, and the recipient will receive an email. In the email sent, the individual will be asked to register their personal user account.',
    nb: 'Her kan administrator tildele og fjerne tilganger til portalen. Rollen(e) brukerne blir tildelt bestemmer hva de får tilgang til. Ved invitasjon av nye brukere, pass på å at feltet “Send invitasjon på e-post” er fylt ut slik at den nye brukeren mottar meldingen. Dersom du glemmer dette, er det bare å krysse av “Send invitasjon på e-post” og så lagre, da vil mottakeren motta e-post. I e-posten som sendes vil vedkommende bli bedt om å registrere sin personlige brukerkonto.',
  },
  ResendSendEmail: {
    en: 'Resend email',
    nb: 'Send epost på nytt',
  },
  userIdentityRequirementsHelp1: {
    en: 'Manual verification should be conducted before adding a new user, to ensure good user control within the portal. The users themselves must have control and access to the email with which they are invited.',
    nb: 'For å ha god kontroll på brukere som inviteres inn i portalen, bør det gjennomføres manuell kontroll før ny bruker leggs til. Portalen krever minimum at brukeren må ha kontroll på e-postadressen som de inviteres inn med.',
  },
  userIdentityRequirementsHelp2: {
    en: 'If the organization desires increased control, they can require that all users must further confirm their identity using one of the methods outlined, or else the user will be denied access to the organization. It is possible to choose both methods, but the user only needs to confirm their identity once. An administrator can override an identification by verifying the user manually.',
    nb: 'Dersom organisasjonen ønsker økt kontroll, kan det legges inn et krav om at alle brukerne må bekrefte sin identitet ytterligere med en av metodene som er angitt her, ellers blir brukeren nektet tilgang til organisasjonen. Det er mulig å velge begge metodene, men brukeren trenger kun å bekrefte sin identitet én gang. Det er mulig for en administrator å overstyre en identifisering ved å verifisere brukeren manuelt.',
  },
  userIdentityRequirementsHelp3: {
    en: 'Note: The organization will be billed NOK 5 per user who confirms their identity. See the terms of use for more information.',
    nb: 'NB: Organisasjonen faktureres kr 5 pr. bruker som bekrefter identiteten. Se brukervilkårene for ytterligere opplysninger.',
  },
  ImportOrganizationInfo: {
    en: 'Import from Brønnøysund',
    nb: 'Import fra Brønnøysund',
  },
  AskForAssistance: {
    en: 'Expert assistance',
    nb: 'Få eksperthjelp',
  },
  //I would like to be contacted by phone
  IWouldLikeToBeContactedAtPhone: {
    en: 'I would like to be contacted by phone',
    nb: 'Jeg ønsker å bli kontaktet på telefon',
  },
  EraseTooltip: {
    en: 'Erase drawings',
    nb: 'Viske ut tegninger',
  },
  CommentAndDrawTooltip: {
    en: 'Draw with note',
    nb: 'Tegne med notat',
  },
  DrawTooltip: {
    en: 'Draw freely',
    nb: 'Tegne fritt',
  },
  NoteTooltip: {
    en: 'Write a note',
    nb: 'Skriv notat',
  },
  askForAssistanceButton: {
    en: 'Get expert assistance',
    nb: 'Be om bistand',
  },
  CanAlsoContactOnEmail: {
    en: 'You can also reach us by email: support@orgbrain.no',
    nb: 'Du kan også nå oss på e-post: support@orgbrain.no',
  },
  SupportRequestFrom: {
    en: 'Support request from',
    nb: 'Forespørsel fra',
  },
  HasRequestedAssistanceOnPhone: {
    // has requested assistance. Contact the the user on
    en: 'has requested assistance. Contact the the user on phone',
    nb: 'har bedt om konsulentbistand. Kontakt brukeren på telefon',
  },
  GetAssistanceText1: {
    en: 'Are your days busy?',
    nb: 'Har du travle dager?',
  },
  GetAssistanceText2: {
    en: 'Get help with performing your tasks in the portal from a consultant who is an expert on the',
    nb: 'Få hjelp med utføre dine oppgaver i portalen av en konsulent som er ekspert på',
  },
  GetAssistanceText3: {
    nb: 'programvaren.',
    en: 'software.',
  },
  GetAssistanceText4: {
    nb: 'Pris: kr 400 for hvert påbegynt kvarter',
    en: 'Price: kr 400 for each commenced quarter',
  },

  GetAssistanceTooltip: {
    en: 'Are your days busy? Get help with performing your tasks in the portal from a consultant who is an expert on the software.',
    nb: 'Har du travle dager? Få hjelp med utføre dine oppgaver i portalen av en konsulent som er ekspert på programvaren.',
  },
  Parvalue: {
    en: 'Par value',
    nb: 'Pålydende',
  },
  NewUser: {
    en: 'New user',
    nb: 'Ny bruker',
  },
  Rights: {
    en: 'Rights',
    nb: 'Rettigheter',
  },
  Access: {
    en: 'Access',
    nb: 'Tilgang',
  },
  hideSigningTextForRoles: {
    nb: 'Utelat følgende tekst fra protokollen: "Følgende skal signere at protokollen er lest:" (OBS: Dette øker potensielt ansvaret for de som signerer.)',
    en: 'Exclude the following text from the minutes: "The following shall sign that the minutes have been read:" (NOTE: This potentially increases the responsibility for those who sign.)',
  },

  CouldNotDelete: {
    nb: 'Kunne ikke slettes',
    en: 'Could not delete',
  },
  ItIsNotPossibleToDeleteAllAdmins: {
    nb: 'Det er ikke mulig å slette alle med administratorrettigheter fra en undergruppe. Dersom du ønsker å slette undergruppen, ta kontakt med Orgbrain på support@orgbrain.no',
    en: 'It is not possible to delete all administrators from a subgroup. If you want to delete the subgroup, contact Orgbrain at support@orgbrain.no',
  },
  NewSubGroupCreated: {
    nb: 'Ny gruppe er opprettet',
    en: 'New subgroup created',
  },
  YouCanFindTheNewSubgroup: {
    nb: 'Den nye gruppen finner du som en underenhet til din organisasjon. Du kan gå til den ved å klikke på nedtrekksmenyen for organisasjoner øverst til høyre i portalen.',
    en: 'The new subgroup is found as a subunit to your organization. You can go to it by clicking on the drop-down menu for organizations at the top right of the portal.',
  },
  ChangesToPremissionsDiffersFromParent: {
    en: 'Changes have been made. There are now <b>deviations</b> in access from the folder above',
    nb: 'Det er foretatt endringer. Det er nå <b>avvik</b> i tilgang fra mappen over',
  },
  AccessControlWarningContainer: {
    en: 'Users marked in red have been given access to this level without having access to the level above. You must also grant access to the folders above for the access to take effect.',
    nb: 'Brukere som er merket rødt, er blitt gitt tilgang til dette nivået uten å ha fått tilgang til nivået over. Du må også gi tilgang på mappene over for at tilgangen skal ha effekt.',
  },
  CreateQuestionnaire: {
    en: 'Create a New Survey',
    nb: 'Opprett spørreskjema',
  },
  ExtraContacts: {
    en: 'Extra contacts: ',
    nb: 'Ekstra kontakter: ',
  },
  ExtraContactsHelpText: {
    en: 'Must be filled inn with yes to signal that the contact is an extra contact for the row above it. It is possible to have more than one extra contact for the same row, by filling in yes for each row above it will connect to the first with nothing.',
    nb: 'Må fylles inn med ja for å signalisere at kontakten er en ekstra kontakt for raden over. Det er mulig å ha flere ekstra kontakter for samme rad, ved å fylle inn ja for hver rad over vil den koble seg til den første med ingenting.',
  },
  DateSent: {
    en: 'Date sent',
    nb: 'Sendt dato',
  },
  Contract: {
    en: 'Contract',
    nb: 'Kontrakt',
  },
  dueDiligenceInfoBox: {
    en: 'Here you have the opportunity to limit which users should have access to the content of folders and files. Access you set here will be reused in all subfolders. You also have the opportunity to limit access further to fewer users in subfolders.',
    nb: 'Her har du mulighet til å avgrense hvilke brukere som skal ha tilgang til innholdet i mapper og filer. Tilganger du setter her vil gjenbrukes i alle underliggende mapper. Du har også mulighet til å avgrense tilgangen ytterligere til færre brukere i underliggende mapper.',
  },
  SendPaymentInformation: {
    en: 'Send payment information',
    nb: 'Send betalingsinformasjon',
  },
  EmissionInviteInvestors: {
    en: 'Invite investors',
    nb: 'Inviter investorer',
  },
  SubmitAndSendEmail: {
    en: 'Submit and send email',
    nb: 'Gjennomfør og send e-post',
  },
  SubmitAndSendEmailTooltipDividend: {
    en: 'Here you can complete the assignment and choose who should receive notification of the assignment by e-mail.',
    nb: 'Her kan du gjennomføre tildelingen og velge hvem som skal motta melding om tildelingen på e-post.',
  },
  SendMessage: {
    en: 'Send message',
    nb: 'Send melding',
  },

  Address: {
    en: 'Address',
    nb: 'Adresse',
  },

  VpsNumber: {
    en: 'VPS number',
    nb: 'VPS-nummer',
  },
  LeiNumber: {
    en: 'LEI number',
    nb: 'LEI-nummer',
  },
  CopyForm: {
    en: 'Copy form',
    nb: 'Kopier skjema',
  },
  dobfield: {
    en: 'Date of Birth (6 digits)',
    nb: 'Fødselsdato (6 siffer)',
  },
  PNRLable: {
    nb: 'P. nr. (5 siffer) / F.nr (11 siffer)',
    en: 'SSN (5 digits) / B.nr (11 digits)',
  },

  helpTextAnnualPlan: {
    en: 'By choosing "Based on previous meetings", a proposal for an annual plan will be made based on the board meetings that have been held in the last 12 months. The meetings will be held on the same day of the week as the year before, adjusted for any official holidays. By choosing "Completely new annual plan", you will get the opportunity to set up a completely new annual plan based on your adjustments.',
    nb: 'Ved valg av «Basert på tidligere møter» vil det bli laget forslag til årsplan basert på styremøtene som har vært de siste 12 måneder. Møtene blir lagt på samme ukedag som året før, justert for eventuelle offisielle fridager. Ved valg av «Helt ny årsplan» vil du få muligheten til å sette opp helt ny årsplan basert på dine tilpasninger.',
  },
  AddSubTask: {
    en: 'Add subtask',
    nb: 'Legg til underoppgave',
  },
  BusinessAdress: {
    nb: 'Forretningsadresse',
    en: 'Business address',
  },
  NewClaim: {
    en: 'New claim',
    nb: 'Ny pant',
  },

  DateEntered: {
    en: 'Date entered',
    nb: 'Dato innført',
  },
  PostalAddress: {
    nb: 'Postadresse',
    en: 'Postal address',
  },
  AdditionalInformation: {
    en: 'Additional information',
    nb: 'Ytterligere opplysninger',
  },
  DescriptionTask: {
    en: 'Description',
    nb: 'Beskrivelse',
  },
  YouHaveNoSubgroups: {
    en: 'You have no subgroups',
    nb: 'Du har ingen undergrupper',
  },
  PleaseAddSubgroupName: {
    nb: 'Opprett ny undergruppe',
    en: 'Create new subgroup',
  },
  CreateAdminUser: {
    nb: 'Legg til første administrator',
    en: 'Create first administrator',
  },
  Meeting: {
    en: 'Meeting',
    nb: 'Møte',
  },
  AssociatedFile: {
    en: 'Associated file',
    nb: 'Tilknyttet fil',
  },
  HidePath: {
    en: 'Hide path',
    nb: 'Skjul filsti',
  },
  ShowPath: {
    en: 'Show path',
    nb: 'Vis filsti',
  },
  SubTasks: {
    en: 'Subtasks',
    nb: 'Underoppgaver',
  },
  ParentTask: {
    en: 'Parent task',
    nb: 'Foreldreoppgave',
  },
  DependsOn: {
    en: 'Depends on',
    nb: 'Avhengig av',
  },
  Comments: {
    en: 'Comments',
    nb: 'Kommentarer',
  },
  StartTask: {
    en: 'Start',
    nb: 'Start',
  },
  ModifiedTasks: {
    en: 'Modified',
    nb: 'Endret',
  },
  ProgressTask: {
    en: 'Progress',
    nb: 'Fremdrift',
  },
  ShowFollowupInCalendarForResponsible: {
    en: 'The user responsible for the task will see the task in the calendar.',
    nb: 'Den som er satt som ansvarlig for oppgaven, vil se oppgaven i sin kalenderen.',
  },
  ShowInCalendarForResponsible: {
    en: 'Show in calendar for responsible',
    nb: 'Vis i kalenderen for ansvarlig',
  },
  ShowFollowupInCalendarForEverybody: {
    en: 'Those who have access to the the task will see the it in the calendar.',
    nb: 'De som har tilgang til oppgaven vil se den i kalenderen.',
  },
  ShowInCalendarForEverybody: {
    en: 'Show in calendar for everyone',
    nb: 'Vis i kalenderen for alle',
  },

  SendNotificationEmail: {
    en: 'Send notification by email',
    nb: 'Send varsling på e-post',
  },
  SendToResponsibleTooltip: {
    en: 'Those assigned to be responsible for the task will receive an email notification whenever a change is saved or a comment is added.',
    nb: 'De som er satt som ansvarlige for oppgaven, vil motta en varsling på e-post hver gang det lagres en endring eller legges til en kommentar.',
  },
  ResponsibleTasksTooltip: {
    en: 'Here you can add more people responsible for this task.',
    nb: 'Her kan du legge til flere ansvarlige for denne oppgaven.',
  },
  SendToResponsible: {
    en: 'Send to responsible',
    nb: 'Send til ansvarlig',
  },
  SendToMorePeople: {
    en: 'Send to more people',
    nb: 'Send til flere',
  },
  IncludeTheDescriptionTooltip: {
    nb: 'Hvis du ikke huker av her, vil kun tittelen på oppgaven sendes i e-posten, slik at mottakerne må følge lenken til Orgbrain for å se beskrivelsen av oppgaven.',
    en: 'If this box is not checked, only the title of the task will be visible in the email and the recipients must follow a link to Orgbrain to see the description of the task.',
  },
  IncludeDescriptionInEmail: {
    en: 'Include the description in the email',
    nb: 'Inkluder beskrivelsen i e-posten',
  },
  Cancel: {
    en: 'Cancel',
    nb: 'Avbryt',
  },
  Save: {
    en: 'Save',
    nb: 'Lagre',
  },
  TaskPriority: {
    en: 'Task priority',
    nb: 'Oppgaveprioritet',
  },
  TaskStatus: {
    en: 'Task status',
    nb: 'Oppgavestatus',
  },
  TaskProgress: {
    en: 'Task progress',
    nb: 'Oppgavefremdrift',
  },
  AuthToBoard: {
    en: 'Board Authorizations',
    nb: 'Styrefullmakter',
  },
  Title: {
    en: 'Title',
    nb: 'Tittel',
  },
  TitleTooltip: {
    en: 'Give the authorization a title',
    nb: 'Her gir du fullmakten en tittel',
  },
  ownersIdentity: {
    en: 'Owners identity',
    nb: 'Eiers identitet',
  },
  ThisIsAPrivateTask: {
    en: 'This is a private task',
    nb: 'Dette er en privat oppgave',
  },
  StartTime: {
    en: 'Start time',
    nb: 'Starttid',
  },
  DeleteTask: {
    en: 'Delete task',
    nb: 'Slett oppgave',
  },
  'Deleting a task cannot be undone. Do you want to delete this task permanently?': {
    en: 'Deleting a task cannot be undone. Do you want to delete this task permanently?',
    nb: 'Sletting av en oppgave kan ikke angres. Ønsker du å slette denne oppgaven permanent?',
  },
  Undo: {
    en: 'Undo',
    nb: 'Angre',
  },
  TaskCompleted: {
    en: 'Task completed',
    nb: 'Oppgave fullført',
  },
  UnlinkFile: {
    en: 'Do you want to disconnect this task from a data room file?',
    nb: 'Vil fjerne koblingen mellom denne oppgaven og dataromfilen?',
  },
  UnlinkFileWarningText: {
    en: 'The file in attachments will not be deleted automatically.',
    nb: 'Filen i vedlegg vil ikke bli slettet automatisk.',
  },
  Ok: {
    en: 'Ok',
    nb: 'Ok',
  },
  DisabledTaskTooltip: {
    en: 'You do not have permission to modify this task',
    nb: 'Du har ikke tillatelse til å endre denne oppgaven',
  },
  Critical: {
    en: 'Critical',
    nb: 'Kritisk',
  },
  High: {
    en: 'High',
    nb: 'Høy',
  },
  Medium: {
    en: 'Medium',
    nb: 'Middels',
  },
  Low: {
    en: 'Low',
    nb: 'Lav',
  },
  Maybe: {
    en: 'No priority',
    nb: 'Ingen prioritet',
  },
  None: {
    en: 'No priority',
    nb: 'Ingen prioritet',
  },
  Ongoing: {
    en: 'Ongoing',
    nb: 'Pågår',
  },
  NotDone: {
    en: 'Not done',
    nb: 'Ikke gjort',
  },
  Done: {
    en: 'Done',
    nb: 'Ferdig',
  },
  CreateATask: {
    en: 'Create a task',
    nb: 'Opprett en oppgave',
  },
  Create: {
    en: 'Create',
    nb: 'Opprett',
  },
  CreatedBy: {
    en: 'Created by',
    nb: 'Opprettet av',
  },
  PrivateTaskTooltip: {
    nb: 'Dersom du markerer en oppgave som privat, vil oppgaven kun være synlig for deg.',
    en: 'By marking the task as private, the task will only be visible to you.',
  },
  Warning: {
    en: 'Warning',
    nb: 'Advarsel',
  },

  CloseTaskEditWarning: {
    nb: 'Du har lagt inn ny informasjon som ikke er lagret. Ønsker du å fortsette uten å lagre eller gå tilbake for å lagre?',
    en: 'You have entered new information that has not been saved. Do you want to continue without saving or go back to save?',
  },
  Continue: {
    nb: 'Fortsett',
    en: 'Continue',
  },
  GoBack: {
    nb: 'Gå tilbake',
    en: 'Go back',
  },
  TaskNotChanged: {
    nb: 'Oppgaven er ikke endret',
    en: 'The task is not changed',
  },
  AddSubTaskTooltip: {
    nb: 'Her kan du opprette en ny oppgave som er del av denne oppgaven',
    en: 'Here you can create a new task that is part of this task',
  },
  NoUndoneTasks: {
    nb: 'Ingen uferdige oppgaver',
    en: 'No undone tasks',
  },
  EmailWillContainTextWarning: {
    en: 'An email will be sent. The e-mail will contain the text you have entered. Be careful about adding sensitive information to the message',
    nb: 'En e-post vil bli sendt. E-posten vil inneholde teksten du har skrevet inn. Vær forsiktig med å legge til sensitiv informasjon i meldingen',
  },
  EmailsWillBeSent: {
    en: 'Emails will be sent',
    nb: 'E-poster vil bli sendt',
  },
  instructionsTobeGivenPowerOfAthorizedInfo: {
    en: 'Here you can choose to enter instructions on how you want the recipient of the power of attorney to vote on one or more of the cases that are under consideration.',
    nb: 'Her kan du velge å legge inn en instruksjon på hvordan du ønsker at mottakeren av fullmakten skal stemme på en eller flere av de sakene som er til behandling.',
  },
  signingPowerOfAthorizedInfo: {
    en: 'If an administrator registers a power of attorney on behalf of others, the administrator signs to confirm that the power of attorney has been received.',
    nb: 'Dersom en administrator registrerer en fullmakt på vegne av andre, signerer administrator for å bekrefte at fullmakt er mottatt.',
  },
  SendNotification: {
    en: 'Send notification',
    nb: 'Send varsel',
  },
  signedBy: {
    en: 'Signed by',
    nb: 'Signert av',
  },
  LongTextInTheBottomProxy: {
    en: 'If the person who has received power of attorney don`t have access to participate digitally using Orgbrain`s portal, the person must either attend by personal appearance or otherwise stated by the company.',
    nb: 'Dersom den som er blitt tildelt fullmakt ikke er gitt tilgang til å delta digitalt via portalen, må vedkommende som er gitt fullmakt delta ved personlig oppmøte eller på annen måte oppgitt av selskapet.',
  },
  AreYouSure: {
    en: 'Are you sure?',
    nb: 'Er du sikker?',
  },
  ConfirmSendingEmails: {
    en: 'Send email notification',
    nb: 'Send varsel på e-post',
  },
  TaskAreYouSure: {
    en: 'You are about to send an email notification to the specified individuals that an update has been made to the task.\nAre you sure?',
    nb: 'Du er i ferd med å sende et varsel på e-post til de angitte personene om at det er foretatt en oppdatering på oppgaven.\nEr du sikker?',
  },
  SendPaymentInformationToInvestorsTooltip: {
    en: 'Here you will find a draft of a notification about payment that can be sent to the selected investors',
    nb: 'Her vil du finne utkast til en melding om innbetaling som kan sendes til de utvalgte investorene',
  },
  NoTasks: {
    en: 'No tasks yet',
    nb: 'Det finnes ingen oppgaver enda',
  },
  NoCompletedTasks: {
    en: 'There are no completed tasks',
    nb: 'Det finnes ingen fullførte oppgaver',
  },
  NoIncompleteTasks: {
    en: 'There are no incomplete tasks',
    nb: 'Det finnes ingen uferdige oppgaver',
  },
  YouHaveNoTasks: {
    en: 'You have no tasks',
    nb: 'Du har ingen oppgaver',
  },
  YouHaveNoIncompleteTasks: {
    en: 'You have no incomplete tasks',
    nb: 'Du har ingen uferdige oppgaver',
  },
  YouHaveNoCompletedTasks: {
    en: 'You have no completed tasks',
    nb: 'Du har ingen fullførte oppgaver',
  },
  YouHaveNoPrivateTasks: {
    en: 'You have no private tasks',
    nb: 'Du har ingen private oppgaver',
  },
  ThereAreNoTasks: {
    en: 'No tasks yet',
    nb: 'Det finnes ingen oppgaver enda',
  },
  PrivateTasks: {
    en: 'Private tasks',
    nb: 'Private oppgaver',
  },
  NoemissionsYet: {
    en: 'There are no ongoing share issues',
    nb: 'Du har ingen pågående kapitalforhøyelser',
  },
  CopyShareIssueEmission: {
    en: 'Clone previous share issue',
    nb: 'Klone tidligere kapitalforhøyelse',
  },
  NewShareIssueMenu: {
    en: 'Create',
    nb: 'Opprett',
  },
  CopyEmissionTooltip: {
    en: 'Create a new share issue using a previous one as a template.',
    nb: 'Opprett en ny kapitalforhøyelse ved å bruke en tidligere som mal.',
  },
  CreateEmissionTooltip: {
    en: 'Create an entirely new share issue.',
    nb: 'Opprett en helt ny kapitalforhøyelse.',
  },
  NoEmissionsFound: {
    en: 'No previous or ongoing share issues were found.',
    nb: 'Ingen tidligere eller pågående kapitalforhøyelser ble funnet.',
  },
  SelectShareIssueEmission: {
    en: 'Select previous share issue',
    nb: 'Velg tidligere kapitalforhøyelse',
  },
  ShareIssueDeletionSuccess: {
    en: 'The share issue is now deleted',
    nb: 'Kapitalforhøyelsen er nå slettet',
  },
  ShareIssueDeletionFailed: {
    en: 'Error: deletion failed',
    nb: 'Error: sletting feilet',
  },

  NoInvestorsInvited: {
    en: 'No investors invited',
    nb: 'Ingen investorer er invitert',
  },
  ShareRegisterInfo4: {
    en: 'looks like right now that provide a detailed history of the current membership situation.',
    nb: 'ser ut som akkurat nå som gir en detaljert historikk over den aktuelle medlemssituasjonen.',
  },
  NewContract: {
    en: 'New contract',
    nb: 'Ny kontrakt',
  },
  ContractOwner: {
    en: 'Contract owner',
    nb: 'Kontraktsinnehaver',
  },
  History: {
    en: 'History',
    nb: 'Historikk',
  },
  TaxIncentive: {
    en: 'Tax Incentive',
    nb: 'Skatteinsentiv',
  },
  ImportContracts: {
    en: 'Import contracts',
    nb: 'Importer kontrakter',
  },
  ExportContracts: {
    en: 'Export contracts',
    nb: 'Eksporter kontrakter',
  },
  andContractInformation: {
    en: 'and contract information',
    nb: 'og kontraktsinformasjon',
  },
  OrgbrainUses: {
    en: 'Orgbrain uses',
    nb: 'Orgbrain bruker',
  },
  ContractTitle: {
    en: 'Contract title',
    nb: 'Avtaletittel',
  },
  NameOfShareclass: {
    en: 'Name of share class',
    nb: 'Navn på aksjeklasse',
  },
  ImportContractTitleText: {
    en: 'Is the title of the contract, and is used to identify the contract. This is a required field and is used to identify exisitng contracts in the registry.',
    nb: 'Er tittelen på kontrakten, og brukes til å identifisere kontrakten. Dette er et obligatorisk felt og brukes til å identifisere eksisterende kontrakter i registeret.',
  },
  contractedAmount: {
    en: 'Contracted amount',
    nb: 'Kontraktbeløp',
  },
  ImportContractedAmountText: {
    en: 'Is the contracted amount of the contract, and is used as the amount field in contracts. This is a required field and is used to sett the amount in a contract.',
    nb: 'Er kontraktsbeløpet i kontrakten, og brukes som beløpsfelt i kontrakter. Dette er et obligatorisk felt og brukes til å sette beløpet i en kontrakt.',
  },
  contractType: {
    en: 'Contract type',
    nb: 'Kontraktstype',
  },
  ImportContractTypeText: {
    en: 'Is the type of the contract, and is used to identify the contract type. This is an required field and is used to identify exisitng contracts in the registry and sett the contract type.',
    nb: 'Er kontraktstypen, og brukes til å identifisere kontraktstypen. Dette er et obligatorisk felt og brukes til å identifisere eksisterende kontrakter i registeret og sette kontraktstypen.',
  },
  contractStatus: {
    en: 'Contract status',
    nb: 'Kontrakt status',
  },
  ImportContractStatusText: {
    en: 'Is the status of the contract, and is used to identify the contract status. This is an optional field and is used to sett contract status.',
    nb: 'Er kontrakten status, og brukes til å identifisere kontrakten status. Dette er et valgfritt felt og brukes til å sette kontrakten status.',
  },
  contractExpiryDate: {
    en: 'Contract expiry date',
    nb: 'Kontrakt utløpsdato',
  },
  ImportContractExpiryDateText: {
    en: 'Is the expiry date of the contract, and is used to identify the contract expiry date. This is an optional field and is used to sett contract expiry date.',
    nb: 'Er kontraktens utløpsdato, og brukes til å identifisere kontrakten utløpsdato. Dette er et valgfritt felt og brukes til å sette kontrakten utløpsdato.',
  },
  strikePrice: {
    en: 'Strike price',
    nb: 'Innløsningskurs',
  },
  StrikePriceTable: {
    en: 'Strike price',
    nb: 'Kurs',
  },
  ReadMessageMenu: {
    en: 'Seen by',
    nb: 'Sett av',
  },
  ChatSeenBy: {
    en: 'Seen by: ',
    nb: 'Sett av: ',
  },
  CurrentUserText: {
    en: 'You',
    nb: 'Deg',
  },
  ImportStrikePriceText: {
    en: 'Is the strike price of the contract, and is used to identify the contract strike price. This is a required field and is used to sett contract strike price.',
    nb: 'Er innløsningskursen på kontrakten, og brukes til å identifisere kontrakten innløsningskurs. Dette er et obligatorisk felt og brukes til å sette kontrakten innløsningskurs.',
  },
  totalLoan: {
    en: 'Total loan',
    nb: 'Lånebeløp',
  },
  ImportTotalLoanText: {
    en: 'Is the total loan of the contract, and is used to identify the contract total loan in the loan contract type. This is a optional field and is used in loans to sett contract total loan amount.',
    nb: 'Er lånebeløpet på kontrakten, og brukes til å identifisere kontrakten lånebeløp i lånekontraktstypen. Dette er et valgfritt felt og brukes i lån til å sette kontrakten lånebeløp.',
  },
  effectiveInterestRate: {
    en: 'Effective interest rate',
    nb: 'Effektiv lånerente',
  },
  ImportEffectiveInterestRateText: {
    en: 'Is the effective interest rate of the contract, and is used to identify the contract effective interest rate in the loan contract type. This is a optional field and is only used in loans to sett contract interest rate.',
    nb: 'Er den effektive lånerenten på kontrakten, og brukes til å identifisere kontrakten effektive lånerente i lånekontraktstypen. Dette er et valgfritt felt og brukes kun i lån til å sette kontrakten effektive lånerente.',
  },
  convertibleShareOfLoan: {
    en: 'Convertible share of loan',
    nb: 'Konverteringsbeløp',
  },
  ImportConvertibleShareOfLoanText: {
    en: 'Is the convertible share of loan of the contract, and is used to identify the contract convertible share of loan in the loan contract type. This is a optional field and is only used in loans to sett contract convertible share of loan.',
    nb: 'Er konverteringsbeløpet på kontrakten, og brukes til å identifisere kontrakten konverteringsbeløp i lånekontraktstypen. Dette er et valgfritt felt og brukes kun i lån til å sette kontrakten konverteringsbeløp.',
  },
  contractTerms: {
    en: 'Contract terms',
    nb: 'Betingelser',
  },
  ImportContractTermsText: {
    en: 'Is the contract terms of the contract, and is used to identify the contract terms in the loan contract type. This is a optional field and is only used in loans to sett contract terms.',
    nb: 'Er betingelsene på kontrakten, og brukes til å identifisere kontrakten betingelser i lånekontraktstypen. Dette er et valgfritt felt og brukes kun i lån til å sette kontrakten betingelser.',
  },
  contractPaymentStructure: {
    en: 'Contract payment structure',
    nb: 'Lånets betalingsstruktur',
  },
  ImportContractPaymentStructureText: {
    en: 'Is the contract payment structure of the contract, and is used to identify the contract payment structure in the loan contract type. This is a optional field and is only used in loans to sett contract payment structure.',
    nb: 'Er betalingsstrukturen på kontrakten, og brukes til å identifisere kontrakten betalingsstruktur i lånekontraktstypen. Dette er et valgfritt felt og brukes kun i lån til å sette kontrakten betalingsstruktur.',
  },
  InTheImport: {
    en: 'in the import',
    nb: 'i importen',
  },
  Strikeprice: {
    en: 'Strike price',
    nb: 'Innløsningskurs',
  },
  ContractInvestor: {
    en: 'Investor',
    nb: 'Investor',
  },
  NoShareClasses: {
    en: 'No share classes',
    nb: 'Ingen aksjeklasser',
  },
  CreateShareClassToSolvetheproblem: {
    en: 'You need to create a shareclass or import to use this functionality',
    nb: 'Du må opprette en aksjeklasse eller importere for å bruke denne funksjonaliteten',
  },
  CreateShareclass: {
    en: 'Create share class',
    nb: 'Opprett aksjeklasse',
  },
  MoreOptions: {
    en: 'More options',
    nb: 'Flere valg',
  },
  ShowLess: {
    en: 'Show less',
    nb: 'Vis mindre',
  },
  parvalueTooltip: {
    nb: 'Legg inn pålydende beløp per aksje som angitt i vedtektene',
    en: 'Enter the par value per share as stated in the articles of association',
  },
  NoRegisteredShareclass: {
    en: 'There are no registered',
    nb: 'Det er ingen registrerte',
  },
  ReadMoreOnEmissions: {
    en: 'Read more about share issues in orgbrain',
    nb: 'Les mer om kapitalforhøyelser i orgbrain',
  },
  CreateEmission: {
    en: 'Create share issue',
    nb: 'Opprett kapitalforhøyelse',
  },
  sharePrice: {
    nb: 'Aksjekurs',
    en: 'Share price',
  },

  NewCloneShareIssue: {
    en: 'New/clone share issue',
    nb: 'Ny/klone kapitalforhøyelse',
  },
  PointZeroNewShareIssueInfo: {
    en: 'Go to "Create" to initiate a brand new capital increase, or to create a new one by cloning a previous capital increase as a template.',
    nb: 'Gå til "Opprett" for å lage en helt ny kapitalforhøyelse, eller for å opprette en ny ved å klone en tidligere kapitalforhøyelse som mal.',
  },
  PointTwoNewShareIssueInfo: {
    en: 'Make an order form based on a template and add potentially relevant attachments to investors',
    nb: 'Lag en bestillingsblankett basert på mal og legg til eventuelle relevante vedlegg til investorene.',
  },
  CanAddShareclass: {
    en: 'You can add',
    nb: 'Du kan legge til',
  },
  UploadHelpShareclass: {
    en: 'by uploading data in the',
    nb: 'ved å laste opp data i',
  },
  OrCreateShareclass: {
    en: 'Alternatively, you can create a',
    nb: 'Alternativt kan du opprette en',
  },
  ManuallyShareclass: {
    en: 'manually by pressing "New',
    nb: 'manuelt ved å trykke "Ny',
  },
  WhereManuallyShareClass: {
    en: 'at the top right corner of this page',
    nb: 'øverst i høyre hjørne på denne siden',
  },
  ThisActionCannotBeUndoneDialog: {
    nb: 'Denne handlingen kan ikke angres.',
    en: 'This action cannot be undone.',
    sv: 'Denna åtgärd kan inte ångras.',
  },
  shareClasses: {
    en: 'share classes',
    nb: 'aksjeklasser',
  },
  shareClass: {
    en: 'share class',
    nb: 'aksjeklasse',
  },
  shareRegistry: {
    en: 'share registry',
    nb: 'aksjeregister',
  },
  shareCategories: {
    en: 'share categories',
    nb: 'aksjekategorier',
  },
  shareCategory: {
    en: 'share category',
    nb: 'aksjekategori',
  },
  unitRegister: {
    en: 'unit register',
    nb: 'enhetsregister',
  },
  GenerateSharenumbers: {
    en: 'Generate share numbers',
    nb: 'Generer aksjenummer',
  },
  sectionCategories: {
    en: 'section categories',
    nb: 'seksjonskategorier',
  },
  sectionCategory: {
    en: 'section category',
    nb: 'seksjonskategori',
  },
  sectionOwnerRegister: {
    en: 'section owner register',
    nb: 'seksjonseierregister',
  },
  memberCategories: {
    en: 'member categories',
    nb: 'medlemskategorier',
  },
  memberCategory: {
    en: 'member category',
    nb: 'medlemskategori',
  },
  membershipRegister: {
    en: 'membership register',
    nb: 'medlemsregister',
  },
  Action: {
    en: 'Action',
    nb: 'Handling',
  },
  SendInvitation: {
    en: 'Send invitation',
    nb: 'Send invitasjon',
  },
  SendInvitationEmissionHelpText: {
    en: 'Invite the investor to sign an order form where they can fill out the investment amount.',
    nb: 'Inviter investoren til å signere en tegningsblankett hvor de kan fylle ut investeringsbeløpet.',
  },
  PrefillShares: {
    en: 'Prefill shares',
    nb: 'Forhåndsutfyll aksjer',
  },
  PrefillSharesHelpText: {
    en: 'Register the investor as ordering a specific investment amount (useful if a paper order form has been received)',
    nb: 'Registrer investoren som bestiller et spesifikt investeringsbeløp (nyttig hvis en papirordre er mottatt)',
  },
  AddInvestorHelpText: {
    en: 'Here you can add, prefill shares, or invite a single investor manually. If the investor does not exist in the system from before, you can create a new investor by clicking "New investor".',
    nb: 'Her kan du legge til, fylle ut aksjer for, eller invitere en enkelt investor manuelt. Eksisterer ikke investoren i systemet fra før, kan du lage en ny investor ved å trykke på "Ny investor".',
  },
  Invitations: {
    en: 'Invitations',
    nb: 'Invitasjoner',
  },
  Add: {
    en: 'Add',
    nb: 'Legg til',
  },
  Modified: {
    en: 'Modified',
    nb: 'Endret',
  },
  NewAuthorization: {
    en: 'New authorization',
    nb: 'Ny fullmakt',
  },
  AuthorizationsModuleInfo: {
    en: 'The board can register authorizations granted by the general meeting to the board. Using the "New Authorization" button, you can either add authorizations manually or import all authorizations registered in the Brønnøysund Registers.',
    nb: 'Her kan styret registrere fullmakter som generalforsamlingen har gitt styret. Via knappen "Ny fullmakt" kan du enten legge til fullmakter manuelt eller importere alle fullmakter som er registrert i Brønnøysundregistrene.',
  },
  Summary: {
    en: 'Summary',
    nb: 'Oversikt',
  },
  OrderedInRelationToMinimum: {
    en: 'Ordered in relation to the minimum requirement of',
    nb: 'Bestilt i forhold til minstekravet på',
  },
  shares: {
    en: 'shares',
    nb: 'aksjer',
  },
  ContractOwnerAlt: {
    en: 'Owner',
    nb: 'Innehaver',
  },
  Active: {
    en: 'Active',
    nb: 'Aktive',
  },
  AuthorizationImportError: {
    en: 'Error while importing authorizations',
    nb: 'Feil ved import av fullmakter',
  },
  AuthorizationImportSuccess: {
    en: 'Authorizations imported',
    nb: 'Fullmakter importert',
  },
  DeleteAuthorization: {
    en: 'Delete authorization',
    nb: 'Slett fullmakt',
  },
  DeleteAuthorizationPrompt: {
    en: 'Are you sure you want to delete this authorization?',
    nb: 'Er du sikker på at du vil slette denne fullmakten?',
  },
  InvestorExistsErrorEmission: {
    en: 'This investor already exists, you might want to uncheck new investor and search for them instead',
    nb: 'Denne investoren finnes allerede, du vil kanskje fjerne haken for ny investor og heller søke etter dem',
  },
  SimilarNameEmissionWarning: {
    en: 'There is an investor with a similar name in the registry. If this is the investor you want to add, uncheck new investor and search for this person',
    nb: 'Det finnes en investor med lignende navn i registeret. Hvis dette er investoren du vil legge til fjern hukingen fra ny investor og søk etter denne personen',
  },
  All: {
    en: 'All',
    nb: 'Alle',
  },
  Liquidation: {
    en: 'Liquidation',
    nb: 'Likvidasjon',
  },

  Equal_partial_liquidation: {
    en: 'Equal partial liquidation',
    nb: 'Likvidasjon lik fordeling',
  },
  Unequal_partial_liquidation: {
    en: 'Unequal partial liquidation',
    nb: 'Likvidasjon ulik fordeling',
  },
  Taxable_merger: {
    en: 'Taxable merger',
    nb: 'Skattepliktig fusjon',
  },
  Taxable_demerger: {
    en: 'Taxable demerger',
    nb: 'Skattepliktig fisjon',
  },
  Cancellation_of_treasury_shares_to_cover_losses_and_allocations_to_funds: {
    en: 'Cancellation of treasury shares to cover losses and allocations to funds',
    nb: 'Sletting av egne aksjer for å dekke tap og overføringer til fond',
  },
  Taxable_redemption_in_connection_with_TaxFree_merger_Or_demerger: {
    en: 'Taxable redemption in connection with TaxFree merger Or demerger',
    nb: 'Skattepliktig innløsning i forbindelse med skattefri fusjon eller fisjon',
  },
  Relocation_of_company_to_and_from_Norway: {
    en: 'Relocation of company to and from Norway',
    nb: 'Flytting av selskap til og fra Norge',
  },
  OnlyVisibleToBoard: {
    en: 'To board only',
    nb: 'Til styret',
  },
  discountType: {
    en: 'Rabatttype',
    nb: 'Discount type',
  },
  YourContract: {
    en: 'Your contract',
    nb: 'Din kontrakt',
  },
  Entity: {
    en: 'Entity',
    nb: 'Entitet',
  },
  Maturity: {
    en: 'Maturity',
    nb: 'Forfall',
  },
  Status: {
    en: 'Status',
    nb: 'Status',
  },
  Strike: {
    en: 'Strike',
    nb: 'Strike',
  },
  LastSharePrice: {
    en: 'Last share price',
    nb: 'Siste aksjekurs',
  },
  EstimatedValueOfPosition: {
    en: 'Estimated position value',
    nb: 'Estimert verdi på posisjon',
  },
  YourPosition: {
    en: 'Your position',
    nb: 'Din posisjon',
  },
  StrikeRelativeToSharePrice: {
    en: 'Strike relative to share price',
    nb: 'Strike i forhold til aksjekurs',
  },
  NumberOfOwners: {
    en: 'Number of owners',
    nb: 'Antall eiere',
  },
  TotalNumberOfShares: {
    en: 'Total number of shares',
    nb: 'Totalt antall aksjer',
  },
  EstimatedValueOfCompany: {
    en: 'Estimated company value',
    nb: 'Estimert verdi på selskap',
  },
  Company: {
    en: 'Company',
    nb: 'Selskap',
  },
  YouHaveNoPositions: {
    en: 'You have no positions',
    nb: 'Du har ingen posisjoner',
  },
  YouHaveNoContracts: {
    en: 'You have no agreements',
    nb: 'Du har ingen avtaler',
  },
  Percentage: {
    en: 'Percentage',
    nb: 'Prosent',
  },
  OrderForms: {
    en: 'Order forms',
    nb: 'Bestillingsblanketter',
  },
  InvestorPortfolioInfo: {
    en: 'See your investments, financial agreements, and order forms for ongoing capital increases.',
    nb: 'Her kan du se dine investeringer, finansielle kontrakter og bestillingsblanketter for pågående kapitalforhøyelser.',
  },
  Organization: {
    en: 'Organization',
    nb: 'Organisasjon',
  },
  RemainingAmountContract: {
    en: 'Remaining',
    nb: 'Gjenværende',
  },
  RemainingTooltip: {
    en: 'Here you enter the number of shares that is remaining in the agreement',
    nb: 'Her skriver du inn antall aksjer som er igjen i avtalen',
  },
  //BOARDMATCH
  CouldNotGenerateCompanyvalue: {
    en: 'Could not generate company value',
    nb: 'Kunne ikke generere selskapsverdi',
  },
  Error: {
    en: 'Error',
    nb: 'Feil',
  },
  Boardmatch: {
    en: 'Boardmatch',
    nb: 'Styrematch',
  },
  BoardmatchCandidate: {
    en: 'Boardmatch Me',
    nb: 'Styrematch meg',
  },
  BoardmatchOrg: {
    en: 'Boardmatch Org',
    nb: 'Styrematch org',
  },
  AboutBoardmatch: {
    en: 'About',
    nb: 'Om Styrematch',
  },

  RedirectHeader: {
    en: 'Redirect',
    nb: 'Omdirigering',
  },
  RedirectMessage: {
    en: 'Are you sure you want to redirect to the website below:',
    nb: 'Er du sikker på at du vil omdirigeres til nettstedet nedenfor:',
  },
  MenuCandidate: {
    en: 'Candidate',
    nb: 'Deg som kandidat',
  },
  MenuOrg: {
    en: 'Organization',
    nb: 'Din organisasjon',
  },
  City: {
    en: 'City',
    nb: 'Poststed',
  },
  PublicAdvertTooltip: {
    en: 'By publishing your advertisement, you will gain access to and be matched with relevant candidates',
    nb: 'Ved å publisere annonsen deres, vil dere få tilgang til og bli matchet med relevante kandidater',
  },
  CandidatePublishProfileTooltip: {
    en: 'By publishing your profile, you will gain access to and be matched with relevant positions',
    nb: 'Ved å publisere profilen din, vil du få tilgang til og bli matchet med relevante stillinger',
  },
  CandidateAnonymousProfileTooltip: {
    en: 'By making your profile anonymous, your name or your LinkedIn profile will not be visible when someone views your profile',
    nb: 'Ved å gjøre profilen anonym, vil ikke navnet ditt eller LinkedIn-profilen din være synlig når noen ser på din profil',
  },
  CandidateProfileInfoBox: {
    en: 'This is where you put in information about your personal profile. It is up to you how much information you wish to include, but we recommend that you put in as much as possible to make it easier to find a good match. By making your profile anonymous, your name or your LinkedIn profile will not be visible when someone views your profile.',
    nb: 'Her legger du inn opplysninger om din personlige profil. Det er opp til deg hvor mye informasjon du ønsker å inkludere, men vi anbefaler at du legger inn så mye som mulig for å gjøre det enklere for løsningen å finne en passende match. Ved å gjøre profilen anonym, vil ikke navnet ditt eller LinkedIn-profilen din være synlig når noen ser på din profil.',
  },
  OrgProfileInfoBox: {
    en: 'This is where you register information about your business and what type of candidate you seek. It is up to the business how much information they give, but we recommend that you put in as much as possible to make it easier to find a good match. If you are seeking candidates for multiple roles, you have to create a new advertisement for every role you are searching for.',
    nb: 'Her registrerer dere opplysninger om virksomheten og hvilken type kandidat dere søker. Det er opp til virksomheten å velge hvor mye informasjon man legger inn, men vi anbefaler at dere legger inn så mye som mulig for å gjøre det enklere for løsningen å finne en god match. Hvis dere er ute etter kandidater til ulike verv, må det opprettes en ny annonse for hvert verv dere søker etter.',
  },

  BoardmatchSubheader: {
    en: 'We match your profile with the right company',
    nb: 'Vi matcher din profil med den rette virksomheten',
  },
  BoardmatchSubheaderOrg: {
    en: 'We match your advertisement with the right candidate',
    nb: 'Vi matcher din annonse med den rette kandidaten',
  },
  BoardmatchDescription: {
    en: 'Boardmatch is a feature that connects candidates seeking positions for boards, committees, advisory boards, and other corporate roles with companies looking for such positions.',
    nb: 'Styrematch er en funksjon som kobler kandidater som er ute etter verv i styrer, komiteer, advisory board og andre selskapsverv, med virksomheter som er ute etter slike verv.',
  },
  BoardmatchDescription2: {
    en: 'When you create a profile, you gain access to advertisements from companies that match the criteria you have specified. Similarly, companies will be able to see your profile if there is a match.',
    nb: 'Når du oppretter en profil i løsningen, får du tilgang til annonser fra virksomheter som matcher de kriteriene du har spesifisert. På samme måte vil virksomheter kunne se din profil hvis det er en match.',
  },
  BoardmatchDescription3: {
    en: 'The company will then have the opportunity to send a message expressing their interest in initiating a dialogue.',
    nb: 'Virksomheten vil da ha mulighet til å sende en melding om at de er interessert i å innlede en dialog.',
  },
  BoardmatchDescription4: {
    en: 'You can choose whether your name should be visible to others or if you prefer to be anonymous. Only the companies that have published an advertisement and that match your profile will have access to view it.',
    nb: 'Du kan velge om ditt navn skal være synlig for andre eller om du ønsker å være anonym. Kun de virksomhetene som har publisert en annonse og som matcher med profilen din, vil ha tilgang til å se den.',
  },
  BoardmatchDescriptionOrg: {
    en: 'Boardmatch is a feature that connects businesses looking for candidates for board positions, committees, advisory boards and other corporate roles, with candidates seeking such roles.',
    nb: 'Styrematch er en funksjon som kobler virksomheter som er ute etter kandidater til styret, komiteer, advisory board og andre selskapsverv, med kandidater som er ute etter slike verv.',
  },
  BoardmatchDescription2Org: {
    en: 'When you publish an advertisement through «Boardmatch Org», your organization will gain access to a selection of registered candidates who meet the criteria you have defined. Similarily, candidates who create a profile will be presented with advertisements that match their profile.',
    nb: 'Når du publiserer en stillingsannonse gjennom «Styrematch org», vil virksomheten din få tilgang til et utvalg av registrerte kandidater som møter de kriteriene dere har definert. På tilsvarende vis vil kandidater som oppretter en profil bli presentert for annonser som matcher deres profil.',
  },
  BoardmatchDescription3Org: {
    en: 'This allows candidates with a match to send a message to the organization to express their interest in initiating a dialogue.',
    nb: 'Dette gir kandidatene som har en match mulighet til å sende en melding til virksomheten for å uttrykke sin interesse i å innlede en dialog.',
  },
  BoardmatchDescription4Org: {
    en: 'Candidates can choose whether they want to have a profile where their name is visible, or if they prefer to remain anonymous. Your organization can also use the system to build and maintain a network of potential candidates.',
    nb: 'Kandidater kan velge om de ønsker å ha en profil der navnet er synlig for andre, eller om de foretrekker å være anonym. Din virksomhet kan også benytte systemet til å bygge og vedlikeholde et nettverk med potensielle kandidater.',
  },
  HowItWorks: {
    en: 'How it works',
    nb: 'Slik fungerer det',
  },
  HowItWorksDesc: {
    en: 'The candidate creates a profile and the company creates an advertisement. Our algorithms find matches, and dialogue can be initiated.',
    nb: 'Kandidaten oppretter profil og virksomheten lager en annonse. Våre algoritmer finner match, og dialog kan innledes.',
  },
  RegisterAsCandidate: {
    en: 'Register as candidate',
    nb: 'Register deg som kandidat',
  },
  RegisterOrganization: {
    en: 'Register organization',
    nb: 'Registrer organisasjon',
  },
  GoToCandidateProfile: {
    en: 'Go to candidate profile',
    nb: 'Gå til din kandidatprofil',
  },
  GoToOrgPage: {
    en: 'Go to advertisements',
    nb: 'Gå til deres stillingsannonser',
  },
  AddCandidateProfile: {
    en: 'Create profile',
    nb: 'Opprett profil',
  },
  NoCandidateProfile: {
    en: 'You have not created a profile yet.',
    nb: 'Du har ikke opprettet en profil ennå.',
  },
  Profile: {
    en: 'Profile',
    nb: 'Profil',
  },
  Published: {
    en: '(Published)',
    nb: '(Publisert)',
  },
  PublishedTooltip: {
    en: 'Your profile is published and visible to businesses.',
    nb: 'Profilen er publisert og synlig for virksomheter.',
  },
  PublishedAdTooltip: {
    en: 'Your avdertisement is published and visible to candidates.',
    nb: 'Annonsen er publisert og synlig for kandidater.',
  },
  NotPublished: {
    en: '(Not published)',
    nb: '(Ikke publisert)',
  },
  NotPublishedTooltip: {
    en: 'To publish your profile, check "Public profile" when editing.',
    nb: 'For å publisere profilen din, huk av "Publisert profil" når du redigerer.',
  },
  NotPublishedAdTooltip: {
    en: 'To publish your advertisement, check "Publish advertisement" when editing.',
    nb: 'For å publisere annonsen, huk av "Publiser annonse" når du redigerer.',
  },
  PublishAdToSeeMatch: {
    en: 'Publish an advertisement to find candidates.',
    nb: 'Publiser en annonse for å finne kandidater.',
  },
  PublishProfileToSeeMatch: {
    en: 'Publish your profile to find a match.',
    nb: 'Publiser din profil for å finne en match.',
  },
  RerouteToConnectedProfile: {
    en: 'Go to their profile',
    nb: 'Gå til deres profil',
  },
  ConnectedProfileTooltip: {
    en: 'Go to their profile',
    nb: 'Gå til deres profil',
  },
  Anonymous: {
    en: 'Anonymous',
    nb: 'Anonym',
  },
  AnonymousProfile: {
    en: 'Make profile anonymous',
    nb: 'Gjør profil anonym',
  },
  OrganizationName: {
    en: 'Organizaiton name',
    nb: 'Organisasjonens navn',
  },
  Name: {
    en: 'Name',
    nb: 'Navn',
  },
  DeleteProfile: {
    en: 'Delete profile',
    nb: 'Slett profil',
  },
  DeleteFromNetwork: {
    en: 'Delete from network',
    nb: 'Slett fra nettverk',
  },
  EditInfo: {
    en: 'Edit info',
    nb: 'Rediger info',
  },
  Edit: {
    en: 'Edit',
    nb: 'Rediger',
  },
  AboutMe: {
    en: 'About me',
    nb: 'Om meg',
  },
  BasicInfo: {
    en: 'Basic info',
    nb: 'Basisinformasjon',
  },
  YearFounded: {
    en: 'Year founded',
    nb: 'År stiftet',
  },
  Birthdate: {
    en: 'Birthdate',
    nb: 'Fødselsdato',
  },
  Age: {
    en: 'Age',
    nb: 'Alder',
  },
  Residence: {
    en: 'Residence',
    nb: 'Bosted',
  },
  Candidates: {
    en: 'Candidates',
    nb: 'Kandidater',
  },
  Nationality: {
    en: 'Nationality',
    nb: 'Nasjonalitet',
  },
  NewInquiry: {
    en: 'New Inquiry',
    nb: 'Ny henvendelse',
  },
  NoInquiries: {
    en: 'You have not yet recieved inquiries from any businesses.',
    nb: 'Du har ikke mottatt noen henvendelser fra virksomheter ennå.',
  },
  NoInquiriesOrg: {
    en: 'No candidates have sent you inquiries yet.',
    nb: 'Ingen kandidater har sendt dere noen henvendelser ennå.',
  },
  AS: {
    en: 'Joint-Stock Company',
    nb: 'Aksjeselskap',
  },
  ENK: {
    en: 'Sole Proprietorship',
    nb: 'Enkeltpersonforetak',
  },
  ES: {
    en: 'Condominium Assosiaction',
    nb: 'Eierseksjonssameie',
  },
  ANS: {
    en: 'General Partnership',
    nb: 'Ansvarlig selskap',
  },
  DA: {
    en: 'Partnership with Shared Liability',
    nb: 'Delt ansvar selskap',
  },
  NUF: {
    en: 'Norwegian Branch of Foreign Company',
    nb: 'Norsk avdeling av utenlandsk foretak',
  },
  SA: {
    en: 'Cooperative',
    nb: 'Samvirkeforetak',
  },
  IKS: {
    en: 'Intermunicipal Company',
    nb: 'Interkommunalt selskap',
  },
  KS: {
    en: 'Limited Partnership',
    nb: 'Kommandittselskap',
  },
  BA: {
    en: 'Housing Cooperative',
    nb: 'Borettslag',
  },
  SP: {
    en: 'Limited Liability Company',
    nb: 'Selskap med begrenset ansvar',
  },
  STI: {
    en: 'Foundation',
    nb: 'Stiftelse',
  },
  FLI: {
    en: 'Association/Organization',
    nb: 'Forening/lag/innretning',
  },
  VOF: {
    en: 'Charitable or Nonprofit Organization',
    nb: 'Veldedig eller allmennyttig foretak',
  },
  GFS: {
    en: 'Mutual Insurance Company',
    nb: 'Gjensidig forsikringsselskap',
  },
  HF: {
    en: 'Health Enterprise',
    nb: 'Helseforetak',
  },
  SF: {
    en: 'State Enterprise',
    nb: 'Statsforetak',
  },
  KF: {
    en: 'Municipal Enterprise',
    nb: 'Kommunalt foretak',
  },
  FKF: {
    en: 'County Municipal Enterprise',
    nb: 'Fylkeskommunalt foretak',
  },
  Message: {
    en: 'Message',
    nb: 'Melding',
  },
  WantedRole: {
    en: 'Wanted role',
    nb: 'Ønsket type verv',
  },
  WantedRoleQuestion: {
    en: 'What type of role do you wish to fill?',
    nb: 'Hvilken type rolle ønsker dere å fylle?',
  },
  WhichWantedRole: {
    en: 'What type of position do you want?',
    nb: 'Hvilken type verv ønsker du deg?',
  },
  NetworkWhichWantedRole: {
    en: 'What type of position would be relevant for the candidate?',
    nb: 'Hvilken type verv kan være relevant for kandidaten?',
  },
  WantedGender: {
    en: 'Wanted gender',
    nb: 'Krav til kjønn',
  },
  WantedAge: {
    en: 'Preferred age interval',
    nb: 'Ønsket aldersintervall',
  },
  WantedEducation: {
    en: 'Preferred education level/experience',
    nb: 'Preferert utdanningsnivå/erfaring',
  },
  PersonalProfile: {
    en: 'My page',
    nb: 'Min side',
  },
  Role: {
    en: 'Role',
    nb: 'Rolle',
  },
  'role.key': {
    en: 'Role Key',
    nb: 'Rolle Nøkkel',
  },
  Business: {
    en: 'Business',
    nb: 'Bransje',
  },
  WorkLanguage: {
    en: 'Work language',
    nb: 'Språk i stillingen',
  },
  Website: {
    en: 'Website',
    nb: 'Hjemmeside',
  },
  TitleOfAnounce: {
    en: 'Title of the advertisement',
    nb: 'Tittel på annonsen',
  },
  'profile.location?': {
    en: 'Profile Location?',
    nb: 'Profilsted?',
  },
  ShowInterest: {
    en: 'Show interest',
    nb: 'Meld interesse',
  },
  ShowInterestDescCandidate: {
    en: 'The candidate will be notified that you would like to make contact. You can write a message to the candidate in the field below.',
    nb: 'Kandidaten vil bli informert om at dere er interesserte i å komme i kontakt. Du kan skrive en melding til kandidaten i feltet under.',
  },
  ShowInterestDescOrg: {
    en: 'The organization will be notified that you are interested in this position. You can write a message to the organization in the field below.',
    nb: 'Selskapet vil bli informert om at du er interessert i stillingen. Du kan legge ved en melding til selskapet i feltet nedenfor.',
  },
  ShowProfile: {
    en: 'Show Profile',
    nb: 'Vis profil',
  },
  Inquirys: {
    en: 'Inquiries',
    nb: 'Henvendelser',
  },
  Network: {
    en: 'Network',
    nb: 'Nettverk',
  },
  AddNewCandidate: {
    en: 'Add Candidate',
    nb: 'Legg til kandidat',
  },
  AddNewCandidateManual: {
    en: 'Add manually',
    nb: 'Legg til manuelt',
  },
  NoCandidatesInNetwork: {
    en: 'No candidates have been added to your network',
    nb: 'Ingen kandidater er lagt til i ditt nettverk',
  },
  AddToCurrentProfile: {
    en: 'Add to current advertisement',
    nb: 'Legg til i valgt annonse',
  },
  FirstName: {
    en: 'First name',
    nb: 'Fornavn',
  },
  LastName: {
    en: 'Last name',
    nb: 'Etternavn',
  },
  ContactInfo: {
    en: 'Contact info',
    nb: 'Kontaktinfo',
  },
  Email: {
    en: 'E-mail',
    nb: 'E-post',
  },
  PhoneNumber: {
    en: 'Phone number',
    nb: 'Telefonnummer',
  },
  InquiryDate: {
    en: 'Inquiry Date',
    nb: 'Forespørselsdato',
  },
  Contacted: {
    en: 'Contacted',
    nb: 'Kontaktet',
  },
  LastChanged: {
    en: 'Last changed',
    nb: 'Sist endret',
  },
  CreateNewProfile: {
    en: 'Register profile',
    nb: 'Registrer profil',
  },
  NoProfile: {
    en: 'No profiles have been created.',
    nb: 'Dere har ikke opprettet en profil ennå.',
  },
  NoAdvert: {
    en: 'No advertisements have been created.',
    nb: 'Dere har ikke opprettet en annonse ennå.',
  },
  PublicProfile: {
    en: 'Publish profile',
    nb: 'Publiser profil',
  },
  PublicAdvert: {
    en: 'Publish advertisement',
    nb: 'Publiser annonse',
  },
  'IN DIALOG': {
    en: 'In dialog',
    nb: 'I dialog',
  },
  CONTACTED: {
    en: 'Contacted',
    nb: 'Kontaktet',
  },
  'NOT CONTACTED': {
    en: 'Not contacted',
    nb: 'Ikke kontaktet',
  },
  ERROR: {
    en: 'ERROR',
    nb: 'FEIL',
  },
  Messages: {
    en: 'Messages',
    nb: 'Meldinger',
  },
  AddNewMessage: {
    en: 'Write a message',
    nb: 'Skriv en melding',
  },
  NewMessage: {
    en: 'Write message...',
    nb: 'Skriv melding...',
  },
  EditMessage: {
    en: 'Edit message',
    nb: 'Rediger melding',
  },
  EditAd: {
    en: 'Edit advertisement',
    nb: 'Rediger annonse',
  },
  EditProfile: {
    en: 'Edit profile',
    nb: 'Rediger profil',
  },
  DeleteInquiry: {
    en: 'Delete inquiry',
    nb: 'Slett henvendelse',
  },
  DeleteMessage: {
    en: 'Delete message',
    nb: 'Slett melding',
  },
  EditedMessage: {
    en: '(edited)',
    nb: '(redigert)',
  },
  InquiryNotes: {
    en: 'Notes',
    nb: 'Notater',
  },
  DescLimit: {
    en: '(Max 750 characters)',
    nb: '(Maks 750 tegn)',
  },
  descPlaceholder: {
    en: 'Description...',
    nb: 'Beskrivelse...',
  },
  CandidateProfile: {
    en: 'Candidate Profile',
    nb: 'Kandidatprofil',
  },
  MyProfile: {
    en: 'My profile',
    nb: 'Min profil',
  },
  NetworkCandidateProfile: {
    en: 'Candidate Profile (Network)',
    nb: 'Kandidatprofil (Nettverk)',
  },
  CandidateDescription: {
    en: 'A short description of you',
    nb: 'En kort beskrivelse av deg',
  },
  CandidateDescriptionNetwork: {
    en: 'A short description of the candidate',
    nb: 'En kort beskrivelse av kandidaten',
  },
  Gender: {
    en: 'Gender',
    nb: 'Kjønn',
  },
  FEMALE: {
    en: 'Female',
    nb: 'Kvinne',
  },
  MALE: {
    en: 'Male',
    nb: 'Mann',
  },
  ANY: {
    en: 'Any gender',
    nb: 'Ikke spesifisert',
  },
  OTHERGENDER: {
    en: 'Other',
    nb: 'Annet',
  },
  CONVERSATIONAL: {
    en: 'Conversational',
    nb: 'Grunnleggende',
  },
  FLUENT: {
    en: 'Fluent',
    nb: 'Flytende',
  },
  LinkedIn: {
    en: 'LinkedIn',
    nb: 'LinkedIn',
  },
  Language: {
    en: 'Language',
    nb: 'Språk',
  },
  LanguageHeader: {
    en: 'Which languages do you speak?',
    nb: 'Hvilke språk behersker du?',
  },
  LanguageHeaderNetwork: {
    en: 'Which languages does the candidate speak?',
    nb: 'Hvilke språk behersker kandidaten?',
  },
  Proficiency: {
    en: 'Proficiency',
    nb: 'Ferdighetsnivå',
  },
  'Add Language': {
    en: 'Add Language',
    nb: 'Legg til språk',
  },
  Education: {
    en: 'Education',
    nb: 'Utdanning',
  },
  EducationHeader: {
    en: 'What educations do you have?',
    nb: 'Hvilke utdanninger har du?',
  },
  AddEducation: {
    en: 'Add education',
    nb: 'Legg til utdanning',
  },
  AddIndustryExperience: {
    en: 'Add industry experience',
    nb: 'Legg til bransjeerfaring',
  },
  'educ.key': {
    en: 'Education Key',
    nb: 'Utdanningsnøkkel',
  },
  AndOther: {
    en: 'and other(s)',
    nb: 'og andre',
  },
  OrgbrainBoardSchool: {
    en: 'Orgbrain/Board School',
    nb: 'Orgbrain/Styreskolen',
  },
  NetworkCompletedOrgbrainBoardSchool: {
    en: 'Is the candidate certified through Orgbrain/Board School?',
    nb: 'Er kandidaten sertifisert gjennom Orgbrain/Styreskolen?',
  },
  NetworkCompletedOtherBoardCourses: {
    en: 'Has the candidate completed other board courses?',
    nb: 'Har kandidaten fullført andre styrekurs?',
  },
  CompletedOrgbrainBoardSchool: {
    en: 'Are you certified through Orgbrain/Board School?',
    nb: 'Er du sertifisert gjennom Orgbrain/Styreskolen?',
  },
  CompletedBoardCourses: {
    en: 'Have you completed any board courses?',
    nb: 'Har du fullført styrekurs?',
  },
  CompletedOtherBoardCourses: {
    en: 'Have you completed other board courses?',
    nb: 'Har du fullført andre styrekurs?',
  },
  CourseType: {
    en: 'Course Type',
    nb: 'Kurstype',
  },
  AddCourse: {
    en: 'Add Course',
    nb: 'Legg til kurs',
  },
  NoCourses: {
    en: 'No Courses',
    nb: 'Ingen kurs',
  },
  AdTitleTooltip: {
    en: 'Give the advertisement a title, for example what you are looking for (for example «We seek a chairman to SaaS company in growth»)',
    nb: 'Her gir du annonsen en tittel, for eksempel hva dere søker (for eksempel «Vi søker styreleder til SaaS selskap i vekst»).',
  },
  AdAboutTooltip: {
    en: 'Here you can give a short description of your business and explain why this position is interesting to apply for.',
    nb: 'Her kan du gi en kort beskrivelse av virksomheten din og forklare hvorfor stillingen er interessant å søke på.',
  },
  Traits: {
    en: 'Personal traits',
    nb: 'Personlige egenskaper',
  },
  TraitsQuestion: {
    en: 'Which personal traits are most important to you?',
    nb: 'Hvilke personlige egenskaper er viktigst for dere?',
  },
  TraitsSubheader: {
    en: 'Pick those that are most applicable to you.',
    nb: 'Velg de du mener passer best for deg.',
  },
  NetworkTraitsSubheader: {
    en: 'Pick those that are most applicable to the candidate.',
    nb: 'Velg de du mener passer best for kandidaten.',
  },
  'trait.key': {
    en: 'Trait Key',
    nb: 'Egenskapsnøkkel',
  },
  Industry: {
    en: 'Industry',
    nb: 'Bransje',
  },
  BusinessType: {
    en: 'Business type',
    nb: 'Type virksomhet',
  },
  BusinessLocation: {
    en: 'Location',
    nb: 'Lokalisering',
  },
  IndustryExperience: {
    en: 'Industry experience',
    nb: 'Bransjeerfaring',
  },
  IndustryExperienceHeader: {
    en: 'Enter relevant industry experience',
    nb: 'Oppgi relevant bransjeerfaring',
  },
  'experience.key': {
    en: 'Experience Key',
    nb: 'Erfaringsnøkkel',
  },
  Competancy: {
    en: 'Areas of expertise',
    nb: 'Kompetanseområder',
  },
  CompetancyHeader: {
    en: 'Enter your competancy',
    nb: 'Oppgi dine kompetanseområder',
  },
  CompetancyQuestion: {
    en: 'What competency are you seeking?',
    nb: 'Hvilken kompetanse er dere ute etter?',
  },
  'skill.key': {
    en: 'Skill Key',
    nb: 'Ferdighetsnøkkel',
  },
  Experience: {
    en: 'Relevant experience',
    nb: 'Relevant erfaring',
  },
  RelevantExperienceHeader: {
    en: 'Enter relevant experience',
    nb: 'Oppgi relevante verv og roller',
  },
  OrgType: {
    en: 'Org. type',
    nb: 'Org.type',
  },
  FromDate: {
    en: 'From date',
    nb: 'Fra dato',
  },
  ToDate: {
    en: 'To date',
    nb: 'Til dato',
  },
  From: {
    en: 'from',
    nb: 'fra',
  },
  To: {
    en: 'to',
    nb: 'til',
  },
  FromYear: {
    en: 'From year',
    nb: 'Fra år',
  },
  ToYear: {
    en: 'To year',
    nb: 'Til år',
  },
  VOCATIONAL: {
    en: 'Vocational',
    nb: 'Yrkesfaglig',
  },
  BACHLOR: {
    en: 'Bachelor',
    nb: 'Bachelor',
  },
  MASTER: {
    en: 'Master',
    nb: 'Master',
  },
  EXPERIENCE: {
    en: 'Experience',
    nb: 'Bransjeerfaring',
  },
  ALL: {
    en: 'All',
    nb: 'Alle',
  },
  YOUNG: {
    en: 'Under 35 years',
    nb: 'Under 35 år',
  },
  MIDDEL: {
    en: '35 to 60 years',
    nb: '35 til 60 år',
  },
  OLD: {
    en: 'Over 60 years',
    nb: 'Over 60 år',
  },
  Country: {
    en: 'Country',
    nb: 'Land',
  },
  AddExperience: {
    en: 'Add Experience',
    nb: 'Legg til erfaring',
  },
  InternationalExperience: {
    en: 'International experience',
    nb: 'Internasjonal erfaring',
  },
  InternationalExperienceQ: {
    en: 'Required international experience',
    nb: 'krav om internasjonal erfaring',
  },
  InternationalExperienceImportance: {
    en: 'Is international experience important?',
    nb: 'Er det viktig med internasjonal erfaring?',
  },
  InternationalExperienceYears: {
    en: 'International experience years',
    nb: 'Internasjonale erfaringsår',
  },
  InternationalExperienceQuestion: {
    en: 'Do you have international experience?',
    nb: 'Har du internasjonal erfaring?',
  },
  WantedRemuneration: {
    en: 'Wanted remuneration',
    nb: 'Ønsket honorar',
  },
  BoardmatchHeadline: {
    en: 'New inquiry on Boardmatch',
    nb: 'Ny henvendelse på Styrematch',
  },

  BoardmatchHeaderToOrg: {
    en: 'Boardmatch - new candidate has shown interest',
    nb: 'Styrematch - ny kandidat har vist interesse',
  },
  BoardmatchHeaderToCandidate: {
    en: 'Boardmatch - someone has shown interest in your profile',
    nb: 'Styrematch - noen har vist interesse for din profil',
  },
  GoToAdvertisment: {
    en: 'Log in',
    nb: 'Logg inn',
  },
  BoardmatchEmailToOrg1: {
    en: 'A new candidate has shown interest in your advertisement on Boardmatch and wishes to start a dialogue.',
    nb: 'En ny kandidat har vist interesse for annonsen din på Styrematch og ønsker å starte en dialog.',
  },
  BoardmatchEmailToOrg2: {
    en: 'Log in to Orgbrain to see who it is.',
    nb: 'Logg inn på Orgbrain for å se hvem det gjelder.',
  },
  BoardmatchEmailToCandidate1: {
    en: 'A business has shown interest in your profile on Boardmatch and wishes to start a dialogue.',
    nb: 'En virksomhet har vist interesse for profilen din på Styrematch og ønsker å starte en dialog. ',
  },
  BoardmatchEmailToCandidate2: {
    en: 'Log in to Orgbrain to see who it is.',
    nb: 'Logg inn på Orgbrain for å se hvem det gjelder.',
  },
  NoneMunicipality: {
    en: 'Not given ',
    nb: 'Ikke angitt ',
  },
  AtSchool: {
    en: 'from ',
    nb: 'fra ',
  },
  In: {
    en: 'in ',
    nb: 'i ',
  },
  School: {
    en: 'School',
    nb: 'Skole',
  },
  MinusSharesFound: {
    en: 'Investor with negative shares found',
    nb: 'Investor med negative antall funnet',
  },
  MinusSharesInRegisteryDescription: {
    en: 'The number of shares in the registry is negative for one or more of the investors in the registry. This can cause problems if not solved before the import is completed.',
    nb: 'Antall aksjer i registeret er negative for en eller flere av investorene i registeret. Dette kan føre til problemer om ikke løst før importeringen gjennobmføres.',
  },
  ExpectedRemunerationQuestion: {
    en: 'Are you willing to work for free in certain cases?',
    nb: 'Er du villig til å jobbe frivillig i noen sammenhenger?',
  },
  ExpectedRemunerationAmount: {
    en: 'Expectations regarding remuneration',
    nb: 'Forventninger om honorar',
  },
  ExpectedRemuneration: {
    en: 'Expectation of remuneration',
    nb: 'Forventning til honorar',
  },
  NOREM: {
    en: 'No Remuneration',
    nb: 'Ingen honorar',
  },
  remuneration: {
    en: 'Remuneration',
    nb: 'Honorar',
  },
  RemunerationOrg: {
    en: 'Remuneration',
    nb: 'Honorering',
  },
  OrgProfile: {
    en: 'Organization Profile',
    nb: 'Organisasjonsprofil',
  },
  OurPage: {
    en: 'Our page',
    nb: 'Vår side',
  },
  CreateNewAd: {
    en: 'Create new advertisement',
    nb: 'Opprett stillingsannonse',
  },
  DeleteAd: {
    en: 'Delete advertisement',
    nb: 'Slett annonse',
  },
  AllAds: {
    en: 'All advertisements',
    nb: 'Alle annonser',
  },
  Ads: {
    en: 'Advertisements',
    nb: 'Annonser',
  },
  Advert: {
    en: 'Job advertisement',
    nb: 'Stillingsannonse',
  },
  Ad: {
    en: 'Advertisement',
    nb: 'Annonse',
  },
  FindCandidate: {
    en: 'Find candidates',
    nb: 'Finn kandidater',
  },
  FindBoard: {
    en: 'Find positions',
    nb: 'Finn verv',
  },
  Personal: {
    en: 'Personal',
    nb: 'Personlig',
  },
  OrgHomeInfoBox: {
    en: 'Here you can create advertisements to find candidates sith a profile in Orgbrain. When an advertisement is created, you get an overview of candidates that fit to your ad. You can also create and maintain an overview of candidates in your network.',
    nb: 'Her kan du lage annonser for å finne kandidater som har en profil i Orgbrain. Når en annonse er opprettet, får du en oversikt over kandidater som passer til annonsen din. Du kan også lage og holde ved like en oversikt over kandidater i ditt nettverk.',
  },
  OrganizationProfile: {
    en: 'Organization Profile',
    nb: 'Organisasjonsprofil',
  },
  DescHeader: {
    en: 'About the business',
    nb: 'Om virksomheten',
  },
  Turnover: {
    en: 'Turnover',
    nb: 'Omsetning',
  },
  NumEmployees: {
    en: 'Number of Employees',
    nb: 'Antall Ansatte',
  },
  OrgNationality: {
    en: 'Organization Nationality',
    nb: 'Organisasjonsnasjonalitet',
  },
  OrgNationalityQuestion: {
    en: 'Is the organization',
    nb: 'Er organisasjonen',
  },
  Norwegian: {
    en: 'Norwegian',
    nb: 'Norsk',
  },
  Nordic: {
    en: 'Nordic',
    nb: 'Nordisk',
  },
  International: {
    en: 'International',
    nb: 'Internasjonal',
  },
  LocationImportance: {
    en: 'Location Importance',
    nb: 'Stedsbetydning',
  },
  LocationImportanceQuestion: {
    en: 'Is it important that the candidate lives geographically nearby?',
    nb: 'Er det viktig at kandidaten bor geografisk nærme?',
  },
  WantedTitle: {
    en: 'Preferred education/work title',
    nb: 'Preferert utdanning/yrkestittel',
  },
  WorkTitle: {
    en: 'Work title',
    nb: 'Yrkestittel',
  },
  OrganizationType: {
    en: 'Organization Type',
    nb: 'Organisasjonstype',
  },
  BoardCourse: {
    en: 'Completed Board Course',
    nb: 'Fullført styrekurs',
  },
  WantedMeetings: {
    en: 'Expected amount of meetings in a year',
    nb: 'Forventet antall møter i året',
  },
  PhysicalMeets: {
    en: 'Physical meetings',
    nb: 'Fysiske møter',
  },
  DigitalMeets: {
    en: 'Digital meetings',
    nb: 'Digitale møter',
  },
  Remuneration: {
    en: 'Remuneration',
    nb: 'Honorar',
  },
  'rem.key': {
    en: 'Remuneration Key',
    nb: 'Lønnsnøkkel',
  },
  NoneValidCandidateProfile: {
    en: 'None Valid Candidate Profile',
    nb: 'Ingen gyldig kandidatprofil',
  },
  AddToContactList: {
    en: 'Add to network',
    nb: 'Legg til i nettverk',
  },
  AlreadySentInquiry: {
    en: 'Inquiry sent',
    nb: 'Henvendelse er sendt',
  },
  AlreadyAddedToNetwork: {
    en: 'In your network',
    nb: 'Ligger i ditt nettverk',
  },
  Duration: {
    en: 'Duration',
    nb: 'Varighet',
  },
  SendInquiry: {
    en: 'Send inquiry',
    nb: 'Send henvendelse',
  },
  ANALYTICAL: {
    en: 'Analytical',
    nb: 'Analytisk',
  },
  STRATEGIC: {
    en: 'Strategic',
    nb: 'Strategisk',
  },
  TEAM_PLAYER: {
    en: 'Team Player',
    nb: 'Lagspiller',
  },
  RELATIONSHIP_BUILDER: {
    en: 'Relationship Builder',
    nb: 'Relasjonsbygger',
  },
  SOLUTION_ORIENTED: {
    en: 'Solution Oriented',
    nb: 'Løsningsorientert',
  },
  CURIOUS: {
    en: 'Curious',
    nb: 'Nysgjerrig',
  },
  PROACTIVE: {
    en: 'Proactive',
    nb: 'Initiativrik',
  },
  INFLUENCER: {
    en: 'Influencer',
    nb: 'Påvirker',
  },
  RELIABLE: {
    en: 'Reliable',
    nb: 'Pålitelig',
  },
  EXECUTION_POWER: {
    en: 'Execution Power',
    nb: 'Gjennomføringskraft',
  },
  FOCUSED: {
    en: 'Focused',
    nb: 'Fokusert',
  },
  SALES: {
    en: 'Sales and Sales management',
    nb: 'Salg og salgsledelse',
  },
  MARKETING: {
    en: 'Marketing',
    nb: 'Markedsføring',
  },
  STRATEGY: {
    en: 'Strategy',
    nb: 'Strategi',
  },
  ECONOMICS: {
    en: 'Economics',
    nb: 'Økonomi',
  },
  LEADERSHIP: {
    en: 'Leadership',
    nb: 'Ledelse',
  },
  COMMUNICATION: {
    en: 'Communication',
    nb: 'Kommunikasjon',
  },
  LABOR_ORGANIZATIONS: {
    en: 'Labor and Employer Organizations',
    nb: 'Arbeidstaker- og arbeidsgiverorganisasjoner',
  },
  ARCHITECTURE_AND_INTERIOR: {
    en: 'Architecture, Area and Interior',
    nb: 'Arkitektur, areal og interiør',
  },
  ARCHIVE_AND_LIBRARY: {
    en: 'Archive and Library',
    nb: 'Arkiv og bibliotek',
  },
  BANKING_AND_INSURANCE: {
    en: 'Banking, Finance and Insurance',
    nb: 'Bank, finans og forsikring',
  },
  EDUCATION: {
    en: 'Children, School and Education',
    nb: 'Barn, skole og undervisning',
  },
  AUTOMOTIVE: {
    en: 'Automotive, Vehicles and Workshop',
    nb: 'Bil, kjøretøy og verksted',
  },
  AUTO_PRODUCTION: {
    en: 'Automobile and Component Production',
    nb: 'Bil- og bildelproduksjon',
  },
  RETAIL: {
    en: 'Retail and Commerce',
    nb: 'Butikk og varehandel',
  },
  CONSTRUCTION: {
    en: 'Construction and Building',
    nb: 'Bygg og anlegg',
  },
  MAINTENANCE_SERVICES: {
    en: 'Maintenance Services',
    nb: 'Drift og vedlikeholdstjenester',
  },
  ANIMAL_HEALTH: {
    en: 'Animals and Animal Health',
    nb: 'Dyr og dyrehelse',
  },
  REAL_ESTATE: {
    en: 'Real Estate',
    nb: 'Eiendom',
  },
  ELECTRONICS: {
    en: 'Electronics',
    nb: 'Elektronikk',
  },
  EVENTS_AND_ARRANGEMENTS: {
    en: 'Events and Arrangements',
    nb: 'Event og arrangementer',
  },
  PHARMACY_AND_MEDICINE: {
    en: 'Pharmacy and Medicine',
    nb: 'Farmasi og legemiddel',
  },
  FISHERIES_AND_AQUACULTURE: {
    en: 'Fisheries and Aquaculture',
    nb: 'Fiskeri og oppdrett',
  },
  PUBLISHING_AND_PRINTING: {
    en: 'Publishing and Printing',
    nb: 'Forlag og trykkeri',
  },
  RESEARCH_AND_SCIENCE: {
    en: 'Research and Science',
    nb: 'Forskning, utdanning og vitenskap',
  },
  DEFENSE_AND_MILITARY: {
    en: 'Defense and Military',
    nb: 'Forsvar og militær',
  },
  WHOLESALE_TRADE: {
    en: 'Wholesale Trade',
    nb: 'Grossisthandel import/eksport',
  },
  HR_AND_RECRUITMENT: {
    en: 'HR and Recruitment',
    nb: 'HR, organisasjonsutvikling og rekruttering',
  },
  HEALTHCARE: {
    en: 'Healthcare',
    nb: 'Helse og omsorg',
  },
  HOTEL_AND_ACCOMMODATION: {
    en: 'Hotel and Accommodation',
    nb: 'Hotell og overnatting',
  },
  CRAFT_SERVICES: {
    en: 'Craft Services',
    nb: 'Håndverkstjenester',
  },
  IT: {
    en: 'IT',
    nb: 'IT',
  },
  IT_HARDWARE: {
    en: 'IT Hardware',
    nb: 'IT-maskinvare',
  },
  IT_SOFTWARE: {
    en: 'IT Software',
    nb: 'IT-programvare',
  },
  NON_PROFIT_ORGANIZATIONS: {
    en: 'Non-Profit Organizations',
    nb: 'Ideelle organisasjoner',
  },
  SPORTS_AND_FITNESS: {
    en: 'Sports and Fitness',
    nb: 'Idrett og trening',
  },
  INDUSTRIAL_PRODUCTION: {
    en: 'Industrial Production',
    nb: 'Industri og produksjon',
  },
  INTERNET_SERVICES: {
    en: 'Internet Services',
    nb: 'Internettbaserte tjenester',
  },
  AGRICULTURE_AND_FORESTRY: {
    en: 'Agriculture and Forestry',
    nb: 'Jordbruk og skogbruk',
  },
  LEGAL_SERVICES: {
    en: 'Legal Services',
    nb: 'Juridiske tjenester',
  },
  CHURCH_AND_MINISTRY: {
    en: 'Church and Ministry',
    nb: 'Kirke og menighet',
  },
  CHEMICAL_INDUSTRY: {
    en: 'Chemical Industry',
    nb: 'Kjemisk industri',
  },
  CONSULTING: {
    en: 'Consulting',
    nb: 'Konsulent og rådgivning',
  },
  POWER_AND_ENERGY: {
    en: 'Power and Energy',
    nb: 'Kraft og energi',
  },
  ARTS_AND_CULTURE: {
    en: 'Arts and Culture',
    nb: 'Kunst og kultur',
  },
  AVIATION: {
    en: 'Aviation',
    nb: 'Luftfart',
  },
  MARITIME_AND_OFFSHORE: {
    en: 'Maritime and Offshore',
    nb: 'Maritim og offshore',
  },
  MARKET_RESEARCH: {
    en: 'Market Research',
    nb: 'Markedsanalyse',
  },
  MARKETING_AND_ADVERTISING: {
    en: 'Marketing and Advertising',
    nb: 'Markedsføring og annonsering',
  },
  FOOD_PRODUCTION: {
    en: 'Food Production',
    nb: 'Matvareproduksjon og -industri',
  },
  MEDIA_AND_CONTENT_PRODUCTION: {
    en: 'Media and Content Production',
    nb: 'Medie- og innholdsproduksjon',
  },
  MEDICAL_EQUIPMENT: {
    en: 'Medical Equipment',
    nb: 'Medisinsk utstyr og rekvisita',
  },
  METALS_AND_MINERALS: {
    en: 'Metals and Minerals',
    nb: 'Metaller og mineraler',
  },
  ENVIRONMENTAL_SERVICES: {
    en: 'Environmental Services',
    nb: 'Miljøtjenester',
  },
  MUSEUMS_AND_CULTURAL_HERITAGE: {
    en: 'Museums and Cultural Heritage',
    nb: 'Museer, gallerier og kulturminne',
  },
  PUBLIC_ADMINISTRATION: {
    en: 'Public Administration',
    nb: 'Offentlig administrasjon',
  },
  OIL_AND_GAS: {
    en: 'Oil and Gas',
    nb: 'Olje og gass',
  },
  ORGANIZATIONS: {
    en: 'Organizations',
    nb: 'Organisasjoner',
  },
  PR_AND_COMMUNICATION: {
    en: 'PR and Communication',
    nb: 'PR, informasjon og kommunikasjon',
  },
  POLICE_AND_SECURITY: {
    en: 'Police and Security',
    nb: 'Politi og sikkerhet',
  },
  TRAVEL_AND_TOURISM: {
    en: 'Travel and Tourism',
    nb: 'Reise og turisme',
  },
  WASTE_MANAGEMENT: {
    en: 'Waste Management',
    nb: 'Renovasjon og renhold',
  },
  FOOD_AND_BEVERAGE: {
    en: 'Food and Beverage',
    nb: 'Restaurant, mat og uteliv',
  },
  SHIPPING: {
    en: 'Shipping',
    nb: 'Shipping',
  },
  CATERING: {
    en: 'Catering',
    nb: 'Storhusholdning og catering',
  },
  TEXTILE: {
    en: 'Textile',
    nb: 'Tekstil',
  },
  TELECOMMUNICATIONS: {
    en: 'Telecommunications',
    nb: 'Telekommunikasjon',
  },
  TRANSPORT_AND_LOGISTICS: {
    en: 'Transport and Logistics',
    nb: 'Transport og logistikk',
  },
  WOOD_INDUSTRY: {
    en: 'Wood Industry',
    nb: 'Trevareindustri',
  },
  ENTERTAINMENT: {
    en: 'Entertainment',
    nb: 'Underholdning',
  },
  HVAC: {
    en: 'HVAC',
    nb: 'VVS',
  },
  WELLNESS: {
    en: 'Wellness',
    nb: 'Velvære',
  },
  HEAVY_INDUSTRY: {
    en: 'Heavy Industry',
    nb: 'Verksindustri',
  },
  FINANCE_AND_ACCOUNTING: {
    en: 'Finance and Accounting',
    nb: 'Økonomi og regnskap',
  },
  STRATEGIC_PARTNER_SALES: {
    en: 'Strategic Partner Sales',
    nb: 'Strategisk partnersalg',
  },
  BRANDING: {
    en: 'Branding',
    nb: 'Merkevarebygging',
  },
  CAPITAL_RAISING: {
    en: 'Capital Raising',
    nb: 'Kapitalinnhenting',
  },
  INITIAL_PUBLIC_OFFERING: {
    en: 'Initial Public Offering',
    nb: 'Børsnotering',
  },
  OTHER_INDUSTRY: {
    en: 'Other',
    nb: 'Annet',
  },
  OTHER: {
    en: 'Other',
    nb: 'Annet',
  },
  POLITICS: {
    en: 'Politics',
    nb: 'Politikk',
  },
  SALG: {
    en: 'Sales and Sales management',
    nb: 'Salg og salgsledelse',
  },
  ADMINISTRATION: {
    en: 'Administration',
    nb: 'Administrasjon',
  },
  HR: {
    en: 'Human Resources',
    nb: 'HR',
  },
  FOU: {
    en: 'Research and Development',
    nb: 'FOU',
  },
  SUSTAINABILITY_ESG: {
    en: 'Sustainability/ESG',
    nb: 'Bærekraft/ESG',
  },
  COMPETENCY_SECURITY_FIRM: {
    en: 'Securities firm',
    nb: 'Verdipapirforetak',
  },
  COMPETENCY_ASSET_MNGMNT: {
    en: 'Asset management',
    nb: 'Fondsforvaltning',
  },
  COMPETENCY_POLITICS: {
    en: 'Politics',
    nb: 'Politikk',
  },
  COMPETENCY_JUSS: {
    en: 'Law',
    nb: 'Juss',
  },
  INTERNATIONALIZATION: {
    en: 'Internationalization',
    nb: 'Internasjonalisering',
  },
  PROGRAMMING: {
    en: 'Programming',
    nb: 'Programmering',
  },
  AI: {
    en: 'Artificial Intelligence',
    nb: 'Kunstig intelligens',
  },
  BLOCKCHAIN: {
    en: 'Blockchain',
    nb: 'Blockchain',
  },
  MERGERSANDACQUISITIONS: {
    en: 'Mergers and Acquisitions',
    nb: 'Fusjon og oppkjøp',
  },
  TECHNOLOGY: {
    en: 'Technology',
    nb: 'Teknologi',
  },
  CULTUREANDENTERTAINMENT: {
    en: 'Culture and Entertainment',
    nb: 'Kultur og underholdning',
  },
  EDUCATION_TRAINING: {
    en: 'Education/Training',
    nb: 'Utdanning/opplæring',
  },
  SERVICE_CUSTOMERSUPPORT: {
    en: 'Service/Customer Support',
    nb: 'Service/kundestøtte',
  },
  CHAIRMANOFTHEBOARD: {
    en: 'Chairman of the Board',
    nb: 'Styreleder',
  },
  BOARDMEMBER: {
    en: 'Board Member',
    nb: 'Styremedlem',
  },
  ALTERNATEMEMBER: {
    en: 'Deputy Member',
    nb: 'Varamedlem',
  },
  NOMINATIONCOMMITTEE: {
    en: 'Nomination Committee',
    nb: 'Valgkomité',
  },
  REMUNERATIONCOMMITTEE: {
    en: 'Remuneration Committee',
    nb: 'Godgjørelsesutvalg',
  },
  CONTROLCOMMITTEE: {
    en: 'Control Committee',
    nb: 'Kontrollutvalg',
  },
  AUDITCOMMITTEE: {
    en: 'Audit Committee',
    nb: 'Revisjonsutvalg',
  },
  ADVISORYBOARD: {
    en: 'Advisory Board',
    nb: 'Advisory board',
  },
  MANAGINGDIRECTOR: {
    en: 'Managing Director',
    nb: 'Daglig leder',
  },
  ADVISOR: {
    en: 'Advisor',
    nb: 'Rådgiver',
  },
  MENTOR: {
    en: 'Mentor',
    nb: 'Mentor',
  },
  DEPUTYCHAIR: {
    en: 'Deputy Chair',
    nb: 'Styrets nestleder',
  },
  OBSERVER: {
    en: 'Observer',
    nb: 'Observatør',
  },
  BOARDSECRETARY: {
    en: 'Board Secretary',
    nb: 'Styresekretær',
  },
  INVESTOR: {
    en: 'Investor',
    nb: 'Investor',
  },
  ENGLISH: {
    en: 'English',
    nb: 'Engelsk',
  },
  SPANISH: {
    en: 'Spanish',
    nb: 'Spansk',
  },
  GERMAN: {
    en: 'German',
    nb: 'Tysk',
  },
  FRENCH: {
    en: 'French',
    nb: 'Fransk',
  },
  SWEDISH: {
    en: 'Swedish',
    nb: 'Svensk',
  },
  CHINESE: {
    en: 'Chinese',
    nb: 'Kinesisk',
  },
  RUSSIAN: {
    en: 'Russian',
    nb: 'Russisk',
  },
  ITALIAN: {
    en: 'Italian',
    nb: 'Italiensk',
  },
  JAPANESE: {
    en: 'Japanese',
    nb: 'Japansk',
  },
  ARABIC: {
    en: 'Arabic',
    nb: 'Arabisk',
  },
  NORTHERNSAMI: {
    en: 'Northern Sami',
    nb: 'Nordsamisk',
  },
  PORTUGUESE: {
    en: 'Portuguese',
    nb: 'Portugisisk',
  },
  DUTCH: {
    en: 'Dutch',
    nb: 'Nederlandsk',
  },
  LATIN: {
    en: 'Latin',
    nb: 'Latin',
  },
  GREEK: {
    en: 'Greek',
    nb: 'Gresk',
  },
  DANISH: {
    en: 'Danish',
    nb: 'Dansk',
  },
  FINNISH: {
    en: 'Finnish',
    nb: 'Finsk',
  },
  POLISH: {
    en: 'Polish',
    nb: 'Polsk',
  },
  TURKISH: {
    en: 'Turkish',
    nb: 'Tyrkisk',
  },
  KOREAN: {
    en: 'Korean',
    nb: 'Koreansk',
  },
  HEBREW: {
    en: 'Hebrew',
    nb: 'Hebraisk',
  },
  PERSIAN: {
    en: 'Persian',
    nb: 'Persisk',
  },
  URDU: {
    en: 'Urdu',
    nb: 'Urdu',
  },
  HINDI: {
    en: 'Hindi',
    nb: 'Hindi',
  },
  THAI: {
    en: 'Thai',
    nb: 'Thai',
  },
  VIETNAMESE: {
    en: 'Vietnamese',
    nb: 'Vietnamesisk',
  },
  INDONESIAN: {
    en: 'Indonesian',
    nb: 'Indonesisk',
  },
  HUNGARIAN: {
    en: 'Hungarian',
    nb: 'Ungarsk',
  },
  CZECH: {
    en: 'Czech',
    nb: 'Tsjekkisk',
  },
  ROMANIAN: {
    en: 'Romanian',
    nb: 'Rumensk',
  },
  NORWEGIAN: {
    en: 'Norwegian',
    nb: 'Norsk',
  },
  LinkedInTooltip: {
    en: 'Add a link to your linkedIn in this format: https://www.linkedin.com/in/YOUR USER/',
    nb: 'Legg til en link til din LinkedIn profil i dette formatet: https://www.linkedin.com/in/YOUR USER/',
  },
  TitleToolTip: {
    en: 'Enter a title for your ad, for example what you are looking for',
    nb: 'Legg inn en tittel på din annonse, for eksempel hva du søker etter',
  },
  AllProfiles: {
    en: 'All advertisements',
    nb: 'Alle annonser',
  },
  NoSelectedProfile: {
    en: 'No selected advertisement',
    nb: 'Ingen annonse valgt',
  },
  WantToDelete: {
    en: 'Are you sure you want to delete? This cannot be undone.',
    nb: 'Er du sikker på at du vil slette? Dette kan ikke angres.',
  },
  WantToDeleteHeader: {
    en: 'Warning',
    nb: 'Advarsel',
  },
  WantToDeleteAd: {
    en: 'Are you sure you want to delete the advertisement? This cannot be undone.',
    nb: 'Er du sikker på at du vil slette annonsen? Dette kan ikke angres.',
  },
  WantToDeleteProfile: {
    en: 'Are you sure you want to delete your profile? This cannot be undone.',
    nb: 'Er du sikker på at du vil slette din profil? Dette kan ikke angres.',
  },
  WantToDeleteMsg: {
    en: 'Are you sure you want to delete this message? This cannot be undone.',
    nb: 'Er du sikker på at du vil slette meldingen? Dette kan ikke angres.',
  },
  WantToDeleteInquiry: {
    en: 'Are you sure you want to delete this inquiry? This cannot be undone.',
    nb: 'Er du sikker på at du vil slette denne henvendelsen? Dette kan ikke angres.',
  },
  AnErrorHaveOcurred: {
    en: 'An error have occured',
    nb: 'Det har oppstått en feil',
  },
  CheckIfYourEventsAreConsistentIfThisErrorPersistsContactSupport: {
    en: 'Check if your events are consistent. If this error persists, contact support.',
    nb: 'Sjekk om hendelser er konsistente. Hvis denne feilen vedvarer, kontakt support.',
  },
  NoSubscriptionsEmission: {
    en: 'You currently have no subscriptions, historic or ongoing...',
    nb: 'Du har for øyeblikket ingen bestillingsblanketter, verken historiske eller pågående...',
  },
  ShareClassOnly: {
    en: 'Share class',
    nb: 'Aksjeklasse',
  },
  CompanyOnly: {
    en: 'Company',
    nb: 'Selskap',
  },
  Ownershare: {
    en: 'Share of ownership',
    nb: 'Eierandel',
  },
  EstimatedValue: {
    en: 'Estimated value',
    nb: 'Estimert verdi',
  },
  Portfolio: {
    en: 'Portfolio',
    nb: 'Portefølje',
  },
  FromNetwork: {
    en: 'From network',
    nb: 'Fra nettverk',
  },
  STARTUP: {
    en: 'Start-up business',
    nb: 'Oppstartselskap',
  },
  SMB: {
    en: 'SMB',
    nb: 'SMB',
  },
  BIGBUSINESS: {
    en: 'Big business',
    nb: 'Stor virksomhet',
  },
  LISTED: {
    en: 'Listed',
    nb: 'Børsnotert',
  },
  ASSOCIATION: {
    en: 'Association',
    nb: 'Forening',
  },
  FOUNDATION: {
    en: 'Foundation',
    nb: 'Stiftelse',
  },
  SPORTS: {
    en: 'Sports',
    nb: 'Idrett',
  },
  PAYED: {
    en: 'Paid for in money',
    nb: 'Betalt i penger',
  },
  NOTPAYED: {
    en: 'Unpaid position',
    nb: 'Ikke betalt verv',
  },
  PAYEDSTOCKS: {
    en: 'Options, stocks, other',
    nb: 'Opsjoner, aksjer, annet',
  },
  NEGOTIATION: {
    en: 'After further agreement',
    nb: 'Etter nærmere avtale',
  },
  Latest: {
    en: 'Latest',
    nb: 'Nyeste',
  },
  BestMatch: {
    en: 'Best match',
    nb: 'Beste match',
  },
  HideContactedOrgs: {
    en: 'Hide contacted organizations',
    nb: 'Skjul kontaktede organisasjoner',
  },
  ProfileDeleted: {
    en: 'Profile deleted',
    nb: 'Profil slettet',
  },
  ErrorOnDelete: {
    en: 'Error on deleting',
    nb: 'Feil ved sletting',
  },
  InquiryDeleted: {
    en: 'Inquiry deleted',
    nb: 'Henvendelse slettet',
  },
  ErrorOnDeleteInquiry: {
    en: 'Error on deleting inquiry',
    nb: 'Feil ved sletting av henvendelse',
  },
  PostedInquiry: {
    en: 'Posted inquiry',
    nb: 'Henvendelse sendt',
  },
  ErrorFetchInquiry: {
    en: 'Error fetching inquiry',
    nb: 'Feil ved henting av henvendelse',
  },
  DeletedMessage: {
    en: 'Message deleted',
    nb: 'Slettet melding',
  },
  ErrorDeletingMessage: {
    en: 'Error on deleting message',
    nb: 'Feil ved sletting av melding',
  },
  PostedMessage: {
    en: 'Message sent',
    nb: 'Melding sendt',
  },
  ErrorPostingMessage: {
    en: 'Error on sending message',
    nb: 'Feil ved sending av melding',
  },
  SavedNotes: {
    en: 'Saved notes',
    nb: 'Internt notat lagret',
  },

  ErrorSavingNotes: {
    en: 'Error on saving notes',
    nb: 'Feil ved lagring av notat',
  },
  CandidateEmail: {
    en: 'Email: ',
    nb: 'Email: ',
  },
  CandidatePhone: {
    en: 'Phone number: ',
    nb: 'Telefon nr: ',
  },
  ProfileSaved: {
    en: 'Profile saved',
    nb: 'Lagret profil',
  },
  ErrorProfileSaved: {
    en: 'Error saving profile',
    nb: 'Feil ved lagring av profil',
  },
  ProfileNotValid: {
    en: 'Profile not valid',
    nb: 'Profilen er ugyldig',
  },
  ErrorFetchingProfile: {
    en: 'Error fetching profile',
    nb: 'Feil ved henting av profil',
  },
  NetworkCandidateProfileDeleted: {
    en: 'Network candidate deleted',
    nb: 'Nettverkskandidat slettet',
  },
  NoneValidProfileId: {
    en: 'Ivalid profile ID',
    nb: 'Ugyldig profil-ID',
  },
  SavedAd: {
    en: 'Saved advertisement',
    nb: 'Lagret annonse',
  },
  ErrorSavingAd: {
    en: 'Error on saving advertisement',
    nb: 'Feil ved lagring av annonse',
  },
  DuplicateToNetwork: {
    en: 'Can not add duplicates to network',
    nb: 'Kan ikke lagre duplikater til nettverk',
  },
  SavedToNetwork: {
    en: 'Saved to network',
    nb: 'Lagret i nettverk',
  },
  ErrorSavingToNetwork: {
    en: 'Error on saving to network',
    nb: 'Feil ved lagring i nettverk',
  },
  PublishProfile: {
    en: 'Publish profile',
    nb: 'Publiser profil',
  },
  PublishProfileDesc: {
    en: 'You can publish your profile for businesses, or save it as a draft only visible to yourself.',
    nb: 'Du kan publisere profilen din for virksomheter, eller lagre den som utkast kun synlig for deg selv.',
  },
  PublishAd: {
    en: 'Publish advertisement',
    nb: 'Publiser annonse',
  },
  PublishAdDesc: {
    en: 'You can publish the advertisement for candidates, or save it as a draft only visible to your organisation.',
    nb: 'Du kan publisere annonsen for kandidater, eller lagre den som utkast kun synlig for din organisasjon.',
  },
  SaveAsDraft: {
    en: 'Save as draft',
    nb: 'Lagre som utkast',
  },
  SaveAndPublish: {
    en: 'Save and publish',
    nb: 'Lagre og publiser',
  },
  MoreInfo: {
    en: 'More information',
    nb: 'Mer informasjon',
  },
  GetStarted: {
    en: 'Get started',
    nb: 'Kom i gang',
  },
  NoMatchesOrg: {
    en: "It looks like there aren't any matches right now, but new candidates get added frequently. Keep paying attention!",
    nb: 'Det ser ut som det ikke er noen match akkurat nå, men nye kandidater legges stadig til. Følg med!',
  },
  NoMatchesCandidate: {
    en: "It looks like there aren't any matches right now, but new opportunities appear frequently. Keep paying attention!",
    nb: 'Det ser ut som det ikke er noen match akkurat nå, men nye muligheter dukker stadig opp. Følg med!',
  },
  InterestingCandidate: {
    en: 'Is the candidate interesting?',
    nb: 'Er kandidaten interessant?',
  },
  InterestingCandidateInfoBox: {
    en: 'Here you have the option to mark a candidate as interesting, not interesting, or maybe. This will not be visible to the candidate. Please note that if you mark a candidate as "No", the candidate will disappear from your match.',
    nb: 'Her har du muligheten til å merke en kandidat som interessant, ikke interessant, eller kanskje. Dette vil ikke være synlig for kandidaten. Vær oppmerksom på at hvis du markerer en kandidat som "Nei", vil kandidaten forsvinne fra din match.',
  },
  InterestingAd: {
    en: 'Is the ad interesting?',
    nb: 'Er annonsen interessant?',
  },
  InterestingAdInfoBox: {
    en: 'Here you have the option of marking an ad as interesting, not interesting, or maybe. This will not be visible to the advertiser. Please note that if you mark an ad as "No", the ad will disappear from your match.',
    nb: 'Her har du muligheten til å merke en annonse som interessant, ikke interessant, eller kanskje. Dette vil ikke være synlig for annonsøren. Vær oppmerksom på at hvis du markerer en annonse som "Nei", vil annonsen forsvinne fra din match.',
  },
  MaybeInteresting: {
    en: 'Maybe',
    nb: 'Kanskje',
  },
  ShowOnlyRatedProfiles: {
    en: 'Only show rated profiles',
    nb: 'Vis bare vurderte profiler',
  },
  ShowOnlyRatedAds: {
    en: 'Only show rated advertisements',
    nb: 'Vis bare vurderte annonser',
  },
  Years: {
    en: ' years',
    nb: ' år',
  },
  MUSIC: {
    en: 'Music',
    nb: 'Musikk',
  },
  AddToNetworkTooltip: {
    en: 'Here you can add the candidate to your network in Boardmatch. This will not be visible to the candidate.',
    nb: 'Her kan du legge kandidaten til ditt nettverk i Styrematch. Dette vil ikke være synlig for kandidaten.',
  },
  ShowInterestCandidateTooltip: {
    en: 'Here you can send a message to the candidate.',
    nb: 'Du kan her sende en melding til kandidaten.',
  },
  ShowInterestOrgTooltip: {
    en: 'Here you can send a message to the business.',
    nb: 'Du kan her sende en melding til virksomheten.',
  },
  WantToDeleteFromNetwork: {
    en: 'Are you sure you want to delete this candidate from your network? This cannot be undone.',
    nb: 'Er du sikker på at du vil slette denne kandidaten fra nettverket ditt? Dette kan ikke angres.',
  },
  NetworkConnectedToOrgbrain: {
    en: 'This candidate is connected to an Orgbrain account.',
    nb: 'Denne kandidaten er koblet til en Orgbrain-bruker.',
  },
  AllOwners: {
    en: 'All owners',
    nb: 'Alle eiere',
  },
  OpenOrderForms: {
    en: 'Open order forms',
    nb: 'Åpne bestillingsblanketter',
  },
  HistoricalOrderForms: {
    en: 'Historical order forms',
    nb: 'Historiske bestillingsblanketter',
  },
  OpenOrderFormsInfo: {
    en: 'See all open order forms you have received for capital increases that have not yet been completed.',
    nb: 'Her kan du se alle åpne bestillingsblanketter du har mottatt for kapitalforhøyelser som enda ikke er fullført.',
  },
  HistoricalOrderFormsInfo: {
    en: 'Here you can see all historical order forms, both completed and canceled.',
    nb: 'Her kan du se alle historiske bestillingsblanketter, både fullførte og avbrutte.',
  },
  ShareClassSaved: {
    en: 'Share class saved',
    nb: 'Aksjeklasse lagret',
  },
  ErrorSavingShareClass: {
    en: 'Error on saving share class',
    nb: 'Feil ved lagring av aksjeklasse',
  },
  OK: {
    en: 'OK',
    nb: 'OK',
  },
  ParValue: {
    en: 'Par value',
    nb: 'Pålydende',
  },
  VoteWeight: {
    en: 'Vote weight',
    nb: 'Stemmevekt',
  },
  ErrorCreatingParValueEvent: {
    en: 'Error creating par value event',
    nb: 'Feil ved opprettelse av endring av pålydende hendelse',
  },
  Seller: {
    en: 'Seller',
    nb: 'Avhender',
  },
  InfoAboutSeller: {
    en: 'Information about seller',
    nb: 'Informasjon om avhender',
  },
  InfoAboutShares: {
    en: 'Information about shares',
    nb: 'Info om aksjer',
  },
  Individual: {
    en: 'Individual',
    nb: 'Individ',
  },
  BoardMembers: {
    en: 'Board Members',
    nb: 'Styremedlemmer',
  },
  SpreadsheetMustShowHistoryOfTransaction: {
    en: 'The spreadsheet must show the history of stock transactions, capital increases, etc.',
    nb: 'Regnearket må vise historikken over hendelser i selskapet.',
  },
  ChooseAMethodToImportThe: {
    en: 'Choose a method to import the ',
    nb: 'Velg en metode for å legge inn ',
  },
  AtACertainTimeUsuallyNowWithNoHistory: {
    en: 'at a certain point in time.',
    nb: 'på et bestemt tidspunkt.',
  },
  PaidAmount: {
    en: 'Amount paid',
    nb: 'Betalt',
  },
  Settings: {
    en: 'Settings',
    nb: 'Innstillinger',
  },
  TermsOfUse: {
    en: 'Terms of use',
    nb: 'Brukervilkår',
  },
  Client: {
    en: 'Client',
    nb: 'Klient',
  },
  Roles: {
    en: 'Roles',
    nb: 'Roller',
  },
  aiTitle: { en: 'Artificial intelligence (AI)  ', nb: 'Kunstig intelligens (AI) ' },
  aiTooltip: {
    en: 'Enable AI to write better texts',
    nb: 'Aktiver AI for å skrive bedre tekster',
  },
  aiBodyText: {
    en: 'Get help from artificial intelligence to write better texts and perform other tasks. You can find more information about the use of AI in our ',
    nb: 'Få hjelp fra kunstig intelligens til å skrive bedre tekster og til å utføre øvrige oppgaver. Du kan finne mer informasjon om bruken av AI i våre ',
  },
  aiTermsLink: { en: 'terms of use', nb: 'brukervilkår' },
  emailLabel: { en: 'Email', nb: 'E-postadresse' },
  totpLabel: { en: 'Authenticator App', nb: 'Authenticator app' },
  addTotpButton: { en: 'Add', nb: 'Legg til' },
  changeTotpButton: { en: 'Change', nb: 'Endre' },
  contactTitle: { en: 'Account', nb: 'Konto' },
  other: { en: 'Other', nb: 'Annet' },
  calendarAPI: { en: 'Calendar API', nb: 'Kalender API' },
  phoneLabel: { en: 'Mobile (SMS 2FA)', nb: '2FA ved bruk av SMS' },
  name: { en: 'Name', nb: 'Navn' },
  nameInfo: {
    nb: 'Her kan du endre navnet på din bruker. For eksempel dersom du har endret ditt etternavn. ',
    en: 'Here you can change the display name of your user. For example, if you have changed your surname.',
  },
  emailInfo: {
    nb: `Her har du mulighet til å endre den e-postadresse som din brukerkonto er koblet til.
      Du kan bare endre til en e-postadresse som ikke allerede er i bruk hos Orgbrain. Dersom det er nødvendig å slå sammen to aktive kontoer, vennligst ta kontakt med Orgbrains kundestøtte.
      `,
    en: `Here you have the option to change the e-mail address to which your user account is linked.
      You can only change to an email address that is not already in use at Orgbrain. If it is necessary to merge two active accounts, please contact Orgbrain's customer support.
      `,
  },
  passwordInfoBox: {
    nb: `Her har du mulighet til å endre passord som din brukerkonto er koblet til.`,
    en: `Here you have the option to change the password to which your user account is linked.`,
  },
  sportsidInfo: {
    en: `Here you can register authentication  using SportsID`,
    nb: `Her kan du registrere autentisering ved bruk av SportsID`,
  },
  bankidInfo: {
    en: `Here you can register the two-factor authentication (2FA) using BankID`,
    nb: `Her kan du registrere tofaktorautentiseringen (2FA) ved bruk av BankID`,
  },
  phoneInfo: {
    en: `Here you can enter and change your mobile number. This can be used, among other things, for two-factor authentication (2FA)`,
    nb: `Her kan du legge inn og endre mobilnummer. Dette kan blant annet benyttes ved tofaktorautentisering (2FA)`,
  },
  toptInfo: {
    en: `Here you can register the two-factor authentication (2FA) using an app that supports Google Authenticator and other apps that support TOTP (Time-based One-time Password).`,
    nb: `Her kan du registrere tofaktorautentiseringen (2FA) ved bruk av app gir støtte for Google Authenticator og andre apper som støtter TOTP (Time-based One-time Password).
      `,
  },
  editingLocked: {
    nb: 'Lås opp for å endre',
    en: 'Unlock to make changes',
  },
  editingEnabled: {
    en: 'Editing enabled',
    nb: 'Redigering aktivert',
  },
  passwordLable: {
    en: 'Password',
    nb: 'Passord',
  },
  passwordInfo: {
    nb: 'Som en sikkerhetsmekanisme har det blitt sent en e-post til deg med en lenke for å endre passord.',
    en: 'As a security measure, an email has been sent to you with a link to change your password.',
  },
  passwordMailSent: {
    en: 'Mail sent',
    nb: 'E-post sendt',
  },
  passwordSubject: {
    nb: 'Endre passord',
    en: 'Change password',
  },
  orgbrainPasswordTitle: { en: 'Orgbrain password', nb: 'Orgbrain passord' },
  ResetPassword: {
    en: 'Reset',
    nb: 'Tilbakestill',
  },
  CopyLink: {
    en: 'Copy link',
    nb: 'Kopier lenke',
  },
};
