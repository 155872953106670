<div class="d-flex mt-20 layout orgbrain-style">
  <div class="sub-heading label">
    <div style="position: relative">
      <div style="position: relative; display: flex">
        <div>{{ label }}</div>
        <orgbrain-info-box-open-button *ngIf="moduleId" [moduleId]="moduleId" class="infobox">
        </orgbrain-info-box-open-button>
      </div>
      <div style="position: absolute; right: -24px; top: -8px"></div>
    </div>
  </div>

  <div class="body-text-1 value">{{ value }}</div>
</div>
