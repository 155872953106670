import {
  NotificationClusterTypeEnum,
  INotification,
  NotificationItemWithHtml,
  OrgNotificationItems,
  ParamsForBellTemplate,
  notificationClusterInfo,
  convertIfString,
  notificationCodes,
} from '@orgbrain/lib-data';
import { createHtml } from '@orgbrain/lib-util';
declare const require;

export type SortedNotificationData = {
  orgId: string;
  createdAt: Date;
  orgTypeList: {
    type: NotificationClusterTypeEnum;
    createdAt: Date;
  }[];
};
const cloneDeep = require('lodash.clonedeep');

export function createOrgToNotificationsMap(items: INotification[]): {
  orgNotificationsItemsMap: {
    [organizationId: string]: OrgNotificationItems;
  };
  sortedKeys: SortedNotificationData[]; // this gives the order to display the items in the map
} {
  const orgNotificationsItemsMap: { [organizationId: string]: OrgNotificationItems } = {};

  for (const item of items) {
    item.userId = item.userId.toString();
    const data = convertIfString(item.data);
    if (!orgNotificationsItemsMap[item.organizationId]) {
      orgNotificationsItemsMap[item.organizationId] = {
        organizationId: item.organizationId,
        organizationName: data.organizationName,
        payloadListByTypeMap: {},
      };
    }

    const orgBatchItem = orgNotificationsItemsMap[item.organizationId];

    const batchClusterType =
      notificationCodes[item.code]?.batchClusterType || NotificationClusterTypeEnum.LEGACY;

    if (batchClusterType === NotificationClusterTypeEnum.LEGACY) {
      continue;
    }

    const list: INotification[] = (orgBatchItem.payloadListByTypeMap[batchClusterType] =
      orgBatchItem.payloadListByTypeMap[batchClusterType] || []);

    list.push(item);
  }

  const notification0: INotification = cloneDeep(items[0]);

  for (const item of items) {
    if (!item.userId) {
      throw new Error('All items must have a userId');
    }

    if (item.userId !== notification0.userId) {
      throw new Error('All items must have the same recipient');
    }
  }
  const sortedKeys = sortNotificationMap(orgNotificationsItemsMap);
  return { orgNotificationsItemsMap, sortedKeys };
}

export function addListToHtmlItemsWithChannelMerge(
  list: INotification[],
  htmlItems: NotificationItemWithHtml[],
  batchClusterType: NotificationClusterTypeEnum,
  lang,
  timeZone: string
) {
  let text;

  let includetitle = true;

  if (batchClusterType !== NotificationClusterTypeEnum.DUMMY) {
    text = `<div class="notification-cluster-header"> ${notificationClusterInfo[batchClusterType]?.name?.[lang]} </div>`;
    htmlItems.push({ html: text } as NotificationItemWithHtml);
    includetitle = false;
  }

  const mergedList = mergeAllChannelItems(list);

  for (const item of mergedList) {
    if (includetitle) {
      const title = notificationCodes[item.code]?.title[lang] || 'No title';
      const html = `<div class="notification-title"> ${title}</div>`;
      htmlItems.push({ html } as NotificationItemWithHtml);
    }

    const htmlItem = createHtml(item, lang, false, timeZone);

    // const text = '   I am the item ' + JSON.stringify(item);
    if (htmlItem) {
      htmlItems.push(htmlItem);
    }
  }
}

function mergeAllChannelItems(list: INotification[]): INotification[] {
  const channelItemsMap: { [channelId: string]: INotification[] } = {};

  const result: INotification[] = [];
  for (const item of list) {
    if (!item.channelId) {
      result.push(item);
      item.list = [item];
      continue;
    }

    const list: INotification[] = (channelItemsMap[item.channelId] =
      channelItemsMap[item.channelId] || []);

    list.push(item);
  }

  for (const channelId of Object.keys(channelItemsMap)) {
    const list = channelItemsMap[channelId];
    const mergedItem = mergeSingleChannelItems(list);
    result.push(mergedItem);
  }

  return result;
}

function mergeSingleChannelItems(list: INotification[]): INotification {
  const result: INotification = list[list.length - 1];

  const dataMerged: ParamsForBellTemplate = convertIfString(result.data);

  for (const item of list.slice(1)) {
    const newData = convertIfString(item.data);
    const nameList = dataMerged.inviterName?.split(',').map((x) => x.trim());
    if (nameList && !nameList.includes(newData.inviterName.trim())) {
      nameList.push(newData.inviterName.trim());
      dataMerged.inviterName = nameList.join(', ');
    }
  }
  if (dataMerged.inviterName == null) dataMerged.inviterName = '';
  let count = list.length;
  if (count > 1) {
    dataMerged.inviterName += ` (${count} total mentions)`;
    dataMerged.inviterName = dataMerged.inviterName.trim();
  }

  result.data = dataMerged;
  result.list = list;

  return result;
}

const sortDateBiggestFirst = (a, b) => {
  // Handle null or undefined values by considering them as the oldest possible dates
  const dateA = a && a.createdAt ? new Date(a.createdAt) : new Date(0);
  const dateB = b && b.createdAt ? new Date(b.createdAt) : new Date(0);

  // Get time values for comparison
  const timeA = dateA.getTime();
  const timeB = dateB.getTime();

  // Compare the time values
  const val = timeB - timeA;

  // Log the comparison for debugging purposes
  // console.log(
  //   JSON.stringify({ a: a ? a.createdAt : null, b: b ? b.createdAt : null, val }, null, 2)
  // );

  return val;
};

// Sort the item lists by date
// return ordered array of orgs with order types
function sortNotificationMap(orgNotificationsItemsMap: {
  [organizationId: string]: OrgNotificationItems;
}): SortedNotificationData[] {
  /*
  export type OrgNotificationItems = {
  organizationId: string;
  organizationName: string;
  payloadListByTypeMap: { [key in NotificationClusterTypeEnum]?: INotification[] };
};
*/

  // console.log(JSON.stringify(orgNotificationsItemsMap, null, 2));

  const orgList: SortedNotificationData[] = [];

  for (const orgId of Object.keys(orgNotificationsItemsMap)) {
    const orgTypeMap = orgNotificationsItemsMap[orgId];
    const orgTypeList: {
      type: NotificationClusterTypeEnum;
      createdAt: Date;
    }[] = [];
    for (const notificationClusterType of Object.keys(
      orgTypeMap.payloadListByTypeMap
    ) as NotificationClusterTypeEnum[]) {
      const itemList = orgTypeMap.payloadListByTypeMap[notificationClusterType] || [];

      // Sort the low level lists
      itemList.sort(sortDateBiggestFirst);
      const createdAt = itemList[0]?.createdAt;
      orgTypeList.push({ type: notificationClusterType, createdAt });
    }
    orgTypeList.sort(sortDateBiggestFirst);
    orgList.push({ orgId, createdAt: orgTypeList[0]?.createdAt, orgTypeList });
  }

  orgList.sort(sortDateBiggestFirst);
  return orgList;
}
