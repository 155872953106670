import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BisnodeData } from '@orgbrain/lib-admin';
import { INTERCEPTOR_SKIP_HEADER } from '@orgbrain/lib-data';
import { capitalizeCompany } from '@orgbrain/lib-util';
import { Role } from '@orgbrain/roles';

type CompanyNumberWithName = {
  orgNumber: string;
  orgName: string;
  orgForm: string;
  orgFormLong: string;
};

@Injectable({
  providedIn: 'root',
})
export class BrregHelperService {
  // https://www.brreg.no/produkter-og-tjenester/apne-data/opplysninger-om-virksomheter/
  brregRoleTypes = [
    {
      kode: 'ADOS',
      beskrivelse: 'Administrativ enhet - offentlig sektor',
      orgbrainKey: null,
    },
    {
      kode: 'AVKL',
      beskrivelse: 'Forestår avvikling',
      orgbrainKey: null,
    },
    {
      kode: 'BEST',
      beskrivelse: 'Bestyrende reder',
      orgbrainKey: Role.BOARDSECRETARY,
    },
    {
      kode: 'BOBE',
      beskrivelse: 'Bostyrer',
      orgbrainKey: null,
    },
    {
      kode: 'DAGL',
      beskrivelse: 'Daglig leder/administrerende direktør',
      orgbrainKey: Role.CEO,
    },
    {
      kode: 'DTPR',
      beskrivelse: 'Deltaker med proratarisk ansvar (delt ansvar)',
      orgbrainKey: Role.SHAREHOLDER,
    },
    {
      kode: 'DTSO',
      beskrivelse: 'Deltaker med solidarisk ansvar (fullt ansvarlig)',
      orgbrainKey: Role.SHAREHOLDER,
    },
    {
      kode: 'EIKM',
      beskrivelse: 'Eierkommune',
      orgbrainKey: null,
    },
    {
      kode: 'FFØR',
      beskrivelse: 'Forretningsfører',
      orgbrainKey: null,
    },
    {
      kode: 'HFOR',
      beskrivelse: 'Opplysninger om foretaket i hjemlandet',
      orgbrainKey: null,
    },
    {
      kode: 'HLSE',
      beskrivelse: 'Helseforetak',
      orgbrainKey: null,
    },
    {
      kode: 'INNH',
      beskrivelse: 'Innehaver',
      orgbrainKey: Role.CHAIR,
    },
    {
      kode: 'KDEB',
      beskrivelse: 'Konkursdebitor',
      orgbrainKey: null,
    },
    {
      kode: 'KENK',
      beskrivelse: 'Den personlige konkursen angår',
      orgbrainKey: null,
    },
    {
      kode: 'KIRK',
      beskrivelse: 'Inngår i kirkelig fellesråd',
      orgbrainKey: null,
    },
    {
      kode: 'KOMP',
      beskrivelse: 'Komplementar',
      orgbrainKey: null,
    },
    {
      kode: 'KONT',
      beskrivelse: 'Kontaktperson',
      orgbrainKey: null,
    },
    {
      kode: 'KTRF',
      beskrivelse: 'Inngår i kontorfellesskap',
      orgbrainKey: null,
    },
    {
      kode: 'LEDE',
      beskrivelse: 'Styrets leder',
      orgbrainKey: Role.CHAIR,
    },
    {
      kode: 'MEDL',
      beskrivelse: 'Styremedlem',
      orgbrainKey: Role.BOARDMEMBER,
    },
    {
      kode: 'NEST',
      beskrivelse: 'Nestleder',
      orgbrainKey: Role.DEPUTYCHAIR,
    },
    {
      kode: 'OBS',
      beskrivelse: 'Observatør',
      orgbrainKey: Role.OBSERVER,
    },
    {
      kode: 'OPMV',
      beskrivelse: 'er særskilt oppdelt enhet til',
      orgbrainKey: null,
    },
    {
      kode: 'ORGL',
      beskrivelse: 'Organisasjonsledd i offentlig sektor',
      orgbrainKey: null,
    },
    {
      kode: 'REGN',
      beskrivelse: 'Regnskapsfører',
      orgbrainKey: null,
    },
    {
      kode: 'REPR',
      beskrivelse: 'Norsk representant for utenlandsk enhet',
      orgbrainKey: null,
    },
    {
      kode: 'REVI',
      beskrivelse: 'Revisor',
      orgbrainKey: Role.AUDITOR,
    },
    {
      kode: 'VARA',
      beskrivelse: 'Varamedlem',
      orgbrainKey: Role.DEPUTYMEMBER,
    },
  ];
  constructor(private http: HttpClient) {}

  private makeDisplayName(role: any): string {
    if (!role) {
      return '';
    }
    if (role.person) {
      return this.makePersonFullName(role);
    } else if (role.enhet) {
      return capitalizeCompany(role.enhet.navn.join());
    } else {
      return '';
    }
  }

  private makePersonFullName(role) {
    return `${this.getPersonFirstName(role)} ${role.person.navn.etternavn}`
      .replace(/undefined/g, '')
      .trim();
  }

  private getPersonFirstName(role) {
    return `${role.person.navn.fornavn} ${role.person.navn.mellomnavn}`
      .replace(/undefined/g, '')
      .trim();
  }

  async getOrgByOrgId(orgId): Promise<BisnodeData> {
    let res = { members: [] } as BisnodeData;
    let accountantName, auditorName;
    if (!orgId) {
      return res;
    }
    try {
      const brreg = await this.http
        .get<any>(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgId}`, {
          headers: new HttpHeaders({ [INTERCEPTOR_SKIP_HEADER]: '' }),
        })
        .toPromise();

      const roles = await this.http
        .get<any>(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgId}/roller`, {
          headers: new HttpHeaders({ [INTERCEPTOR_SKIP_HEADER]: '' }),
        })
        .toPromise();

      const roleGroups = roles.rollegrupper || [];
      const userRoleMap = {};
      console.log('roleGroups', roleGroups);

      const mapBoard = {};
      const boardRoles = [
        Role.CEO,
        Role.CHAIR,
        Role.DEPUTYCHAIR,
        Role.BOARDSECRETARY,
        Role.BOARDMEMBER,
        Role.DEPUTYMEMBER,
        Role.OBSERVER,
      ];
      for (const key of boardRoles) {
        mapBoard[key] = true;
      }

      for (const roleGroup of roleGroups) {
        for (const role of roleGroup.roller) {
          if (role?.type?.kode === 'REGN') {
            accountantName = this.makeDisplayName(role);
          }

          if (role?.type?.kode === 'REVI') {
            auditorName = this.makeDisplayName(role);
          }

          if (role.person && !role.person.erDoed && role.type) {
            const userId = role.person.fodselsdato + Object.values(role.person.navn).join();
            userRoleMap[userId] = userRoleMap[userId] || {
              firstName: this.getPersonFirstName(role),
              lastName: role.person.navn.etternavn,
              fullName: this.makePersonFullName(role),
              roles: [],
              internref: userId,
              email: '',
            };
            const roleKey = role.type.kode;
            const foundKey = this.brregRoleTypes.find((item) => item.kode === roleKey);
            const finalKey = foundKey && foundKey.orgbrainKey;
            if (finalKey) {
              userRoleMap[userId].roles.push(finalKey);

              if (mapBoard[finalKey]) {
                userRoleMap[userId].isBoard = true;
              }
            }
          }
        }
      }

      res = this.mapOneEntityBrreg(brreg);
      res.members = Object.values(userRoleMap);
      res.membersBoard = res.members.filter((item) => item.isBoard);
      res.accountantName = accountantName || '';
      res.auditorName = auditorName || '';
    } catch (err: any) {
      console.log('Error in brreg get org', err);
    }
    console.log('res rs res', res);
    return res;
  }

  public mapBrregData(value: any): CompanyNumberWithName[] {
    if (!value || !value._embedded || !value._embedded.enheter) {
      return [];
    }
    const result = [];

    for (const item of value._embedded.enheter) {
      result.push(this.mapOneEntityBrreg(item));
    }
    return result;
  }

  private parsePostcode(code) {
    code = code || '';
    code = '0000' + code;
    code = code.slice(-4);
    if (code === '0000') {
      code = '';
    }
    return code;
  }

  mapOneEntityBrreg(item) {
    item = item || {};
    const name = capitalizeCompany(item.navn);
    const zip = this.parsePostcode(item.forretningsadresse && item.forretningsadresse.postnummer);
    const orgForm = item.organisasjonsform && item.organisasjonsform.kode;
    const addressArr = item?.forretningsadresse?.adresse || [];
    const addressLine1 = addressArr[0] || '';
    const addressLine2 = addressArr.slice(1).join();
    return {
      orgnr: item.organisasjonsnummer,
      organizationNumber: item.organisasjonsnummer,
      established: item.stiftelsesdato,
      orgNumber: item.organisasjonsnummer,
      name,
      orgName: name,
      orgForm,
      type: orgForm,
      orgFormLong: item.organisasjonsform && item.organisasjonsform.beskrivelse,
      city: capitalizeCompany(item.forretningsadresse && item.forretningsadresse.poststed),
      country: capitalizeCompany(item.forretningsadresse && item.forretningsadresse.land),
      zip,
      postnr: zip,
      postalCode: zip,
      website: '',
      phone: '',
      companyIndustry: item?.naeringskode1?.beskrivelse,
      address: addressArr.join(','),
      addressLine1,
      addressLine2,
    } as any;
  }
}
