export type MongoId = string | any;

export enum ChatChannelType {
  MEETING_CHANNEL = 'MEETING_CHANNEL',
  ORGANIZATION_CHANNEL = 'ORGANIZATION_CHANNEL',
  TASK_CHANNEL = 'TASK_CHANNEL',
  CONVERSATION_CHANNEL = 'CONVERSATION_CHANNEL',
}
export enum ChatMessageType {
  MESSAGE = 'MESSAGE',
  REMOVED_USER = 'REMOVED_USER',
  ADDED_USER = 'ADDED_USER',
  LEFT_CHAT = 'LEFT_CHAT',
  NEW_NAME = 'NEW_NAME',
  CHANGED_HISTORY = 'CHANGED_HISTORY',
  CALL_START = 'CALL_START',
  CALL_END = 'CALL_END',
}
export type IChatMessage = {
  channelId: MongoId;
  type: ChatMessageType;
  organizationId: MongoId;
  fromUserId: MongoId;
  receivedAt: Date;
  editedAt: Date;
  message: string;
  read: MongoId;
  reactions: { emojiName: string; userIds: MongoId[] }[];
  editedMessageHistory: string[];
  replyToMessageId: MongoId;
  mentionedUserIds: MongoId[];
  isDeleted: boolean;
  deletedAt: Date | number;
  deletedByUser: MongoId;
};

export type IChannel = {
  _id: MongoId;
  id: string;
  chatChannelType: ChatChannelType;
  meetingId: MongoId;
  taskId: MongoId;
  organizationId: MongoId;
  usersToLastSeenMessage: any;
};
