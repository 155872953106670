/*
USAGE:

Backend
        publishToChannel({
            channelName: `${ChannelEnum.UserNotification}.${inviteeId}`,
            payload: { status: SignOrderStatusEnum.TASK_EXPIRED },
          });
        }


Frontend
        this.subscription = channelService
          .getChannel(`${ChannelEnum.UserNotification}.${userId}`)
          .pipe(debounceTime(500))
          .subscribe(async (_) => {
            await this.load();
          });

*/

export enum OrganizationChannelStatusEnum {
  POLICY = 'POLICY',
}

export enum SignOrderStatusEnum { // A biy of a mess. We mix stuff form different systems.
  ALIEN = 'ALIEN',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  CREATE_FAILED = 'CREATE_FAILED',
  EXPIRED = 'EXPIRED',
  TASK_COMPLETED = 'TASK_COMPLETED',
  TASK_REJECTED = 'TASK_REJECTED',
  TASK_EXPIRED = 'TASK_EXPIRED',
  OPEN = 'OPEN',
}

export enum ChannelEnum {
  HEARTBEAT = 'HEARTBEAT',

  // The user notifaction list has changed.   - userId
  UserNotification = 'UserNotification',

  // A user has been modified so we need to rebuild permission etc.    - userId
  UserModifyNotification = 'UserModifyNotification',

  // The status of a sign order has change so we can reaload in file views.  - orderId
  SigningNotification = 'SigningNotification',

  //  Unused.   - orderId
  // Should be used to update the status of a sign order in the sign order list.
  SigningNotificationV2 = 'SigningNotificationV2',

  // ANNOTATIONS have change so we need to reload in file views.  - direntId
  ANNOTATION = 'ANNOTATION',

  //  Emmitted during recursive delete - direntId
  //  Seems not to be used yet
  RecursiveHardDelete = 'RecursiveHardDelete',

  // Change in an organization.  - organizationId
  // payload should have context.
  // OrganizationChannelStatusEnum.POLICY   - policy has changed
  Organization = 'Organization',

  // Stress testing channel. Not for production use.
  StressTesting = 'StressTesting',
}
