import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DecorationService {
  originalFaviconUrl: string;

  updateFavicon(unreadCount) {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;

    if (unreadCount > 0) {
      favicon.href = 'assets/favicon-notification-G.svg';
    } else {
      favicon.href = 'assets/favicon-notification-F.svg';
    }

    // if (!this.originalFaviconUrl) {
    //   this.originalFaviconUrl = favicon.href;
    // }

    // const image = new Image();
    // image.src = this.originalFaviconUrl;

    // image.onload = function () {
    //   const canvas = document.createElement('canvas');
    //   const context = canvas.getContext('2d');

    //   const size = 32; // Size of the favicon
    //   canvas.width = size;
    //   canvas.height = size;

    //   // Draw the original favicon
    //   context.drawImage(image, 0, 0, size, size);

    //   if (unreadCount > 0) {
    //     // Draw the red badge
    //     context.fillStyle = '#ffffff';
    //     context.beginPath();

    //     const dotSize = 8;
    //     context.arc(size - dotSize, dotSize, dotSize, 0, 2 * Math.PI);
    //     context.arc(size - dotSize, dotSize, dotSize, 0, 2 * Math.PI);

    //     context.fill();

    //     context.fillStyle = '#48964C';
    //     context.fillStyle = '#ff0000';

    //     context.beginPath();

    //     const dotSize1 = 6;
    //     context.arc(size - dotSize, dotSize, dotSize1, 0, 2 * Math.PI);
    //     context.arc(size - dotSize, dotSize, dotSize1, 0, 2 * Math.PI);

    //     context.fill();

    //     context.fillStyle = '#ffffff';
    //     context.beginPath();
    //     const dotSize2 = 3;

    //     context.arc(size - dotSize, dotSize, dotSize2, 0, 2 * Math.PI);
    //     context.arc(size - dotSize, dotSize, dotSize2, 0, 2 * Math.PI);

    //     context.fill();
    //   }

    //   // Update the favicon with the new image
    //   favicon.href = canvas.toDataURL('image/png');
    // };
  }

  playSound() {
    const sounds = ['level-up-3-199576.mp3', 'achive-sound-132273.mp3'];

    setTimeout(() => {
      const sound = new Audio('assets/mp3/' + sounds[0]);
      sound.volume = 0.3;
      sound.play();
    }, 100);
  }
}
