import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SharedUtilService } from '../util.service';

export class SnackRefProxy {
  activeToast: ActiveToast<any> | any;
  constructor(activeToast: ActiveToast<any>) {
    this.activeToast = activeToast;
  }
  dismiss(): void {
    this.activeToast.toastRef.close();
  }
  onAction(): Observable<void> {
    return this.activeToast.onAction;
  }
}

@Injectable({ providedIn: 'root' })
export class Snack2ToastrService {
  constructor(private toastr: ToastrService, public util: SharedUtilService) {}

  // Define a new open function that maps to Toastr's success function

  open(
    message: string | any,
    buttonText = null,
    config = null,
    icon: 'success' | 'info' | 'warning' | 'error' = 'info' ///actully any mat-icon but lets not get too exicited.
  ): SnackRefProxy {
    message = this.util.sensibleMessage(message);
    const options = {
      tapToDismiss: false,
      closeButton: true,
      disableTimeOut: !config?.duration,
      timeOut: config?.duration,
      icon: icon,
      buttonText,
      extendedTimeOut: 0,
      enableHtml: config?.enableHtml,
    };

    console.log(this.toastr.toasts);

    return new SnackRefProxy(this.toastr.show(message, null, options));
  }
}
